import * as AngleTypes from "../../../constants/Angles";
import {
    CORNER_CUTOUT,
    COUPE,
    COUPE_OVER_LENGTH,
    DEBASING_ROUGH,
    DRILL_HOLE,
    GLUED_CUSHION,
    HEIGHT_COUPE,
    NOTCH,
    NOTCH_OVER_LENGTH,
    PROFILE,
    RABAT,
    RECTANGULAR_CUT_OUT,
    ROUNDED_CORNER,
} from "../../../constants/OperationTypes";
import {BACK, FRONT, LEFT, RIGHT} from "../../../constants/ObjectSides";
import * as ProfileTypes from '../../../constants/ProfileOperationTypes';

export default class OperationsSorter {

    constructor() {
        this.frontSideOperations = [];
        this.leftSideOperations = [];
        this.backSideOperations = [];
        this.rightSideOperations = [];
        this.miscellaneousOperations = [];
        this.operationsToShowOnSide = [];
    }

    sortOperationsBySide(operations) {
        const operationsToBeSorted = [
            NOTCH,
            COUPE,
            CORNER_CUTOUT,
            DRILL_HOLE,
            RECTANGULAR_CUT_OUT,
            ROUNDED_CORNER,
            GLUED_CUSHION,
            COUPE_OVER_LENGTH,
            DEBASING_ROUGH,
            PROFILE,
        ];

        for (let i = 0; i < operations.length; i++) {
            // Check if the measurement lines of this operation should be displayed
            if (operationsToBeSorted.includes(operations[i].type)) {
                switch (operations[i].type) {
                    case COUPE:
                        this.__sortCoupe(operations[i]);
                        break;
                    case CORNER_CUTOUT:
                        this.__sortCornerCutOut(operations[i]);
                        break;
                    case COUPE_OVER_LENGTH:
                        this.__sortCoupeOverLength(operations[i]);
                        break;
                    case DRILL_HOLE:
                    case RECTANGULAR_CUT_OUT:
                    case ROUNDED_CORNER:
                    case GLUED_CUSHION:
                        this.miscellaneousOperations.push(operations[i]);
                        this.operationsToShowOnSide.push(operations[i]);
                        break;
                    case DEBASING_ROUGH:
                    case PROFILE:
                        this.operationsToShowOnSide.push(operations[i]);
                        break;
                    default:
                        // Other operations can be added to the side that they are on
                        this.__sortOtherOperation(operations[i]);
                        break;
                }
            }
        }

        // Sort the operations by position
        this.frontSideOperations.sort(this.__compareFrontOperations);
        this.leftSideOperations.sort(this.__compareLeftOperations);
        this.backSideOperations.sort(this.__compareBackOperations);
        this.rightSideOperations.sort(this.__compareRightOperations);

        return {
            front: this.frontSideOperations,
            left: this.leftSideOperations,
            back: this.backSideOperations,
            right: this.rightSideOperations,
            miscellaneous: this.miscellaneousOperations,
            showOnSide: this.operationsToShowOnSide,
        }
    }

    sortOperationsToShowOnSides(operations) {
        const operationsToBeSorted = [
            DEBASING_ROUGH,
            PROFILE,
            HEIGHT_COUPE,
            RABAT,
            NOTCH_OVER_LENGTH,
            GLUED_CUSHION,
        ];

        let leftSide = [];
        let rightSide = [];
        let frontSide = [];

        operations.forEach(operation => {
            if (operationsToBeSorted.includes(operation.type)) {
                switch (operation.type) {
                    case GLUED_CUSHION:
                        if (operation.side === LEFT) {
                            leftSide.push(operation);
                        }
                        if (operation.side === RIGHT) {
                            rightSide.push(operation);
                        }
                        break;
                    case DEBASING_ROUGH:
                        leftSide.push(operation);
                        break;
                    case PROFILE:
                        // Only profiletypes 4 and 5 need measurement lines drawn
                        if ([ProfileTypes.TYPE_4, ProfileTypes.TYPE_5].includes(operation.additionalDimension.type)) {
                            if ([LEFT, RIGHT].includes(operation.side)) {
                                frontSide.push(operation);
                            } else {
                                leftSide.push(operation);
                            }
                        }
                        break;
                    case HEIGHT_COUPE:
                    case RABAT:
                    case NOTCH_OVER_LENGTH:
                        if ([FRONT, BACK].includes(operation.side)) {
                            leftSide.push(operation);
                        } else {
                            frontSide.push(operation);
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        return {
            left: leftSide,
            right: rightSide,
            front: frontSide,
        }
    }

    __compareFrontOperations(operationA, operationB) {
        if (operationA.position.x < operationB.position.x) {
            return -1
        }
        if (operationA.position.x > operationB.position.x) {
            return 1
        }

        return 0;
    }

    __compareBackOperations(operationA, operationB) {
        if (operationA.position.x > operationB.position.x) {
            return -1
        }
        if (operationA.position.x < operationB.position.x) {
            return 1
        }

        return 0;
    }

    __compareLeftOperations(operationA, operationB) {
        if (operationA.position.z < operationB.position.z) {
            return -1
        }
        if (operationA.position.z > operationB.position.z) {
            return 1
        }

        return 0;
    }

    __compareRightOperations(operationA, operationB) {
        if (operationA.position.z > operationB.position.z) {
            return -1
        }
        if (operationA.position.z < operationB.position.z) {
            return 1
        }

        return 0;
    }

    __sortCoupe(operation) {
        // If the operation type is a coupe, the lines should be displayed on the front or back of the object,
        // not on the left or right (on which sides a coupe always is)
        if (operation.angle === AngleTypes.INNER) {
            this.frontSideOperations.push(operation);
        } else {
            this.backSideOperations.push(operation);
        }

        if (operation.side === LEFT) {
            this.leftSideOperations.push(operation);
        } else {
            this.rightSideOperations.push(operation);
        }
    }

    __sortOtherOperation(operation) {
        switch (operation.side) {
            case FRONT:
                this.frontSideOperations.push(operation);
                break;
            case LEFT:
                this.leftSideOperations.push(operation);
                break;
            case BACK:
                this.backSideOperations.push(operation);
                break;
            case RIGHT:
                this.rightSideOperations.push(operation);
                break;
            default:
                break;
        }
    }

    __sortCornerCutOut(operation) {
        if (operation.additionalDimension.type === FRONT) {
            this.frontSideOperations.push(operation);
        } else {
            //BACK
            this.backSideOperations.push(operation);
        }
    }

    __sortCoupeOverLength(coupeOverLength) {
        if (coupeOverLength.additionalDimension.type === LEFT) {
            this.leftSideOperations.push(coupeOverLength);
        } else {
            this.rightSideOperations.push(coupeOverLength);
        }
    }
}

