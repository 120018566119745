export class Setting {
    constructor(id, key, value) {
        this.id = id;
        this.key = key;
        this.value = this.parseValue(value);
    }

    parseValue(value) {
        if (value == 0 || value == 1) {
            value = value == 1;
        }

        return value;
    }
}
