import MeasurementPoints from "../../../models/MeasurementPoints";
import {BACK, FRONT, LEFT, RIGHT} from "../../../../constants/ObjectSides";
import {Vector3} from "three";
import {applyOffsetToPoints} from "../SurfaceMeasurementLineHelper";

export function getPointsForGluedCushionsOnSurface(pieceDimensions, gluedCushion) {
    let horizontalPoints = new MeasurementPoints(
        getHorizontalPointA(pieceDimensions, gluedCushion),
        getHorizontalPointB(pieceDimensions, gluedCushion),
    );

    let verticalPoints = new MeasurementPoints(
        getVerticalPointA(pieceDimensions, gluedCushion),
        getVerticalPointB(pieceDimensions, gluedCushion)
    );

    // When the cushion is on the right side of the piece the line should be on the left side
    // Otherwise the line would be rendered outside the piece
    horizontalPoints.lineSide = getHorizontalLineSide(gluedCushion.side);
    horizontalPoints.lineLength = gluedCushion.dimensions.width;
    horizontalPoints.textSide = getHorizontalTextSide(gluedCushion.side);

    verticalPoints.lineSide = getVerticalLineSide(gluedCushion.side, gluedCushion.additionalDimension.type);
    verticalPoints.textSide = getVerticalTextSide(gluedCushion.side, gluedCushion.additionalDimension.type);

    const pointsWithOffset = applyOffsetToPoints([horizontalPoints], [verticalPoints], gluedCushion.side);

    return {
        horizontal: pointsWithOffset.horizontal[0],
        vertical: pointsWithOffset.vertical[0],
        shortened: true,
    };
}

// Point on the inside of the piece
function getVerticalPointA(pieceDimensions, gluedCushion) {
    let xPos;
    const zPos = getZPos(pieceDimensions, gluedCushion);

    if (gluedCushion.side === LEFT) {
        xPos = pieceDimensions.length / -2 + gluedCushion.dimensions.length;
    } else {
        // RIGHT
        xPos = pieceDimensions.length / 2 - gluedCushion.dimensions.length;
    }

    return new Vector3(
        xPos,
        getYPos(pieceDimensions, gluedCushion),
        zPos
    );
}

// Point on the edge of the piece and the cushion
function getVerticalPointB(pieceDimensions, gluedCushion) {
    let xPos;
    const zPos = getZPos(pieceDimensions, gluedCushion);

    if (gluedCushion.side === LEFT) {
        xPos = pieceDimensions.length / -2;
    } else {
        // RIGHT
        xPos = pieceDimensions.length / 2;
    }

    return new Vector3(
        xPos,
        getYPos(pieceDimensions, gluedCushion),
        zPos
    );
}

// Point for starting the line on the edge of the cushion
function getHorizontalPointA(pieceDimensions, gluedCushion) {
    const xPos = getXPos(pieceDimensions, gluedCushion);
    let zPos;

    if (gluedCushion.side === LEFT) {
        zPos = gluedCushion.position.z - gluedCushion.dimensions.width / 2;
    } else {
        // RIGHT
        zPos = gluedCushion.position.z + gluedCushion.dimensions.width / 2;
    }

    return new Vector3(
        xPos,
        getYPos(pieceDimensions, gluedCushion),
        zPos
    );
}

// Point for ending the line, can end on the edge of the cushion or somewhere in the piece
function getHorizontalPointB(pieceDimensions, gluedCushion) {
    const xPos = getXPos(pieceDimensions, gluedCushion);
    let zPos;

    if (gluedCushion.side === LEFT) {
        zPos = gluedCushion.position.z + gluedCushion.dimensions.width / 2;
    } else {
        zPos = gluedCushion.position.z - gluedCushion.dimensions.width / 2;
    }

    return new Vector3(
        xPos,
        getYPos(pieceDimensions, gluedCushion),
        zPos
    );
}

function getZPos(pieceDimensions, gluedCushion) {
    let zPos;

    if (pieceDimensions.width - 5 <= gluedCushion.dimensions.width) {
        if (gluedCushion.additionalDimension.type === FRONT) {
            zPos = pieceDimensions.width / 2 - gluedCushion.dimensions.width / 2
        } else {
            // BACK
            zPos = pieceDimensions.width / -2 + gluedCushion.dimensions.width / 2
        }
    } else {
        if (gluedCushion.additionalDimension.type === FRONT) {
            zPos = pieceDimensions.width / 2 - gluedCushion.dimensions.width
        } else {
            // BACK
            zPos = pieceDimensions.width / -2 + gluedCushion.dimensions.width
        }
    }

    return zPos;
}

function getXPos(pieceDimensions, gluedCushion) {
    let xPos;

    if (gluedCushion.side === LEFT) {
        // Set point on the right side of the cushion
        xPos = pieceDimensions.length / -2 + gluedCushion.dimensions.length;
    } else {
        // RIGHT
        // Set point on the left side of the cushion
        xPos = pieceDimensions.length / 2 - gluedCushion.dimensions.length;
    }

    return xPos;
}

function getYPos(pieceDimensions, gluedCushion) {
    return pieceDimensions.height / 2 + gluedCushion.dimensions.height + 0.05;
}

function getHorizontalLineSide(side) {
    let lineSide;

    if (side === LEFT) {
        lineSide = RIGHT;
    } else {
        lineSide = LEFT;
    }

    return lineSide;
}

function getHorizontalTextSide(side) {
    let textSide;

    if (side === LEFT || side === RIGHT) {
        textSide = BACK;
    }

    return textSide;
}

function getVerticalLineSide(side, additionalSide) {
    let lineSide;

    if (side === LEFT) {
        if (additionalSide === FRONT) {
            lineSide = LEFT;
        } else {
            lineSide = RIGHT;
        }
    } else {
        if (additionalSide === FRONT) {
            lineSide = RIGHT;
        } else {
            lineSide = LEFT;
        }
    }

    return lineSide;
}

function getVerticalTextSide(side, additionalSide) {
    let textSide;

    if (side === LEFT) {
        if (additionalSide === FRONT) {
            textSide = FRONT;
        } else {
            textSide = BACK;
        }
    } else {
        if (additionalSide === FRONT) {
            textSide = BACK;
        } else {
            textSide = FRONT;
        }
    }

    return textSide;
}
