export const BLUESTONE = 'BLUESTONE';
export const GRANITE = 'GRANITE';
export const MARBLE = 'MARBLE';

export const MATERIALS = [
    BLUESTONE,
    GRANITE,
    MARBLE,
];

export const EXTERIOR_MATERIALS = [
    BLUESTONE,
    GRANITE,
];

export const INTERIOR_MATERIALS = [
    BLUESTONE,
    GRANITE,
    MARBLE,
];