import * as React from 'react';
import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import ExternalLink from "../../classes/models/ExternalLink";
import { useTranslation } from "react-i18next";
import { TRANSLATION_NAMESPACE } from "../../constants/TranslationConstants";

export const ExternalLinkModal = (props) => {
    const { t } = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.externalLinkModal.';

    const [formIsValid, setFormIsValid] = useState(false);
    const [name, setName] = useState('');
    const [linkContent, setLinkContent] = useState('');

    let inputFieldRef = useRef();

    useEffect(() => {
        if (props.isActive) {
            inputFieldRef.focus();
            if (props.externalLink) {
                setName(props.externalLink.name);
                setLinkContent(props.externalLink.link);
            }
        }

    }, [props.isActive]);

    useEffect(() => {
        // Set the validity of the form, check if the value isn't already what it is going to be set as
        // Prevent unnecessary  state changes
        if (name.length < 1 || linkContent.length < 1) {
            if (formIsValid) setFormIsValid(false);
        } else {
            if (!formIsValid) setFormIsValid(true)
        }
    }, [name, linkContent])

    const onCloseHandler = (submit) => {
        if (formIsValid && submit) props.onClose(name, linkContent);
        else props.onClose();

        setName('');
        setLinkContent('');
    }

    const onEnterPress = (event) => {
        if (event.keyCode === 13 && !event.shiftKey) {
            onCloseHandler(true);
        }
    };

    return (
        <Modal show={props.isActive} onHide={() => onCloseHandler()} animation={true} className="modal--prompt">
            <Modal.Header>
                <Modal.Title>{t(prefix + 'title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label>{t(prefix + 'linkNameLabel')}</label>
                    <input
                        ref={(input) => {
                            inputFieldRef = input;
                        }}
                        type="text"
                        name="linkName"
                        placeholder={t(prefix + 'linkNamePlaceholder')}
                        value={name}
                        onKeyDown={(event) => onEnterPress(event)}
                        onChange={(event) => setName(event.target.value)}
                    />

                    <label className="m-t-2">{t(prefix + 'linkContentLabel')}</label>
                    <input
                        type="text"
                        name="linkContent"
                        placeholder={t(prefix + 'linkContentPlaceholder')}
                        value={linkContent}
                        onKeyDown={(event) => onEnterPress(event)}
                        onChange={(event) => setLinkContent(event.target.value)}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="button button--primary"
                    onClick={() => onCloseHandler(true)}
                    disabled={!formIsValid}>
                    {props.externalLink ? t(prefix + 'addButton') : t(prefix + 'editButton')}
                </button>

                <button type="button" className="button button--light"
                    onClick={() => onCloseHandler(false)}>
                    {t(prefix + 'cancelButton')}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

ExternalLinkModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    externalLin: PropTypes.instanceOf(ExternalLink),
}