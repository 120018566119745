export const LENGTH = 'length';
export const WIDTH = 'width';
export const HEIGHT = 'height';
export const FRONT_HEIGHT = 'frontHeight';
export const EXTRUSION = 'extrusion';
export const BAR_WIDTH = 'barWidth';
export const CUSHION_WIDTH = 'cushionWidth';
export const PROTRUSION = 'protrusion';

// For the presets that are displayed in an upright position, the name of 'width' is different
// Here the width is called the height, and the height is called thickness
export const HEIGHT_UPRIGHT = 'height_upright';

// For these types the height is named differently than the other types
export const HEIGHT_TYPE_3_TO_4 = 'height_type_3_to_4';
export const HEIGHT_TYPE_5_TO_8 = 'height_type_5_to_8';

// This dimension is only for type 3 and 4
export const HEIGHT_BACK_SIDE = 'height_back_side';

export const TYPE_1_DIMENSIONS = [
    LENGTH,
    WIDTH,
    HEIGHT,
];

export const TYPE_3_DIMENSIONS = [
    LENGTH,
    WIDTH,
    HEIGHT,
    HEIGHT_BACK_SIDE,
    EXTRUSION,
    BAR_WIDTH,
];

export const TYPE_5_DIMENSIONS = [
    LENGTH,
    WIDTH,
    HEIGHT,
    FRONT_HEIGHT,
    EXTRUSION,
    BAR_WIDTH,
];

export const TYPE_7_DIMENSIONS = [
    LENGTH,
    WIDTH,
    HEIGHT,
    FRONT_HEIGHT,
    EXTRUSION,
    BAR_WIDTH,
    CUSHION_WIDTH,
    PROTRUSION,
];

export const TYPE_9_DIMENSIONS = [
    LENGTH,
    WIDTH,
    HEIGHT,
];

// Same dimensions as the default, but in another order
export const CUSHIONS_NOT_GLUED_DIMENSIONS = [
    WIDTH,
    LENGTH,
    HEIGHT,
];

export const ALL = [
    LENGTH,
    WIDTH,
    HEIGHT,
    FRONT_HEIGHT,
    EXTRUSION,
    BAR_WIDTH,
    CUSHION_WIDTH,
    PROTRUSION,
];
