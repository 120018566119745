import * as React from 'react';
import {ActionsDropdown} from "../../dropdowns/ActionsDropdown";
import {useEffect, useState} from "react";
import GalleryImageService from "../../../classes/services/GalleryImageService";
import {addAlertMessage, setWindowIsLoading} from "../../../actions/GeneralActions";
import {useDispatch, useSelector} from "react-redux";
import {DANGER, SUCCESS} from "../../../constants/Variants";
import {GalleryImageModal} from "../../modals/GalleryImageModal";
import {ActionsDropdownItem} from "../../dropdowns/items/ActionsDropdownItem";
import {ConfirmationModal} from "../../modals/ConfirmationModal";
import {setImages} from "../../../actions/GalleryImageActions";
import {EmptyPage} from "../../empty/EmptyPage";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {AppHeader} from "../../headers/AppHeader";
import {GALLERY_IMAGES} from "../../../constants/AdminPages";

export const GalleryImageManagement = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.admin.galleryImageManagement.';
    const constantsPrefix = 'constants.adminPages.';

    const [imageModalIsActive, setImageModalIsActive] = useState(false);
    const [deleteModalIsActive, setDeleteModalIsActive] = useState(false);
    const [currentGalleryImage, setCurrentGalleryImage] = useState(null);

    const images = useSelector(state => state.galleryImageReducer.images);
    const windowIsLoading = useSelector(state => state.generalReducer.windowIsLoading);

    const galleryImageService = new GalleryImageService();

    const dispatch = useDispatch();

    useEffect(() => {
        if (images.length < 1) {
            getImages();
        }
    }, []);

    //region Api calls
    const getImages = () => {
        dispatch(setWindowIsLoading(true));

        galleryImageService.getImages()
            .then(response => {
                if (response.success) {
                    let promises = response.data.map(galleryImage => {
                        return addImageUrlToGalleryImage(galleryImage);
                    });

                    Promise.all(promises)
                        .then(galleryImages => {
                            dispatch(setImages(galleryImages));
                        })
                        .finally(() => {
                            dispatch(setWindowIsLoading(false));
                        });

                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'fetchGalleryImagesFailedMessage')));
                dispatch(setWindowIsLoading(false))

                throw error;
            });
    }

    const addImageUrlToGalleryImage = async (galleryImage) => {
        return galleryImageService.getImageFile(galleryImage.id)
            .then(imageFile => {
                galleryImage.imageUrl = URL.createObjectURL(imageFile);

                return galleryImage;
            });
    }

    const createImage = (file, tags) => {
        dispatch(setWindowIsLoading(true));

        galleryImageService.create(file, tags)
            .then(response => {
                if (response.success) {
                    addImageUrlToGalleryImage(response.data)
                        .then(galleryImage => {
                            dispatch(setImages(images.concat(galleryImage)));
                        });
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'addGalleryImageFailedMessage')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const updateImage = (tags) => {
        dispatch(setWindowIsLoading(true));

        galleryImageService.update(currentGalleryImage.id, tags)
            .then(response => {
                if (response.success) {
                    dispatch(setImages(images.map(image => {
                        if (image.id === response.data.id) return response.data;

                        return image;
                    })));
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'updateGalleryImageFailedMessage')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const deleteImage = () => {
        dispatch(setWindowIsLoading(true));

        galleryImageService.delete(currentGalleryImage.id)
            .then(response => {
                if (response.success) {
                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteGalleryImageSuccessMessage')));

                    dispatch(setImages(images.filter(image => image.id !== currentGalleryImage.id)));
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'deleteGalleryImageFailedMessage')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }
    //endregion

    const openImageModal = (galleryImage) => {
        if (galleryImage) {
            setCurrentGalleryImage(galleryImage);
        }

        setImageModalIsActive(true);
    }

    const openDeleteModal = (galleryImage) => {
        setCurrentGalleryImage(galleryImage);
        setDeleteModalIsActive(true);
    }

    const closeImageModal = (file, tags) => {
        if (file != null || tags != null) {
            if (currentGalleryImage) {
                updateImage(tags);
            } else {
                createImage(file, tags);
            }
        }

        setCurrentGalleryImage(null);
        setImageModalIsActive(false);
    }

    const closeDeleteModal = (accepted) => {
        if (accepted) {
            deleteImage();
        }

        setCurrentGalleryImage(null);
        setDeleteModalIsActive(false);
    }

    const renderImageList = () => {
        return <div>
            <AppHeader title={t(constantsPrefix + GALLERY_IMAGES)}/>

            <button type="button" className="button button--outline" onClick={() => openImageModal()}>
                <i className="material-icons">add</i>
            </button>

            <div className="list">
                {
                    images.map(image => {
                        return <div className="list__item" key={image.id}>
                            <div className="list__item__content">
                                {image.name}
                                <img src={image.fileUrl} alt=""/>
                            </div>

                            <img src={image.imageUrl} alt=""/>

                            <ActionsDropdown>
                                <ActionsDropdownItem content={t(prefix + 'editDropDownItem')}
                                                     onClick={() => openImageModal(image)}/>
                                <ActionsDropdownItem content={t(prefix + 'deleteDropDownItem')} variant={DANGER}
                                                     onClick={() => openDeleteModal(image)}/>
                            </ActionsDropdown>
                        </div>
                    })
                }
            </div>
        </div>
    }

    const renderContent = () => {
        let content;

        if (!windowIsLoading) {
            if (images?.length > 0) {
                content = renderImageList();
            } else {
                content = <EmptyPage
                    title={t(prefix + 'emptyPageTitle')}
                    content={t(prefix + 'emptyPageContent')}
                    buttonText={t(prefix + 'emptyPageButton')}
                    onButtonClick={() => openImageModal()}
                />;
            }
        }

        return content;
    }

    return (
        <div className="app__content">
            <GalleryImageModal
                isActive={imageModalIsActive}
                onClose={(file, tags) => closeImageModal(file, tags)}
                galleryImage={currentGalleryImage}
            />

            <ConfirmationModal
                isActive={deleteModalIsActive}
                onClose={(accepted) => closeDeleteModal(accepted)}
                content={t(prefix + 'deleteModalContent')}/>

            {renderContent()}
        </div>
    );
};