import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {useSelector} from 'react-redux';
import {SectionTitle} from '../headers/SectionTitle';
import * as ObjectSides from '../../constants/ObjectSides';
import {RadioButton} from '../forms/inputs/RadioButton';
import {ConfirmationModal} from './ConfirmationModal';
import {useForm} from "react-hook-form";
import {Operation} from '../../classes/models/Operation';
import {InfoModal} from './InfoModal';
import {CheckboxWithIcon} from "../forms/inputs/CheckboxWithIcon";
import {parseNumber} from "../../classes/helpers/StringHelper";
import {determineCorner} from "../../classes/helpers/OperationHelper";
import {ValidatedInput} from "../forms/inputs/ValidatedInput";
import iconFrontLeft from '../../assets/img/config-hoek-linksvoor.png';
import iconFrontRight from '../../assets/img/config-hoek-rechtsvoor.png';
import iconBackLeft from '../../assets/img/config-hoek-linksachter.png';
import iconBackRight from '../../assets/img/config-hoek-rechtsachter.png';
import {CORNER_CUTOUT, PROHIBITED_OPERATION_TYPES} from "../../constants/OperationTypes";
import {createOperation, deleteOperation, updateOperation} from "./index";
import {
    BACK_LEFT,
    BACK_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
    FRONT_LEFT,
    FRONT_RIGHT,
    TOP_LEFT, TOP_RIGHT
} from "../../constants/ObjectCorners";
import {getCornerNameByPreset} from "../../classes/helpers/ObjectHelper";
import {ModalHolder} from "./ModalHolder";

function CornerCutoutModal(props) {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.cornerCutoutModal.';

    const {errors, register, handleSubmit} = useForm();

    const [selectedSide, setSelectedSide] = useState('');
    const [selectedCorner, setSelectedCorner] = useState('');
    const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [availableCorners, setAvailableCorners] = useState([]);
    const [infoModalIsActive, setInfoModalIsActive] = useState(false);
    const infoModalContent = t(prefix + 'infoModalContent');

    const {currentConfiguration, currentPiece, currentOperation} = useSelector(state => state.offerReducer);
    const canEdit = useSelector(state => state.generalReducer.canEdit);

    useEffect(() => {
        if (currentOperation == null) {
            setIsFinished(false);
            checkCorners();
        } else {
            let tempAvailableCorners = currentPiece?.getAvailableCorners(PROHIBITED_OPERATION_TYPES.CORNER_CUTOUT);
            const corner = determineCorner(currentOperation.additionalDimension.type, currentOperation.side);

            setSelectedCorner(corner);
            tempAvailableCorners.push(corner);

            setAvailableCorners(tempAvailableCorners);
            setSelectedSide(currentOperation.side);
            setIsFinished(currentOperation.isFinished);
        }

    }, [props.isActive, currentOperation]);

    const createCornerCutout = (fields) => {
        let cornerCutout = new Operation(0, CORNER_CUTOUT);
        cornerCutout.dimensions.length = parseNumber(fields.length);
        cornerCutout.dimensions.width = parseNumber(fields.width);
        cornerCutout.side = selectedSide;
        cornerCutout.isFinished = isFinished;

        if (selectedCorner === FRONT_LEFT || selectedCorner === FRONT_RIGHT) {
            cornerCutout.additionalDimension.type = ObjectSides.FRONT;
        } else {
            cornerCutout.additionalDimension.type = ObjectSides.BACK;
        }

        createOperation(cornerCutout, () => props.onClose(true), t(prefix + 'cornerCutoutCreateFailed'));
    }

    const updateCornerCutout = (fields) => {
        currentOperation.dimensions = {length: parseNumber(fields.length), width: parseNumber(fields.width)};
        currentOperation.side = selectedSide;
        currentOperation.isFinished = isFinished;

        if (selectedCorner === FRONT_LEFT || selectedCorner === FRONT_RIGHT) {
            currentOperation.additionalDimension.type = ObjectSides.FRONT;
        } else {
            currentOperation.additionalDimension.type = ObjectSides.BACK;
        }

        updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'cornerCutoutUpdateFailed'));
    }

    const deleteCornerCutout = () => {
        deleteOperation(
            currentOperation,
            () => props.onClose(true),
            t(prefix + 'cornerCutoutDeleteSuccess'),
            t(prefix + 'cornerCutoutDeleteFailed')
        );
    }

    const checkCorners = () => {
        let corners = [];

        if (currentPiece) {
            corners = currentPiece.getAvailableCorners(PROHIBITED_OPERATION_TYPES.CORNER_CUTOUT);
            setAvailableCorners(corners);
        }

        if (corners.includes(FRONT_LEFT)) {
            setSelectedCorner(FRONT_LEFT);
            setSelectedSide(ObjectSides.LEFT);

        } else if (corners.includes(FRONT_RIGHT)) {
            setSelectedCorner(FRONT_RIGHT);
            setSelectedSide(ObjectSides.RIGHT);

        } else if (corners.includes(BACK_LEFT)) {
            setSelectedCorner(BACK_LEFT);
            setSelectedSide(ObjectSides.LEFT);

        } else if (corners.includes(BACK_RIGHT)) {
            setSelectedCorner(BACK_RIGHT);
            setSelectedSide(ObjectSides.RIGHT);
        }
    }

    const closeModal = (fields) => {
        if (!fields) {
            props.onClose();
            return;
        }

        if (currentOperation == null) {
            if (availableCorners.length) {
                createCornerCutout(fields);
            } else {
                setInfoModalIsActive(true);
            }
        } else {
            updateCornerCutout(fields);
        }
    }

    const onCornerChange = (value) => {
        if (value !== BACK_RIGHT && value !== BACK_LEFT
            && value !== FRONT_LEFT && value !== FRONT_RIGHT) return;

        // Set the new value for the radio buttons, so the correct one is shown as selected
        setSelectedCorner(value);
        if (value === BACK_LEFT || value === FRONT_LEFT) {
            setSelectedSide(ObjectSides.LEFT);
        } else {
            setSelectedSide(ObjectSides.RIGHT);
        }

    };

    const getImage = (corner) => {
        let image;

        switch (corner) {
            case FRONT_LEFT:
            case BOTTOM_LEFT:
                image = iconFrontLeft;
                break;
            case FRONT_RIGHT:
            case BOTTOM_RIGHT:
                image = iconFrontRight;
                break;
            case BACK_LEFT:
            case TOP_LEFT:
                image = iconBackLeft;
                break;
            case BACK_RIGHT:
            case TOP_RIGHT:
                image = iconBackRight;
                break;
        }

        return image;
    }

    const toggleConfirmationModal = (userHasAccepted) => {
        if (userHasAccepted) {
            deleteCornerCutout();
        }

        setConfirmationModalIsActive(!confirmationModalIsActive)
    };

    const closeInfoModal = () => {
        setInfoModalIsActive(false);
        props.onClose();
    };

    const toggleFinishCheckbox = () => {
        setIsFinished(!isFinished);
    }

    const renderCornerSelect = () => {
        return <>
            <SectionTitle
                content={t(prefix + 'cornerTitle')}
            />

            {
                [FRONT_LEFT, FRONT_RIGHT, BACK_LEFT, BACK_RIGHT].map((corner, index) => {
                    const cornerName = getCornerNameByPreset(currentConfiguration?.options.preset, corner);

                    return <RadioButton
                        key={`cornerRadioButton${index}`}
                        name="cornerCutoutSide"
                        content={t('constants.objectCorners.' + cornerName)}
                        checked={selectedCorner === corner}
                        onChange={() => onCornerChange(corner)}
                        disabled={!availableCorners.includes(corner) || !canEdit}
                        image={getImage(cornerName)}
                    />
                })
            }
        </>
    };

    const renderFooterButtons = () => {
        let secondaryButtonStyle = 'button button--outline';
        let secondaryButtonContent = t(prefix + 'cancelButton');
        let secondaryButtonOnClick = () => closeModal();

        if (currentOperation != null && canEdit) {
            secondaryButtonStyle = 'button button--danger';
            secondaryButtonContent = t(prefix + 'deleteButton');
            secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
        }

        const submitButton = <button
            key={'cornerCutoutModalSubmitButton'}
            className="button button--primary"
            form="cornerCutoutForm"
            type="submit">
            {t(prefix + 'doneButton')}
        </button>;

        let buttons = [
            <button
                key={'cornerCutoutModalCancelButton'}
                type="button"
                className={secondaryButtonStyle}
                onClick={secondaryButtonOnClick}>{secondaryButtonContent}
            </button>
        ];

        if (canEdit) {
            buttons.unshift(submitButton);
        }

        return buttons;
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <ConfirmationModal
                isActive={confirmationModalIsActive}
                onClose={(userHasAccepted) => toggleConfirmationModal(userHasAccepted)}
                content={t('modals.cornerCutoutModal.deleteCornerCutout')}
            />

            <InfoModal
                isActive={infoModalIsActive}
                onClose={() => closeInfoModal()}
                content={infoModalContent}
            />

            <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
                <Modal.Header className="modal-header">
                    <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="cornerCutoutForm"
                          onSubmit={handleSubmit(closeModal)}>

                        <p>{t(prefix + 'description')}</p>

                        {renderCornerSelect()}

                        <SectionTitle
                            content={t(prefix + 'dimensionTitle')}
                        />

                        <div className="row">
                            <div className="col-6">
                                <ValidatedInput
                                    name="length"
                                    register={register}
                                    label={t(prefix + 'cornerCutoutLengthLabel')}
                                    placeholder={t(prefix + 'cornerCutoutLengthPlaceholder')}
                                    value={currentOperation?.dimensions.length}
                                    disabled={!canEdit}
                                    required={true}
                                    min={1}
                                    max={currentPiece?.dimensions.length - 5}
                                    error={errors.length}
                                />
                            </div>
                            <div className="col-6">
                                <ValidatedInput
                                    name="width"
                                    register={register}
                                    label={t(prefix + 'cornerCutoutWidthLabel')}
                                    placeholder={t(prefix + 'cornerCutoutWidthPlaceholder')}
                                    value={currentOperation?.dimensions.width}
                                    disabled={!canEdit}
                                    required={true}
                                    min={1}
                                    max={currentPiece?.dimensions.width - 1}
                                    error={errors.width}
                                />
                            </div>
                        </div>

                        <SectionTitle
                            content={t(prefix + 'finishTitle')}
                        />

                        <CheckboxWithIcon
                            content={t(prefix + 'finishCheckbox')}
                            checked={isFinished}
                            onChange={() => toggleFinishCheckbox()}
                            disabled={!canEdit}
                        />

                    </form>

                </Modal.Body>
                <Modal.Footer>
                    {renderFooterButtons()}
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );

}

CornerCutoutModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export {CornerCutoutModal};
