import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {setConfirmationModal,} from "../../actions/GeneralActions";
import {SectionTitle} from "../headers/SectionTitle";
import {RadioButton} from "../forms/inputs/RadioButton";
import {OPERATION_SHAPES, RECTANGULAR_CUT_OUT, RECTANGULAR_CUT_OUT_TYPES} from "../../constants/OperationTypes";
import {ValidatedInput} from "../forms/inputs/ValidatedInput";
import {VectorHelper} from "../../classes/helpers/VectorHelper";
import {Operation} from "../../classes/models/Operation";
import {parseNumber} from "../../classes/helpers/StringHelper";
import {Vector3} from "three";
import {LocationSelectorForm} from "./shared/LocationSelectorForm";
import {createOperation, deleteOperation, updateOperation} from "./index";
import {ModalHolder} from "./ModalHolder";

export const RectangularCutOutModal = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.rectangularCutOutModal.';

    const {errors, handleSubmit, register, setError} = useForm();

    const [length, setLength] = useState();
    const [width, setWidth] = useState();
    const [isCentered, setIsCentered] = useState(true);

    const currentOperation = useSelector(state => state.offerReducer.currentOperation);
    const currentPiece = useSelector(state => state.offerReducer.currentPiece);
    const canEdit = useSelector(state => state.generalReducer.canEdit);

    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isActive) {
            if (currentOperation) {
                setLength(currentOperation.dimensions.length);
                setWidth(currentOperation.dimensions.width);
                setIsCentered(VectorHelper.compareVectors(currentOperation.position, new Vector3()));
            } else {
                setLength(null);
                setWidth(null);
                setIsCentered(true);
            }
        }
    }, [props.isActive]);

    const createRectangularCutOut = (fields) => {
        const length = parseNumber(fields.length)
        const width = parseNumber(fields.width)

        if (!validateSize(length, width)) return;

        let rectangularCutOut = new Operation(0, RECTANGULAR_CUT_OUT);
        rectangularCutOut.dimensions.length = length;
        rectangularCutOut.dimensions.width = width;
        rectangularCutOut.additionalDimension.type = RECTANGULAR_CUT_OUT_TYPES.RECTANGULAR;
        rectangularCutOut.position = getPosition(rectangularCutOut, fields);

        createOperation(rectangularCutOut, () => props.onClose(true), t(prefix + 'createRectangularCutOutFailed'));
    }

    const updateRectangularCutOut = (fields) => {
        const length = parseNumber(fields.length)
        const width = parseNumber(fields.width)

        if (!validateSize(length, width)) return;

        currentOperation.dimensions.length = length;
        currentOperation.dimensions.width = width;
        currentOperation.additionalDimension.type = RECTANGULAR_CUT_OUT_TYPES.RECTANGULAR;
        currentOperation.position = getPosition(currentOperation, fields);

        updateOperation(currentOperation, () => props.onClose(true), t(prefix + 'rectangularCutOutUpdateFailed'));
    }

    const deleteRectangularCutOut = () => {
        deleteOperation(
            currentOperation,
            () => props.onClose(true),
            t(prefix + 'rectangularCutOutDeleteSuccess'),
            t(prefix + 'rectangularCutOutDeleteFailed')
        );
    }

    const validateSize = (length, width) => {
        const maxSize = 0.09;
        const actualSize = (length * width) / 10000;

        if (actualSize > maxSize) {
            const message = t(prefix + 'invalidSizeMessage');
            setError('length', {type: 'custom', message: message});
            setError('width', {type: 'custom', message: message});

            return false;
        } else {
            return true;
        }
    }

    const closeModal = (fields) => {
        if (!fields) {
            props.onClose();
            return;
        }

        if (currentOperation) {
            updateRectangularCutOut(fields);
        } else {
            createRectangularCutOut(fields);
        }
    }

    const getPosition = (rectangularCutOut, fields) => {
        let position;

        if (isCentered) {
            position = new Vector3();
        } else {
            position = VectorHelper.getVectorForRectangularCutOut(
                currentPiece.dimensions,
                rectangularCutOut,
                {horizontalSide: fields.horizontalSide, horizontalValue: parseNumber(fields.xField)},
                {verticalSide: fields.verticalSide, verticalValue: parseNumber(fields.yField)},
            );
        }

        return position;
    }

    const renderRectangularTypeDimensionForm = () => {
        return <div className="row">
            <div className="col-6">
                <ValidatedInput
                    register={register}
                    name="length"
                    label={t(prefix + 'lengthLabel')}
                    placeholder={t(prefix + 'lengthPlaceholder')}
                    value={currentOperation?.dimensions.length}
                    onChange={(value => setLength(value))}
                    disabled={!canEdit}
                    error={errors.length}
                    required={true}
                    min={1}
                    max={currentPiece?.dimensions.length - 10}
                />
            </div>
            <div className="col-6">
                <ValidatedInput
                    register={register}
                    name="width"
                    label={t(prefix + 'widthLabel')}
                    placeholder={t(prefix + 'widthPlaceholder')}
                    value={currentOperation?.dimensions.width}
                    onChange={(value => setWidth(value))}
                    disabled={!canEdit}
                    error={errors.width}
                    required={true}
                    min={1}
                    max={currentPiece?.dimensions.width - 5}
                />
            </div>
        </div>
    }

    const renderPositionForm = () => {
        if (!length && !width) return;

        return <>
            <SectionTitle content={t(prefix + 'centeredTitle')}/>

            <RadioButton
                onChange={() => setIsCentered(true)}
                name={"centeredRadioButton"}
                disabled={!canEdit}
                checked={isCentered}
                content={t(prefix + 'centeredRadioButton')}
            />

            <RadioButton
                onChange={() => setIsCentered(false)}
                name={"notCenteredRadioButton"}
                disabled={!canEdit}
                checked={!isCentered}
                content={t(prefix + 'notCenteredRadioButton')}
            />

            {
                !isCentered ?
                    <LocationSelectorForm
                        register={register}
                        errors={errors}
                        variant={OPERATION_SHAPES.RECTANGULAR}
                        operationDimensions={{length: length, width: width}}
                    />
                    : null
            }
        </>
    }

    const renderFooterButtons = () => {
        let secondaryButtonStyle = 'button button--outline';
        let secondaryButtonContent = t(prefix + 'cancelButton');
        let secondaryButtonOnClick = () => closeModal();

        if (currentOperation != null && canEdit) {
            secondaryButtonStyle = 'button button--danger';
            secondaryButtonContent = t(prefix + 'deleteButton');
            secondaryButtonOnClick = () => dispatch(setConfirmationModal({
                isActive: true,
                content: t(prefix + 'confirmationModalContent'),
                onAccept: () => deleteRectangularCutOut()
            }));
        }

        const submitButton = <button
            key={'rectangularCutOutModalSubmitButton'}
            className="button button--primary"
            form="RectangularCutOutForm"
            type="submit">
            {t(prefix + 'doneButton')}
        </button>;

        let buttons = [
            <button
                key={'rectangularCutOutModalCancelButton'}
                type="button"
                className={secondaryButtonStyle}
                onClick={secondaryButtonOnClick}>
                {secondaryButtonContent}
            </button>
        ];

        if (canEdit) {
            buttons.unshift(submitButton);
        }

        return buttons;
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
                <Modal.Header className="modal-header">
                    <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p>{t(prefix + 'description')}</p>

                    <form id="RectangularCutOutForm" onSubmit={handleSubmit(closeModal)}>
                        <SectionTitle content={t(prefix + 'dimensionTitle')}/>

                        {renderRectangularTypeDimensionForm()}

                        {renderPositionForm()}

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {renderFooterButtons()}
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
};

RectangularCutOutModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}
