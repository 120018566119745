import PropTypes from "prop-types";
import React from "react";

export const TableSectionTitle = props => {
    return (
        <div className="article__sectiontitle">
            <span>
                <strong>{props.title}</strong>
                {props.subTitle ? <small>{props.subTitle}</small> : null}
            </span>
        </div>
    )
}

TableSectionTitle.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string
}
