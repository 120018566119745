import {Service} from "./Service";

export class SystemService extends Service {
    async checkupOnQueue() {
        this.anonymousRequest('get', 'system/queue')
            .then(response => {
                if (!response.data.success) {
                    throw Error(response.data.message);
                }
            });
    }
}
