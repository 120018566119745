import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {
    parseNumber,
    parseToCommaSeparated,
} from "../../classes/helpers/StringHelper";
import {OfferService} from "../../classes/services/OfferService";
import {DEFAULT} from "../../constants/PriceTypes";
import CompanyService from "../../classes/services/CompanyService";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import OutdoorShopService from "../../classes/services/OutdoorShopService";
import {DANGER, SUCCESS} from "../../constants/Variants";
import {ValidatedInput} from "../forms/inputs/ValidatedInput";
import {useForm} from "react-hook-form";
import {addAlertMessage} from "../../actions/GeneralActions";
import {SectionTitle} from "../headers/SectionTitle";
import {captureMessage} from "@sentry/react";
import {setCompany} from "../../actions/CompanyActions";
import icon700x250 from '../../assets/icons/700x250.png';

const ArticleModal = (props) => {
    const prefix = "modals.articleModal.";

    const [title, setTitle] = useState("");
    const [total, setTotal] = useState(0);
    const [quantity, setQuantity] = useState();
    const [activeOffer, setActiveOffer] = useState(0);
    const [margin, setMargin] = useState(0);
    const [offers, setOffers] = useState();

    const dispatch = useDispatch();
    const {t} = useTranslation(TRANSLATION_NAMESPACE);

    const offerService = new OfferService();
    const outdoorShopService = new OutdoorShopService();
    const companyService = new CompanyService();

    const {activeArticle, articleConfig} = useSelector(state => state.outdoorShopReducer);
    const {company} = useSelector((state) => state.companyReducer);

    const {handleSubmit, register, errors} = useForm({
        defaultValues: {
            order_title: articleConfig?.title,
        },
    });

    useEffect(() => {
        getCurrentCompany();
        getOffers();
    }, []);

    useEffect(() => {
        setTotal(calculateTotalPrice())
    }, [quantity, margin]);

    useEffect(() => {
        if (props.isEditing) {
            setMargin(articleConfig.margin);
            setQuantity(articleConfig.amount);
            setActiveOffer(articleConfig.offerId);
            setTotal(articleConfig.amount * activeArticle?.priceExclusive);
        }
    }, []);

    const calculateTotalPrice = () => {
        if (!activeArticle) return 0;

        let discount = 0;
        let parsedQuantity = parseNumber(quantity);

        let totalPrice = parsedQuantity * activeArticle.priceExclusive;
        const palletAmount = activeArticle.palletSurfaceArea ?? activeArticle.palletAmount;

        if (parsedQuantity >= palletAmount) {
            const quantityFullPallets = parsedQuantity - (parsedQuantity % palletAmount);
            discount = (quantityFullPallets * activeArticle.priceExclusive) * 0.05

            totalPrice -= discount;
        }

        if (margin) totalPrice *= (parseNumber(margin) / 100 + 1);

        return totalPrice;
    }

    const getCurrentCompany = () => {
        companyService.getCompanyOfCurrentUser()
            .then((response) => {
                if (response.success) {
                    dispatch(setCompany(response.data));
                } else {
                    throw Error(response.message);
                }
            })
            .catch((error) => {
                captureMessage(error.message);
            });
    }

    const getOffers = () => {
        offerService.getAll(DEFAULT, null, company?.id)
            .then((response) => {
                setOffers(response.data);
            })
            .catch(() =>
                dispatch(addAlertMessage(DANGER, t(prefix + "getOffersFailedMessage")))
            );
    }

    const createArticle = (config) => {
        outdoorShopService.create(config, activeArticle.id)
            .then(response => {
                if (response.success) {
                    props.onClose();
                    dispatch(addAlertMessage(SUCCESS, t(prefix + "createConfigurationSuccessMessage")));
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + "createConfigurationFailedMessage")));
                captureMessage(error.message);
            });
    }

    const updateArticle = (config) => {
        outdoorShopService.update(articleConfig.id, config)
            .then(response => {
                if (response.data.success) {
                    props.onClose(response.data.success);
                } else {
                    throw Error(response.data.message)
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + "updateConfigurationFailedMessage")));
                captureMessage(error.message);
            });
    }

    const isDisabled = margin < 0 || quantity < 0 || !activeOffer || +activeOffer === 0;

    const showPalletUnitAmount = () => {
        if (activeArticle?.unit === "M2") {
            return (
                <span>
          {parseToCommaSeparated(activeArticle?.palletSurfaceArea, 2)}m
          <sup>2</sup>
        </span>
            );
        }
        return <span>{activeArticle?.palletAmount}st</span>;
    };

    const submitHandler = async () => {
        if (!margin) {
            setMargin(0);
        }

        const config = {
            offerId: activeOffer,
            title,
            amount: parseNumber(quantity),
            margin: parseNumber(margin),
        };

        if (!props.isEditing) {
            createArticle(config);
        } else {
            updateArticle(config);
        }
    };

    return (
        <Modal
            show={props.isActive}
            onHide={props.onClose}
            animation={true}
            className="modal--pane"
        >
            <Modal.Header>
                <Modal.Title>{activeArticle?.name}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <form onSubmit={handleSubmit(submitHandler)} id="article-form">
                    <img
                        src={icon700x250}
                        alt="placeholder"
                        className="w-100"
                    />
                    <table className="table--bordered m-b-2">
                        <tbody>
                        <tr>
                            <td>
                                <small>{t(prefix + "dimensions")}</small>
                                <br/>
                                {activeArticle?.length}x{activeArticle?.width}x
                                {activeArticle?.height}
                            </td>
                            <td>
                                <small>{t(prefix + "pallet")}</small>
                                <br/>
                                {showPalletUnitAmount()}
                            </td>
                            <td>
                                <small>{t(prefix + "weightPallet")}</small>
                                <br/>
                                {parseToCommaSeparated(activeArticle.getPalletWeight(), 2)}kg
                            </td>
                            <td>
                                <small>{t(prefix + "pricePerUnit")}</small>
                                <br/>
                                € {activeArticle.price}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <SectionTitle content={t(prefix + "order")}/>
                    <div className="form-group">
                        <ValidatedInput
                            label={t(prefix + "orderTitle")}
                            maxLength={50}
                            register={register}
                            error={errors?.order_title}
                            name="orderTitle"
                            type="text"
                            onChange={setTitle}
                        />
                    </div>
                    <div className="form-group">
                        <label>{t(prefix + "addToOffer")}</label>
                        <select
                            value={activeOffer}
                            onChange={(e) => setActiveOffer(e.target.value)}
                        >
                            <option value="0" disabled>
                                {t(prefix + "chooseOffer")}
                            </option>
                            {offers?.map((offer) => (
                                <option key={offer.id} value={offer.id}>
                                    {offer.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="d-flex">
                        <div className="form-group m-r-1">
                            <ValidatedInput
                                label={t(prefix + "quantity")}
                                register={register}
                                min={1}
                                max={1000}
                                name="quantity"
                                required
                                error={errors?.quantity}
                                onChange={setQuantity}
                                value={articleConfig ? parseToCommaSeparated(articleConfig.amount) : ''}
                            />
                        </div>
                        <div className="form-group m-l-1">
                            <ValidatedInput
                                label={`${t(prefix + "margin")} (%)`}
                                register={register}
                                max={300}
                                min={0}
                                name="margin"
                                error={errors?.margin}
                                onChange={setMargin}
                                value={articleConfig ? parseToCommaSeparated(articleConfig.margin) : ''}
                            />
                        </div>
                    </div>

                    <div className="article__order__summary">
                        <span>{t(prefix + "totalPrice")}</span>
                        <strong>€{total ? parseToCommaSeparated(total, 2) : 0}</strong>
                    </div>
                </form>
            </Modal.Body>

            <Modal.Footer>
                <button
                    form="article-form"
                    type="submit"
                    className="button button--primary"
                    disabled={isDisabled}
                >
                    {props.isEditing
                        ? t(prefix + "updateOffer")
                        : t(prefix + "addToOffer")}
                </button>
                <button className="button button--outline" onClick={props.onClose}>
                    {t(prefix + "close")}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default ArticleModal;

ArticleModal.propTypes = {
    onClose: PropTypes.func,
    isActive: PropTypes.bool,
    isEditing: PropTypes.bool,
};

ArticleModal.defaultProps = {
    isEditing: false,
};
