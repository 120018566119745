import React, { useState } from 'react';
import logo from '../../../assets/img/logo.svg';
import { AuthService } from '../../../internal';
import { useTranslation } from "react-i18next";
import history from "../../../config/history";
import { TRANSLATION_NAMESPACE } from "../../../constants/TranslationConstants";
import { useForm } from 'react-hook-form';
import { ValidationMessage } from '../../shared/ValidationMessage';
import { DANGER } from '../../../constants/Variants';

export function ForgotPassword(props) {
    const { t } = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.forgot.';
    const validationPrefix = 'validation.';

    const [displayAlert, setDisplayAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const { errors, register, handleSubmit } = useForm();

    const authService = new AuthService();

    const renderSuccess = () => {
        return <div className="sessionpage">
            <div className="sessionpage__login">
                <div className="sessionpage__login__inner">

                    <h1>
                        <img alt="logo" src={logo} />
                        {t(prefix + 'title')}
                    </h1>

                    <p>{t(prefix + 'success')}</p>
                </div>
            </div>
        </div>
    }

    const renderForm = () => {
        return <div className="sessionpage">
            <div className="sessionpage__login">
                <div className="sessionpage__login__inner">

                    <h1>
                        <img alt="logo" src={logo} />
                        {t(prefix + 'title')}
                    </h1>

                    <form id="forgotPasswordForm"
                        onSubmit={handleSubmit(onForgot)}>

                        <p><strong>{t(prefix + 'description')}</strong></p>

                        <div className="form-group">
                            <label htmlFor="email">{t(prefix + 'emailAddress')}</label>
                            <input
                                name={'email'}
                                type="text"
                                placeholder={t(prefix + 'emailAddressPlaceholder')}
                                ref={register({
                                    required: true,
                                    maxLength: 100,
                                    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}\s*$/i

                                })} />
                            {errors.email?.type === 'required' ?
                                <ValidationMessage
                                    content={t(validationPrefix + 'required')}
                                    variant={DANGER} /> : null}
                            {errors.email?.type === 'maxLength' ?
                                <ValidationMessage
                                    content={t(validationPrefix + 'maxLenght', { max: 100 })}
                                    variant={DANGER} /> : null}
                            {errors.email?.type === 'pattern' ?
                                <ValidationMessage
                                    content={t(validationPrefix + 'email', { max: 100 })}
                                    variant={DANGER} /> : null}
                        </div>
                        <div className="form-group">

                            {displayAlert ? <ValidationMessage
                                content={t(prefix + 'wrongEmail')}
                                variant={DANGER}
                            /> : null}
                        </div>

                        <div className="form-group">
                            {renderButtons()}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    }

    const onForgot = (fields) => {
        let formData = new FormData();

        formData.append('email', fields.email);

        setSuccess(false);
        setIsLoading(true);
        setDisplayAlert(false);

        authService.forgotPassword(formData).then((data) => {
            if (data?.success) {
                setDisplayAlert(true);
                setSuccess(true);
            } else {
                setDisplayAlert(true);
            }
        }).catch((error) => {
            throw error;
        }).finally(() => setIsLoading(false));
    };

    const renderButtons = () => {
        return <div className="m-t-2">
            <button type="submit" className="button button--primary w-100" disabled={isLoading}
                form="forgotPasswordForm">{isLoading ? t(prefix + 'buttonLoading') : t(prefix + 'submitButton')}
            </button>

            <div className="sessionpage__login__footer">
                <span className="link" onClick={() => history.push('/login')}>{t(prefix + 'loginButton')}</span>
            </div>
        </div>
    };

    return (
        <div>
            {success ? renderSuccess() : renderForm()}
        </div>
    );

}