import {BLUESTONE_WEIGHT} from "../../constants/Values";
import {Type1Piece} from "../models/Type1Piece";
import {Type2Piece} from "../models/Type2Piece";
import {Type3Piece} from "../models/Type3Piece";
import {Type4Piece} from "../models/Type4Piece";
import {Type5Piece} from "../models/Type5Piece";
import {Type6Piece} from "../models/Type6Piece";
import {Type7Piece} from "../models/Type7Piece";
import {Type8Piece} from "../models/Type8Piece";
import {RearHeel1_2} from "../models/RearHeel1_2";
import {RearHeel5_2} from "../models/RearHeel5_2";
import {RearHeelCustom} from "../models/RearHeelCustom";
import {CushionNotGlued} from "../models/CushionNotGlued";
import {
    CUSHION_NOT_GLUED, getStandardMeasurementsByType,
    REAR_HEEL_1_2, REAR_HEEL_5_2, REAR_HEEL_CUSTOM,
    TYPE_1, TYPE_10,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7,
    TYPE_8, TYPE_9
} from "../../constants/ObjectTypes";
import * as Dimensions from "../../constants/Dimensions";
import {BASEBOARDS, PILLARS, UPRIGHT_PRESETS} from "../../constants/Presets";
import {BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP} from "../../constants/ObjectSides";
import {
    BACK_LEFT,
    BACK_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_RIGHT,
    FRONT_LEFT,
    FRONT_RIGHT, TOP_LEFT,
    TOP_RIGHT
} from "../../constants/ObjectCorners";
import {
    DEBASE_ROUGH_TYPES,
    DEBASE_ROUGH_TYPES_BASEBOARDS,
    DEBASE_ROUGH_TYPES_PILLARS
} from "../../constants/OperationTypes";
import {HEIGHT, HEIGHT_BACK_SIDE, LENGTH, WIDTH} from "../../constants/Dimensions";

export class ObjectHelper {


    static getBlueStoneWeight(dimensions) {
        let weight = (this.getCubicMetersByDimensions(dimensions) * BLUESTONE_WEIGHT);

        return Math.round(weight * 100) / 100;
    }

    static getCubicMetersByDimensions(dimensions) {
        return (dimensions.length * dimensions.width * dimensions.height) / 1000000;
    }

}

export function compareDimensions(dimensionsA, dimensionsB) {
    let areEqual = true;

    Object.keys(dimensionsA).forEach(name => {
        if (areEqual) {
            areEqual = (dimensionsB[name] == null && dimensionsA[name] == 0) || dimensionsA[name] == dimensionsB[name];
        }
    });

    return areEqual;
}

export function getTypeOfPiece(piece) {
    let type;

    switch (piece.constructor) {
        case Type1Piece:
            type = TYPE_1;
            break;
        case Type2Piece:
            type = TYPE_2;
            break;
        case Type3Piece:
            type = TYPE_3;
            break;
        case Type4Piece:
            type = TYPE_4;
            break;
        case Type5Piece:
            type = TYPE_5;
            break;
        case Type6Piece:
            type = TYPE_6;
            break;
        case Type7Piece:
            type = TYPE_7;
            break;
        case Type8Piece:
            type = TYPE_8;
            break;
        case RearHeel1_2:
            type = REAR_HEEL_1_2;
            break;
        case RearHeel5_2:
            type = REAR_HEEL_5_2;
            break;
        case RearHeelCustom:
            type = REAR_HEEL_CUSTOM;
            break;
        case CushionNotGlued:
            type = CUSHION_NOT_GLUED;
            break;
        default:
            break;
    }

    return type;
}

export function getDimensionsByPieceType(type) {
    let types;

    switch (type) {
        case TYPE_3:
        case TYPE_4:
            types = Dimensions.TYPE_3_DIMENSIONS;
            break;
        case TYPE_5:
        case TYPE_6:
            types = Dimensions.TYPE_5_DIMENSIONS;
            break;
        case TYPE_7:
        case TYPE_8:
            types = Dimensions.TYPE_7_DIMENSIONS;
            break;
        case TYPE_9:
        case TYPE_10:
            types = Dimensions.TYPE_9_DIMENSIONS;
            break;
        case CUSHION_NOT_GLUED:
            // Same dimensions as the default, but in another order
            types = Dimensions.CUSHIONS_NOT_GLUED_DIMENSIONS;
            break;
        default:
            types = [
                Dimensions.LENGTH,
                Dimensions.WIDTH,
                Dimensions.HEIGHT,
            ];
            break;
    }

    return types;
}

export function getSideNameByPreset(preset, side) {
    let sideName;

    if (preset === PILLARS) {
        switch (side) {
            case FRONT:
                sideName = RIGHT;
                break;
            case LEFT:
                sideName = BOTTOM;
                break;
            case BACK:
                sideName = LEFT;
                break;
            case RIGHT:
                sideName = TOP;
                break;
            case TOP:
                sideName = FRONT;
                break;
            case BOTTOM:
                sideName = BACK;
                break;
        }
    } else if (UPRIGHT_PRESETS.includes(preset)) {
        switch (side) {
            case FRONT:
                sideName = BOTTOM;
                break;
            case BACK:
                sideName = TOP;
                break;
            case TOP:
                sideName = FRONT;
                break;
            case BOTTOM:
                sideName = BACK;
                break;
            default:
                sideName = side;
                break;
        }
    } else {
        sideName = side;
    }

    return sideName;
}

export function getCornerNameByPreset(preset, corner) {
    let cornerName;

    if (preset === PILLARS) {
        switch (corner) {
            case FRONT_LEFT:
                cornerName = BOTTOM_RIGHT;
                break;
            case FRONT_RIGHT:
                cornerName = TOP_RIGHT;
                break;
            case BACK_LEFT:
                cornerName = BOTTOM_LEFT;
                break;
            case BACK_RIGHT:
                cornerName = TOP_LEFT;
                break;
        }
    } else if (UPRIGHT_PRESETS.includes(preset)) {
        switch (corner) {
            case FRONT_LEFT:
                cornerName = BOTTOM_LEFT;
                break;
            case FRONT_RIGHT:
                cornerName = BOTTOM_RIGHT;
                break;
            case BACK_LEFT:
                cornerName = TOP_LEFT;
                break;
            case BACK_RIGHT:
                cornerName = TOP_RIGHT;
                break;
        }
    } else {
        cornerName = corner;
    }

    return cornerName;
}

export function getDebasingRoughTypeByPreset(preset, type) {
    let typeName;

    if (preset === PILLARS) {
        switch (type) {
            case DEBASE_ROUGH_TYPES.FRONT_TO_BACK:
                typeName = DEBASE_ROUGH_TYPES_PILLARS.RIGHT_TO_LEFT;
                break;
            case DEBASE_ROUGH_TYPES.BACK_TO_FRONT:
                typeName = DEBASE_ROUGH_TYPES_PILLARS.LEFT_TO_RIGHT;
                break;
            default:
                typeName = type;
        }
    } else if (preset === BASEBOARDS) {
        switch (type) {
            case DEBASE_ROUGH_TYPES.FRONT_TO_BACK:
                typeName = DEBASE_ROUGH_TYPES_BASEBOARDS.BOTTOM_TO_TOP;
                break;
            case DEBASE_ROUGH_TYPES.BACK_TO_FRONT:
                typeName = DEBASE_ROUGH_TYPES_BASEBOARDS.TOP_TO_BOTTOM;
                break;
            default:
                typeName = type;
        }
    } else {
        typeName = type;
    }

    return typeName;
}

export function dimensionIsStandard(dimension, value, configurationOptions) {
    const dimensionsToExclude = [LENGTH, WIDTH, HEIGHT, HEIGHT_BACK_SIDE];
    if (dimensionsToExclude.includes(dimension)) return true;

    const standardDimensions = getStandardMeasurementsByType(configurationOptions.type, configurationOptions.height);

    return parseFloat(standardDimensions[dimension]) === parseFloat(value);
}
