import {COLLECT} from "../../constants/DeliveryOptions";
import {ArticleSubstitutionGroup} from "./ArticleSubstitutionGroup";
import {DeliveryType} from "./DeliveryType";

export class CompanyBranch {
    constructor(id, name) {
        this.id = id;
        this.name = name;
        this.relationCode = '';
        this.language = '';
        this.canOrder = false;
        this.canOrderStock = false;
        this.address = null;
        this.users = [];
        this.deliveryType = null;
        this.defaultDeliveryOption = COLLECT;
        this.deliveryType = new DeliveryType();
        this.articleSubstitutionGroup = new ArticleSubstitutionGroup();
    }
}
