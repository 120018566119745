import * as ThreeConstants from '../../constants/ThreeConstants';
import * as OperationTypes from '../../constants/OperationTypes';
import {DRAW_FINISHED_SIDE_ARROWS} from "../../constants/Settings";
import {TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8} from "../../constants/ObjectTypes";

import {
    Box3,
    BoxGeometry,
    BoxHelper,
    Geometry,
    MathUtils,
    Mesh,
    MeshStandardMaterial,
    Object3D,
    Sprite,
    SpriteMaterial,
    TextureLoader
} from "three";

import CSG from '../../threeJsPlugins/csg';
import {LineCreator, OperationHelper, TypeMeshCreator, VectorHelper} from '../../internal';
import eyeIcon from "../../assets/icons/eye.png";
import OperationMeshCreator from "../services/OperationMeshCreator";
import {createArrowsForFinishedSides} from "./FinishedSidesHelper";
import {SILLS} from "../../constants/Presets";

class MeshCreator {

    constructor() {
        this.operationMeshCreator = new OperationMeshCreator();
        this.standardArrowSize = 3;
    }

    createMeshFromPiece(piece, configurationInfo, text, isSelected, options = {
        hideArrows: false,
        hideWaterlistLine: false
    }) {
        let operationsToSubtractTrees = [];
        let operationsToUnionTrees = [];
        let coupeOperationTrees = [];
        let lineCollection = [];

        let objectDimensions = piece.dimensions;
        let position = piece.position;
        let coupeOperations = [];
        let operationsToSubtract = [];
        let waterListOperations = [];

        const type = configurationInfo.type;
        const standardFinishedSides = configurationInfo.standardFinishedSides;

        if (objectDimensions.width < 1 || objectDimensions.length < 1) return new Mesh(new Geometry(), new MeshStandardMaterial({color: 'red'}));

        //region Create the mesh depending on the type of the object
        let mainMesh = this.__createMainMesh(piece, type);
        // Create a tree for the main mesh
        // Don't push this one to the trees array, because this will be the main object where the others will
        // be subtracted from
        let mainBsp = CSG.fromMesh(mainMesh);
        //endregion

        // Loop over all operations to separate the coupes from the other operations like the notches
        piece.operations.forEach((operation) => {
            if (operation.type === OperationTypes.COUPE) {
                coupeOperations.push(operation);
            } else if (operation.type === OperationTypes.WATERLIST) {
                waterListOperations.push(operation);
            } else {
                operationsToSubtract.push(operation);
            }
        });

        //region Coupe operations
        // Loop over the coupes and create bsp trees for them
        coupeOperations.forEach((operation) => {
            if (operation.dimensions.length <= 0 || operation.dimensions.width <= 0) return;

            let mesh;

            // Create the mesh for the operation and add the position
            mesh = this.operationMeshCreator.generateCoupe(operation);

            // Do nothing when the method generateCoupe returned null
            // This probably means that the position was not set, due to a wrong side being selected
            if (mesh == null) {
                return null;
            }

            // Create a bsp tree with the mesh
            let bspTree = CSG.fromMesh(mesh);

            // Add it to the array with trees for iterating over them later on
            coupeOperationTrees.push(bspTree);
        });
        //endregion

        //region Other operations
        // Loop over all the operations that the method receives
        operationsToSubtract.forEach((operation) => {
            let mesh = null;
            let linesMesh = null;

            switch (operation.type) {
                case OperationTypes.NOTCH:
                    if (operation.position == null) return;

                    const meshAndLines = this.operationMeshCreator.createNotchMeshAndLines(objectDimensions, operation);

                    mesh = meshAndLines.mesh;
                    linesMesh = meshAndLines.linesMesh;
                    break;
                case OperationTypes.CHISELED_SIDE:
                    lineCollection.push(...this.operationMeshCreator.createChiseledSides(operation, piece, type));
                    break;
                case OperationTypes.CORNER_CUTOUT:
                    mesh = this.operationMeshCreator.createCornerCutoutMesh(type, operation, piece.dimensions);
                    lineCollection.push(LineCreator.createStandardLines(mesh));
                    break
                case OperationTypes.GROOVES:
                    mesh = this.operationMeshCreator.createGrooves(operation, piece);
                    lineCollection.push(LineCreator.createOutlines(mesh));
                    break;
                case OperationTypes.ROUNDED_CORNER:
                    mesh = this.operationMeshCreator.createRoundedCorner(operation, piece.dimensions);
                    break;
                case OperationTypes.PROFILE:
                    mesh = this.operationMeshCreator.createProfile(operation, piece, type);
                    break;
                case OperationTypes.DRILL_HOLE:
                    mesh = this.operationMeshCreator.createDrillHole(operation, piece.dimensions.height);
                    break;
                case OperationTypes.COUPE_OVER_LENGTH:
                    mesh = this.operationMeshCreator.createCoupeOverLength(operation, piece.dimensions);
                    break;
                case OperationTypes.GLUED_CUSHION:
                    mesh = this.operationMeshCreator.createGluedCushion(configurationInfo.type, operation, piece);
                    break;
                case OperationTypes.HEIGHT_COUPE:
                    mesh = this.operationMeshCreator.createHeightCoupe(operation, piece, configurationInfo.preset);
                    break;
                case OperationTypes.DEBASING_ROUGH:
                    mesh = this.operationMeshCreator.createDebasingRough(operation, piece.dimensions);
                    break;
                case OperationTypes.RECTANGULAR_CUT_OUT:
                    mesh = this.operationMeshCreator.createRectangularCutOut(operation, piece.dimensions);
                    break;
                case OperationTypes.ANCHOR_HOLE:
                    mesh = this.operationMeshCreator.createAnchorHole(operation);
                    break;
                case OperationTypes.RABAT:
                    mesh = this.operationMeshCreator.createRabat(operation, piece);
                    break;
                case OperationTypes.NOTCH_OVER_LENGTH:
                    mesh = this.operationMeshCreator.createNotchOverLength(operation, piece);
                    break;
                default:
                    break;
            }

            if (mesh != null) {
                if (linesMesh) {
                    lineCollection.push(LineCreator.createStandardLines(linesMesh));
                }

                let bspTree = CSG.fromMesh(mesh);

                if (operation.type === OperationTypes.GLUED_CUSHION) {
                    operationsToUnionTrees.push(bspTree);
                } else {
                    operationsToSubtractTrees.push(bspTree);
                }
            }
        });
        //endregion

        //region Waterlist operation
        if (waterListOperations.length > 0) {
            // TODO Refactor
            for (let i = 0; i < waterListOperations.length; i++) {
                let waterListMesh = this.operationMeshCreator.createWaterList(piece, waterListOperations[i].side, configurationInfo);

                let waterListBspTree = CSG.fromMesh(waterListMesh);

                for (let j = 0; j < coupeOperationTrees.length; j++) {
                    waterListBspTree = waterListBspTree.subtract(coupeOperationTrees[j]);
                }

                for (let k = 0; k < operationsToSubtractTrees.length; k++) {
                    try {
                        waterListBspTree = waterListBspTree.subtract(operationsToSubtractTrees[k]);
                    } catch (e) {
                        // debugger;
                    }
                }

                waterListMesh = CSG.toMesh(waterListBspTree, waterListMesh.matrix);
                const waterListLines = LineCreator.createStandardLines(waterListMesh);
                operationsToSubtractTrees.push(waterListBspTree);

                if (!options.hideWaterlistLine) {
                    // Create dashed line for the waterlist
                    let boundingBoxWaterList = new Box3().setFromObject(waterListMesh);
                    const dashedLine = LineCreator.createWaterlistLines(waterListOperations[i].side, boundingBoxWaterList, piece.dimensions, waterListMesh.position);
                    lineCollection.push(dashedLine);
                }

                let lineGroup = new Object3D();
                lineGroup.position.add(waterListMesh.position);
                waterListLines.position.sub(waterListLines.position);
                lineGroup.add(waterListLines);

                lineGroup.rotateY(waterListMesh.rotation.y);

                lineCollection.push(lineGroup);
            }
        }
        //endregion

        //region Edit main object / Add coupe
        // Loop over all coupes and subtract them from the main object
        // After this the lines for the new main object can be drawn
        coupeOperationTrees.forEach((tree) => {
            mainBsp = mainBsp.subtract(tree);
        });

        // Subtract all the operations from the main bsp tree
        operationsToSubtractTrees.forEach((tree) => {
            if (tree.polygons.length > 0) {
                mainBsp = mainBsp.subtract(tree);
            }
        });

        // Subtract all the operations from the main bsp tree
        operationsToUnionTrees.forEach((tree) => {
            if (tree.polygons.length > 0) {
                mainBsp = mainBsp.union(tree);
            }
        });

        let meshResult = CSG.toMesh(mainBsp, mainMesh.matrix);

        lineCollection.push(LineCreator.createOutlines(meshResult));
        //endregion

        // Create a new mesh of the bps tree with all the operations subtracted
        meshResult = CSG.toMesh(mainBsp, meshResult.matrix);
        meshResult.material = mainMesh.material;

        if (isSelected) meshResult.material.color.set(ThreeConstants.HIGHLIGHTED_OBJECT_COLOR);

        //region Draw lines for showing parts
        const lines = LineCreator.createPartLines(piece.dimensions, piece.parts, type);
        lineCollection.push(...lines);

        //endregion

        //region Add arrows that mark the finished sides
        let arrowList = [];
        if (DRAW_FINISHED_SIDE_ARROWS && isSelected) {
            if (options && options.hideArrows) {
                // Do nothing
            } else {
                arrowList = createArrowsForFinishedSides(piece, standardFinishedSides);
            }
        }
        //endregion

        // Create a sprite of an eye to display in front of the front side of the object
        const eyeSprite = this.createEyeSprite(configurationInfo.preset, piece.dimensions.width);

        //region Add the main object and all lines to a parent object
        // Create a parent object for the main object and the lines
        let parentObject = new Object3D();
        parentObject.name = piece.id;

        // Add the lines to a parent object, along with the type 1 object
        parentObject.add(meshResult, ...arrowList);
        if (isSelected) parentObject.add(eyeSprite);

        parentObject.add(...lineCollection.filter(line => line != null));
        if (text && objectDimensions.length >= 10 && objectDimensions.width >= 3) parentObject.add(text);

        //region Rotate the new object
        parentObject.rotateX(MathUtils.degToRad(piece.rotation.x));
        parentObject.rotateY(MathUtils.degToRad(piece.rotation.y));
        parentObject.rotateZ(MathUtils.degToRad(piece.rotation.z));
        //endregion

        //endregion

        parentObject.position.add(position);

        return parentObject;
    }

    createType1ForCalculating(object, preset = SILLS, withOperations = true) {
        let meshA = new Mesh(
            new BoxGeometry(object.dimensions.length, object.dimensions.height, object.dimensions.width),
            new MeshStandardMaterial({color: 0x03B1FC})
        );
        let coupeMeshes = [];
        let coupeBspTrees = [];
        let meshResult;

        if (withOperations && object.operations != null) {
            // Search for relevant operations like a coupe. A notch for instance should not affect the side vectors
            object.operations.forEach((operation) => {
                if (operation.type === OperationTypes.COUPE) {
                    let coupeMesh = this.operationMeshCreator.generateCoupe(operation);
                    if (coupeMesh != null) {
                        coupeMesh.updateMatrix();
                        coupeMeshes.push(coupeMesh);
                    }
                }
            });
            //endregion

            meshA.updateMatrix();

            let bspA = CSG.fromMesh(meshA);

            // Create BSP trees from all selected operations so they can be 'cut' from the main object later
            coupeMeshes.forEach((mesh) => {
                let coupeBsp = CSG.fromMesh(mesh);
                coupeBspTrees.push(coupeBsp);
            });

            // Cut the selected operations from the main object
            if (coupeBspTrees.length > 0) {
                let bspResult = bspA;

                coupeBspTrees.forEach((tree) => {
                    bspResult = bspResult.subtract(tree);
                });

                // Get the resulting mesh after all operations are added
                meshResult = CSG.toMesh(bspResult, meshA.matrix);
            } else {
                meshResult = meshA;
            }
        } else {
            meshResult = meshA;
        }

        // Add a material
        // This can be redundant because this object probably will never be rendered
        // Can be useful for rendering when debugging though
        meshResult.material = meshA.material;

        // Rotate the object for accurate calculation of the position
        meshResult.rotateX(MathUtils.degToRad(object.rotation.x));
        meshResult.rotateY(MathUtils.degToRad(object.rotation.y));
        meshResult.rotateZ(MathUtils.degToRad(object.rotation.z));

        // Set the position
        meshResult.position.add(object.position);

        meshResult.updateMatrixWorld();

        return meshResult;
    }

    drawBoundingBox(object) {
        let boundingBoxHelper = new BoxHelper(object, 0xff0000);
        let boundingBoxParent = new Object3D();
        boundingBoxParent.add(boundingBoxHelper);
        boundingBoxParent.rotateY(VectorHelper.getRotationValueByAngle(MathUtils.radToDeg(-object.rotation.y)));

        return boundingBoxParent;
    }

    createEyeSprite(preset, pieceWidth) {
        let spriteMap = new TextureLoader().load(eyeIcon);
        let spriteMaterial = new SpriteMaterial({map: spriteMap});
        let eyeSprite = new Sprite(spriteMaterial);
        const position = VectorHelper.getEyeSpritePosition(preset, pieceWidth);
        eyeSprite.position.set(position.x, position.y, position.z);
        // 1/1 ratio
        eyeSprite.scale.set(9, 9, 1);

        return eyeSprite;
    }

    __createMainMesh(piece, type) {
        let mainMesh;

        switch (type) {
            case TYPE_1:
                mainMesh = TypeMeshCreator.createType1Mesh(piece.dimensions);
                break;
            case TYPE_2:
                mainMesh = TypeMeshCreator.createType1Mesh(piece.dimensions);
                break;
            case TYPE_3:
                mainMesh = TypeMeshCreator.createType3Mesh(piece.dimensions);
                break;
            case TYPE_4:
                mainMesh = TypeMeshCreator.createType3Mesh(piece.dimensions);
                break;
            case TYPE_5:
                mainMesh = TypeMeshCreator.createType5Mesh(piece.dimensions);
                break;
            case TYPE_6:
                mainMesh = TypeMeshCreator.createType5Mesh(piece.dimensions);
                break;
            case TYPE_7:
                mainMesh = TypeMeshCreator.createType7Mesh(piece.dimensions, OperationHelper.getCoupesOfPiece(piece));
                break;
            case TYPE_8:
                mainMesh = TypeMeshCreator.createType7Mesh(piece.dimensions, OperationHelper.getCoupesOfPiece(piece));
                break;
            default:
                mainMesh = TypeMeshCreator.createType1Mesh(piece.dimensions);
                break;
        }

        return mainMesh;
    }

}

export {MeshCreator};
