import * as React from 'react';
import PropTypes from 'prop-types';
import {SectionTitle} from "../../headers/SectionTitle";
import {ConfigOption} from "../../forms/selects/ConfigOption";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {SILLS, SILLS_TYPES} from "../../../constants/Presets";
import {RadioButton} from "../../forms/inputs/RadioButton";
import {useSelector} from "react-redux";

export const PresetSelect = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.configurationModal.';
    const constantsPrefix = 'constants.presets.';

    const {currentCompanyBranch} = useSelector(state => state.companyReducer);

    const shouldRenderStockOptions = (preset) => {
        return currentCompanyBranch?.canOrderStock &&
            props.selectedPreset === SILLS &&
            preset === SILLS;
    }

    return (
        <>
            {
                props.isVisible ?
                    <>
                        <SectionTitle
                            content={t(prefix + 'presetTitle')}
                        />
                        <div className="configuration__options">
                            {
                                props.presets.map((preset, index) => {
                                    return <ConfigOption
                                        dataCy={`presetSelect-${preset.toLowerCase()}`}
                                        key={index}
                                        name="preset"
                                        content={t(constantsPrefix + preset)}
                                        selected={props.selectedPreset === preset}
                                        onClick={() => props.setPreset(preset)}
                                        marginBottom={shouldRenderStockOptions(preset) ? 0 : 2}
                                    >
                                        {
                                            shouldRenderStockOptions(preset) ?
                                                <div className="m-t-1">
                                                    {
                                                        SILLS_TYPES.map(type => {
                                                            return <RadioButton
                                                                key={type}
                                                                onChange={() => props.setSubPreset(type)}
                                                                checked={props.selectedSubPreset === type}
                                                                content={t(constantsPrefix + type)}
                                                                name="subPresetRadioButton"
                                                                marginBottom={0}
                                                                className="m-l-3"
                                                            />
                                                        })
                                                    }
                                                </div>
                                                : null
                                        }
                                    </ConfigOption>
                                })
                            }
                        </div>
                    </>
                    : null
            }
        </>
    );
};

PresetSelect.propTypes = {
    isVisible: PropTypes.bool,
    presets: PropTypes.array,
    selectedPreset: PropTypes.string,
    setPreset: PropTypes.func.isRequired,
    selectedSubPreset: PropTypes.string,
    setSubPreset: PropTypes.func.isRequired,
}
