import * as React from 'react';
import PropTypes from 'prop-types';
import {DANGER} from "../../constants/Variants";

export const AppHeaderDropdownItem = (props) => {
    const getStyling = () => {
        let defaultStyling = 'pointer dropdown-item';
        const disabledStyling = ' disabled';

        if (props.disabled) return defaultStyling + disabledStyling;

        return defaultStyling;
    }

    const renderContent = () => {
        if (props.variant === DANGER) {
            return <span className="color--red">
                {props.content}
            </span>
        }

        return props.content;
    }

    const onClick = () => {
        if (!props.onClick) return;

        props.onClick();
    }

    return (
        <span
            data-cy={props.dataCy}
            className={getStyling()}
            onClick={() => onClick()}>
            {renderContent()}
        </span>
    );
};

AppHeaderDropdownItem.propTypes = {
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    dataCy: PropTypes.string,
}

AppHeaderDropdownItem.defaultProps = {
    disabled: false,
    variant: '',
}