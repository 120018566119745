export function capitalizeString(text) {
    text = text.toLowerCase();

    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function normalizeConstantString(text, capitalised = false) {
    if (capitalised) {
        text = capitalizeString(text);
    }

    return text.replace(new RegExp('_', 'g'), ' ');
}

export function parseNumber(number) {
    number = number + '';

    const parsedNumber = parseFloat(number.replace(',', '.'));

    return isNaN(parsedNumber) ? 0 : parsedNumber;
}

export function parseToCommaSeparated(number, precision = 0) {
    if (!number) return 0;

    if (precision) {
        if (typeof number === 'string') number = parseFloat(number);

        number = number.toFixed(precision);
    }

    return number.toString().replace('.', ',');
}
