import * as ConnectStyles from "../../constants/ConnectObjectStyles";

class RotationHelper {

    static getRotationByConnectionStyle(mainObjectRotation, connectionStyle) {

        Object.keys(mainObjectRotation).forEach(key => {
            mainObjectRotation[key] = parseFloat(mainObjectRotation[key]);
        });

        let rotation;

        switch (connectionStyle) {
            case ConnectStyles.LEFT_UP:
                rotation = mainObjectRotation.y - 90;
                break;
            case ConnectStyles.LEFT_DOWN:
                rotation = mainObjectRotation.y + 90;
                break;
            case ConnectStyles.LEFT_BACK_UP:
                rotation = mainObjectRotation.y - 90;
                break;
            case ConnectStyles.LEFT_FRONT_DOWN:
                rotation = mainObjectRotation.y + 90;
                break;
            case ConnectStyles.LEFT_HORIZONTAL:
                rotation = mainObjectRotation.y;
                break;
            case ConnectStyles.RIGHT_UP:
                rotation = mainObjectRotation.y + 90;
                break;
            case ConnectStyles.RIGHT_DOWN:
                rotation = mainObjectRotation.y - 90;
                break;
            case ConnectStyles.RIGHT_BACK_UP:
                rotation = mainObjectRotation.y + 90;
                break;
            case ConnectStyles.RIGHT_FRONT_DOWN:
                rotation = mainObjectRotation.y - 90;
                break;
            case ConnectStyles.RIGHT_HORIZONTAL:
                rotation = mainObjectRotation.y;
                break;
            default:
                break;
        }

        return rotation;
    }

}

export {RotationHelper};