import * as React from 'react';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import history from "../../config/history";

export const EmptyOrdersPage = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'empty.ordersPage.';

    const toOffers = () => {
        history.push('/offers');
    }

    return (
        <div className="message message--empty">

            <h4>{t(prefix + 'title')}</h4>
            <p>{t(prefix + 'content')}</p>

            <div className="message__actions">
                <button type="button" className="button button--primary button--icon-after" onClick={() => toOffers()}>
                    {t(prefix + 'actionButton')} <i className="m-l-1 material-icons">arrow_forward</i>
                </button>
            </div>

        </div>
    );
};