import {store} from '../../store/index';
import * as OperationTypes from '../../constants/OperationTypes';
import {LEFT, RIGHT} from "../../constants/ObjectSides";
import {LEFT_STYLES, RIGHT_STYLES} from "../../constants/ConnectObjectStyles";
import DisjointSet from 'disjoint';

class CollectionHelper {

    static getHighestId(array) {
        let highestId = 0;

        if (array != null) {
            array.forEach((item) => {
                if (item.id > highestId) {
                    highestId = item.id;
                }
            });
        }

        return highestId;
    }

    static getById(collection, id) {

        for (let i = 0; i < collection.length; i++) {
            if (collection[i].id === id) {
                return collection[i];
            }
        }

        return null;
    }

    static isInCollection(value, collection) {

        for (let i = 0; i < collection.length; i++) {
            if (collection[i] === value) {
                return true;
            }
        }

        return false;
    }

    static getConfigurationById(id) {
        let state = store.getState();
        let configurations = state.offerReducer.configurations;

        for (let i = 0; i < configurations.length; i++) {
            if (configurations[i].id === id) return configurations[i];
        }

        if (configurations.length > 0) {
            return configurations[0]
        } else {
            return null;
        }
    }

    static getNewOperationId() {
        let id = 0;

        let state = store.getState();
        let currentConfiguration = state.offerReducer.currentConfiguration;

        for (let i = 0; i < currentConfiguration.pieces.length; i++) {
            for (let j = 0; j < currentConfiguration.pieces[i].operations.length; j++) {
                if (currentConfiguration.pieces[i].operations[j].id > id) {
                    id = currentConfiguration.pieces[i].operations[j].id;
                }
            }
        }

        return id + 1;
    }

    static getNewObjectId() {
        let state = store.getState();
        let currentConfiguration = state.offerReducer.currentConfiguration;

        let id = 0;

        for (let i = 0; i < currentConfiguration.pieces.length; i++) {
            if (currentConfiguration.pieces[i].id > id) {
                id = currentConfiguration.pieces[i].id;
            }
        }

        return id + 1;
    }

    static getPieceById(id) {
        let state = store.getState();
        let currentConfiguration = state.offerReducer.currentConfiguration;

        for (let k = 0; k < currentConfiguration.pieces.length; k++) {
            if (currentConfiguration.pieces[k].id === parseInt(id)) {
                return currentConfiguration.pieces[k];
            }
        }

        return null;
    }

    static getOperationById(operationId) {
        let coupe = null;
        let state = store.getState();
        let currentConfiguration = state.offerReducer.currentConfiguration;

        for (let i = 0; i < currentConfiguration.pieces.length; i++) {
            for (let j = 0; j < currentConfiguration.pieces[i].operations.length; j++) {
                if (currentConfiguration.pieces[i].operations[j].id === operationId) {
                    coupe = currentConfiguration.pieces[i].operations[j];
                }
            }
        }

        return coupe;
    }

    static getNewOrderId() {
        let state = store.getState();
        let id = 0;

        for (let i = 0; i < state.orderReducer.orders.length; i++) {
            if (state.orderReducer.orders[i].id > id) {
                id = state.orderReducer.orders[i].id;
            }
        }

        return id + 1;
    }

    static getOrderById(id) {
        id = parseInt(id);

        if (id == null || id === 0) return null;

        let state = store.getState();
        const orders = state.orderReducer.orders;

        for (let i = 0; i < orders.length; i++) {
            if (orders[i].id === id) {
                return orders[i];
            }
        }

        return null;
    }

    static getAvailableSidesForConnectionWithoutCoupe(piece) {
        if (piece == null) {
            return [];
        }

        let availableSides = [];
        let leftIsAvailable = true;
        let rightIsAvailable = true;

        for (let i = 0; i < piece.connectedObjects.length; i++) {
            // if (LEFT_STYLES.includes(piece.connectedObjects[i].style)) {
            //     leftIsAvailable = false;
            // }

            // if (RIGHT_STYLES.includes(piece.connectedObjects[i].style)) {
            //     rightIsAvailable = false;
            // }
        }

        for (let i = 0; i < piece.operations.length; i++) {
            if (piece.operations[i].type === OperationTypes.COUPE) {
                if (piece.operations[i].side === LEFT) leftIsAvailable = false;
                if (piece.operations[i].side === RIGHT) rightIsAvailable = false;
            }
        }

        if (leftIsAvailable) {
            availableSides.push(LEFT);
        }

        if (rightIsAvailable) {
            availableSides.push(RIGHT);
        }

        return availableSides;
    }

    static getAvailableSidesForConnectionByCoupe(object) {
        if (object == null) {
            return [];
        }

        let availableSides = [];

        let leftIsAvailable = true;
        let rightIsAvailable = true;

        for (let i = 0; i < object.connectedObjects.length; i++) {
            if (LEFT_STYLES.includes(object.connectedObjects[i].style)) {
                leftIsAvailable = false;
            }

            if (RIGHT_STYLES.includes(object.connectedObjects[i].style)) {
                rightIsAvailable = false;
            }
        }

        for (let i = 0; i < object.operations.length; i++) {
            if (object.operations[i].type === OperationTypes.COUPE && object.operations[i].connectedCoupe != null) {
                object.operations[i].side === LEFT ? leftIsAvailable = false : rightIsAvailable = false;
            }
        }

        if (leftIsAvailable) availableSides.push(LEFT);

        if (rightIsAvailable) availableSides.push(RIGHT);

        return availableSides;
    }

    static getSumOfArray(array, roundDown = false) {
        if (array == null || array.length === 0) return 0;

        const sum = (() => {
            let total = 0;

            array.forEach((value) => {
                value = parseFloat(value);

                if (!isNaN(value)) total += value;
            });

            return total;
        })();

        if (roundDown) return Math.floor(sum);

        return sum;
    }

}

export {CollectionHelper};

export function getConnectedPieces(pieceList) {
    const set = new DisjointSet(
        pieceList.length,
        disjointReducer,
        {maxWeight: 0}
    )

    pieceList.forEach(piece => {
        if (piece.connectedObjects.length > 0) {
            const currentPieceIndex = pieceList.findIndex(p => p.id === piece.id);
            // Get first entry in array because the second one will be handled in another iteration
            const connectedPieceIndex = pieceList.findIndex(p => p.id === piece.connectedObjects[0].id);

            set.union(currentPieceIndex, connectedPieceIndex, {weight: 1});
        }
    });

    const subsets = set.subsets();

    let connectedPieces = [];

    subsets.forEach(subset => {
        let subsetPieces = [];

        subset.forEach(pieceIndex => {
            subsetPieces.push(pieceList[pieceIndex]);
        });

        connectedPieces.push(subsetPieces);
    });

    return connectedPieces;
}

function disjointReducer(s1, s2, edge) {
    return {
        maxWeight: Math.max(s1?.maxWeight, s2?.maxWeight, edge.weight)
    };
}
