import * as GrooveTypes from '../../constants/GrooveTypes';
import * as ProfileOperationTypes from '../../constants/ProfileOperationTypes';
import {
    BACK,
    FRONT,
    LEFT,
    PROFILE_SIDES_TYPE_1,
    PROFILE_SIDES_TYPE_3,
    PROFILE_SIDES_TYPE_5,
    PROFILE_SIDES_TYPE_7,
    PROFILE_SIDES_TYPE_9,
    RIGHT
} from "../../constants/ObjectSides";
import {BACK_LEFT, BACK_RIGHT, FRONT_LEFT, FRONT_RIGHT} from "../../constants/ObjectCorners";
import {
    ANCHOR_HOLE,
    CHISELED_SIDE,
    CORNER_CUTOUT,
    COUPE,
    COUPE_OVER_LENGTH,
    DEBASE_ROUGH_TYPES,
    DEBASING,
    DEBASING_ROUGH,
    DRILL_HOLE,
    EXTRA_SANDING_5CM_BOTTOM,
    FINISHED_SIDE,
    GLUED_CUSHION,
    GROOVES,
    HEIGHT_COUPE,
    NOTCH,
    NOTCH_OVER_LENGTH,
    PROFILE,
    RABAT,
    RECTANGULAR_CUT_OUT,
    RECTANGULAR_CUT_OUT_TYPES,
    ROUNDED_CORNER,
    SUMMARIZED_OPERATIONS,
    WATERLIST
} from "../../constants/OperationTypes";
import {parseNumber, parseToCommaSeparated} from "./StringHelper";
import {Operation} from "../models/Operation";
import notchIcon from "../../assets/img/config-inkeping.png";
import coupeIcon from "../../assets/img/config-coupe.png";
import finishIcon from "../../assets/img/config-afwerking.png";
import cornerCutOutLeftFrontIcon from "../../assets/img/config-hoek-linksvoor.png";
import cornerCutOutLeftBackIcon from "../../assets/img/config-hoek-linksachter.png";
import cornerCutOutRightFrontIcon from "../../assets/img/config-hoek-rechtsvoor.png";
import cornerCutOutRightBackIcon from "../../assets/img/config-hoek-rechtsachter.png";
import groovesChiseledIcon from "../../assets/img/config-groeven-geciseleerd.png";
import groovesContinuousIcon from "../../assets/img/config-groeven-doorlopend.png";
import groovesStoppingRightIcon from "../../assets/img/config-groeven-stoppend-recht.png";
import groovesStoppingRoundIcon from "../../assets/img/config-groeven-stoppend-rond.png";
import roundedCornerLeftFrontIcon from '../../assets/img/config-afgeronde-hoek-linksvoor.png';
import roundedCornerLeftBackIcon from '../../assets/img/config-afgeronde-hoek-linksachter.png';
import roundedCornerRightFrontIcon from '../../assets/img/config-afgeronde-hoek-rechtsvoor.png';
import roundedCornerRightBackIcon from '../../assets/img/config-afgeronde-hoek-rechtsachter.png';
import profileType1Icon from "../../assets/img/config-profiel-type-1.png";
import profileType3Icon from "../../assets/img/config-profiel-type-3.png";
import profileType4Icon from "../../assets/img/config-profiel-type-4.png";
import profileType5Icon from "../../assets/img/config-profiel-type-5.png";
import drillHoleIcon from "../../assets/img/config-boorgat.png";
import heightCoupeLeftInnerIcon from "../../assets/img/config-dikteverstek-links-binnenhoek.png";
import heightCoupeLeftOuterIcon from "../../assets/img/config-dikteverstek-links-buitenhoek.png";
import heightCoupeRightInnerIcon from "../../assets/img/config-dikteverstek-rechts-binnenhoek.png";
import heightCoupeRightOuterIcon from "../../assets/img/config-dikteverstek-rechts-buitenhoek.png";
import coupeOverLengthIcon from "../../assets/img/config-coupe-lengte.png";
import gluedCushionLeftIcon from "../../assets/img/config-verlijmd-kussen-links.png";
import gluedCushionRightIcon from "../../assets/img/config-verlijmd-kussen-rechts.png";
import debaseIcon from '../../assets/img/config-ravaleren.png';
import rectangularCutOutRoundIcon from '../../assets/img/config-uitzagen-rechthoek-rond.png';
import rectangularCutOutIcon from '../../assets/img/config-uitzagen-rechthoek.png';
import debasingRoughFrontToBackIcon from '../../assets/img/config-ravaleren-voor-achter.png';
import debasingRoughBackToFrontIcon from '../../assets/img/config-ravaleren-achter-voor.png';
import debasingRoughMiddleToSidesIcon from '../../assets/img/config-ravaleren-midden.png';
import anchorHoleIcon from '../../assets/img/config-verankeringsgat.png';
import rabatIcon from '../../assets/img/config-rabat.png';
import notchOverLengthIcon from '../../assets/img/config-inkeping-over-lengte.png';
import waterlistIcon from '../../assets/img/config-waterlijst.png';
import extraSandedBottomIcon from '../../assets/img/config-extra-schuren-5cm-onderkant.png';
import pieceIcon from "../../assets/img/config-steen.png";
import dividePieceIcon from "../../assets/img/config-opdeling-stuk.png";
import {INNER} from "../../constants/Angles";
import * as PieceActionTypes from '../../constants/PieceActionTypes'
import {MathUtils} from "three";
import {
    TYPE_1,
    TYPE_10,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7,
    TYPE_8,
    TYPE_9
} from "../../constants/ObjectTypes";
import {getOperationBoundingPosition, getPieceBoundingPosition} from "./BoundingPositionHelper";
import {cloneDeep} from "lodash";
import {VectorHelper} from "./VectorHelper";
import * as Values from "../../constants/Values";
import {DEGREES} from "../../constants/Values";
import {LENGTH, WIDTH} from "../../constants/Dimensions";

class OperationHelper {

    static getBottomSideLengthOfCoupeByAngleDegrees(angleDegrees, leftSideLength) {
        let length;
        const cornerA = parseFloat(angleDegrees);
        const cornerB = 90;
        const cornerC = 180 - (cornerA + cornerB);
        leftSideLength = parseFloat(leftSideLength);

        // Math.sin expects radians instead of degrees, so convert them first to get a correct result
        length = leftSideLength * Math.sin(MathUtils.degToRad(cornerC)) / Math.sin(MathUtils.degToRad(cornerA));

        return length;
    }

    static getBottomSideLengthOfHeightCoupeByAngleDegrees(angleDegrees, leftSideLength) {
        let length;
        const cornerA = parseFloat(angleDegrees);
        const cornerB = 90;
        const cornerC = 180 - (cornerA + cornerB);
        leftSideLength = parseFloat(leftSideLength);

        // Math.sin expects radians instead of degrees, so convert them first to get a correct result
        length = leftSideLength * Math.sin(MathUtils.degToRad(cornerA)) / Math.sin(MathUtils.degToRad(cornerC));

        return length;
    }

    static objectHasCoupeOnSide(object, objectSide) {
        let hasCoupeOnSide = false;

        if (object == null) {
            return;
        }

        object.operations.forEach((operation) => {
            if (operation.type === COUPE && operation.side === objectSide) {
                hasCoupeOnSide = true;
            }
        });

        return hasCoupeOnSide;
    }

    static getCoupesOfPiece(piece) {
        if (piece == null || piece.operations == null) return [];

        let coupes = [];

        piece.operations.forEach(operation => {
            if (operation.type === COUPE) {
                coupes.push(operation);
            }
        })

        return coupes;
    }

    static getAvailableSidesForCoupe(object) {
        let coupeList = this.getCoupesOfPiece(object).map(coupe => coupe.side);
        const sides = [LEFT, RIGHT];

        return sides.filter(side => !coupeList.includes(side));
    }

}

export {OperationHelper};

export function determineCorner(sideA, sideB) {
    const sides = [sideA, sideB];
    let corner;

    if (sides.includes(FRONT) && sides.includes(LEFT)) {
        corner = FRONT_LEFT;
    } else if (sides.includes(FRONT) && sides.includes(RIGHT)) {
        corner = FRONT_RIGHT;
    } else if (sides.includes(BACK) && sides.includes(LEFT)) {
        corner = BACK_LEFT;
    } else {
        corner = BACK_RIGHT;
    }

    return corner;
}

export function getUniqueOperations(operations) {
    let uniqueOperations = [];

    operations.forEach(operation => {
        if (SUMMARIZED_OPERATIONS.includes(operation.type)) {
            const index = uniqueOperations.findIndex(o => o.type === operation.type)

            if (index === -1) {
                // Clone the operation
                operation = Object.assign(new Operation(), operation);

                operation.count = 1;
                uniqueOperations.push(operation);
            } else {
                const uniqueOperationPrice = parseNumber(uniqueOperations[index].price);
                const operationPrice = parseNumber(operation.price);

                uniqueOperations[index].price = parseToCommaSeparated(
                    (uniqueOperationPrice + operationPrice).toFixed(2)
                );
                uniqueOperations[index].count += 1;
            }
        } else {
            uniqueOperations.push(operation);
        }
    });

    return uniqueOperations;
}

export function getOperationIcon(type, side, additionalType) {
    let icon;

    switch (type) {
        case NOTCH:
            icon = notchIcon;
            break;
        case NOTCH_OVER_LENGTH:
            icon = notchOverLengthIcon;
            break;
        case COUPE:
            icon = coupeIcon;
            break;
        case CHISELED_SIDE:
        case FINISHED_SIDE:
            icon = finishIcon
            break;
        case DEBASING:
            icon = debaseIcon;
            break;
        case WATERLIST:
            icon = waterlistIcon;
            break;
        case CORNER_CUTOUT:
            if (side === LEFT) {
                if (additionalType === FRONT) {
                    icon = cornerCutOutLeftFrontIcon;
                } else {
                    icon = cornerCutOutLeftBackIcon;
                }
            } else {
                if (additionalType === FRONT) {
                    icon = cornerCutOutRightFrontIcon;
                } else {
                    icon = cornerCutOutRightBackIcon;
                }
            }
            break;
        case GROOVES:
            switch (additionalType) {
                case GrooveTypes.CHISELED:
                    icon = groovesChiseledIcon;
                    break;
                case GrooveTypes.CONTINUOUS:
                    icon = groovesContinuousIcon;
                    break;
                case GrooveTypes.STOPPING_RIGHT:
                    icon = groovesStoppingRightIcon;
                    break;
                case GrooveTypes.STOPPING_ROUND:
                    icon = groovesStoppingRoundIcon;
                    break;
                default:
                    icon = groovesContinuousIcon;
                    break;
            }
            break;
        case ROUNDED_CORNER:
            icon = getRoundedCornerIcon(side, additionalType);
            break;
        case PROFILE:
            icon = getProfileIcon(additionalType);
            break;
        case DRILL_HOLE:
            icon = drillHoleIcon;
            break;
        case HEIGHT_COUPE:
            if (side === LEFT) {
                if (additionalType === INNER) {
                    icon = heightCoupeLeftInnerIcon;
                } else {
                    icon = heightCoupeLeftOuterIcon;
                }
            } else {
                if (additionalType === INNER) {
                    icon = heightCoupeRightInnerIcon;
                } else {
                    icon = heightCoupeRightOuterIcon;
                }
            }
            break;
        case COUPE_OVER_LENGTH:
            icon = coupeOverLengthIcon;
            break;
        case DEBASING_ROUGH:
            if (additionalType === DEBASE_ROUGH_TYPES.FRONT_TO_BACK) {
                icon = debasingRoughFrontToBackIcon;
            } else if (additionalType === DEBASE_ROUGH_TYPES.BACK_TO_FRONT) {
                icon = debasingRoughBackToFrontIcon;
            } else {
                icon = debasingRoughMiddleToSidesIcon;
            }
            break;
        case RECTANGULAR_CUT_OUT:
            icon = additionalType === RECTANGULAR_CUT_OUT_TYPES.ROUND ? rectangularCutOutRoundIcon : rectangularCutOutIcon;
            break;
        case ANCHOR_HOLE:
            icon = anchorHoleIcon;
            break;
        case GLUED_CUSHION:
            if (side === LEFT) {
                icon = gluedCushionLeftIcon;
            } else {
                icon = gluedCushionRightIcon;
            }
            break;
        case RABAT:
            icon = rabatIcon;
            break;
        case PieceActionTypes.DIVIDE_PIECE:
            icon = dividePieceIcon;
            break;
        case EXTRA_SANDING_5CM_BOTTOM:
            icon = extraSandedBottomIcon;
            break;
        default:
            icon = pieceIcon;
            break;
    }

    return icon;
}

export function getRoundedCornerIcon(side, additionalType) {
    let icon;

    if (side === LEFT) {
        if (additionalType === FRONT) {
            icon = roundedCornerLeftFrontIcon;
        } else {
            icon = roundedCornerLeftBackIcon;
        }
    } else {
        if (additionalType === FRONT) {
            icon = roundedCornerRightFrontIcon;
        } else {
            icon = roundedCornerRightBackIcon;
        }
    }

    return icon;
}

export function getProfileIcon(type) {
    let icon;

    switch (type) {
        case ProfileOperationTypes.TYPE_1:
        case ProfileOperationTypes.ROUNDED:
            icon = profileType1Icon;
            break;
        case ProfileOperationTypes.TYPE_3:
        case ProfileOperationTypes.HALF_ROUNDED:
            icon = profileType3Icon;
            break;
        case ProfileOperationTypes.TYPE_4:
        case ProfileOperationTypes.SLOPING:
            icon = profileType4Icon;
            break;
        default:
            // TYPE 5
            icon = profileType5Icon;
            break;
    }

    return icon;
}

export function summarizeOperations(operations) {
    let summarizedOperations = [];

    operations.forEach(operation => {
        if (SUMMARIZED_OPERATIONS.includes(operation.type)) {
            let indexOf;

            if (operation.type === PROFILE) {
                indexOf = summarizedOperations.findIndex(summarizedOperation =>
                    summarizedOperation.type === operation.type &&
                    summarizedOperation.additionalType === operation.additionalDimension.type &&
                    summarizedOperation.data === operation.data
                );
            } else {
                indexOf = summarizedOperations.findIndex(summarizedOperation =>
                    summarizedOperation.type === operation.type
                );
            }

            if (indexOf >= 0) {
                summarizedOperations[indexOf].count += 1;
            } else {
                summarizedOperations.push({
                    type: operation.type,
                    count: 1,
                    additionalType: operation.additionalDimension.type,
                    data: operation.data
                });
            }
        }
    });

    return summarizedOperations;
}

export function getSidesForProfilesByPieceType(pieceType) {
    if (!pieceType) return [];

    let sides;

    switch (pieceType) {
        case TYPE_1:
        case TYPE_2:
            sides = PROFILE_SIDES_TYPE_1;
            break;
        case TYPE_3:
        case TYPE_4:
            sides = PROFILE_SIDES_TYPE_3;
            break;
        case TYPE_5:
        case TYPE_6:
            sides = PROFILE_SIDES_TYPE_5;
            break;
        case TYPE_7:
        case TYPE_8:
            sides = PROFILE_SIDES_TYPE_7;
            break;
        case TYPE_9:
        case TYPE_10:
            sides = PROFILE_SIDES_TYPE_9;
            break;
        default:
            sides = [];
            break;
    }

    return sides;
}

export function getOperationsToRelocate(initialPiece, dimensionName, newDimensionValue) {
    let operationsToRelocate = [];
    let piece = cloneDeep(initialPiece);
    piece.dimensions[dimensionName] = newDimensionValue;
    piece.recalculateOperations(initialPiece.dimensions);

    piece.operations.forEach(operation => {
        const side = getSideForBoundingPosition(initialPiece.dimensions, dimensionName, newDimensionValue, operation);
        const boundingPositionPiece = getPieceBoundingPosition(piece.dimensions, side);
        const boundingPositionOperation = getOperationBoundingPosition(operation, side);

        if (boundingPositionOperation) {
            if (side === RIGHT) {
                if (boundingPositionOperation.x > boundingPositionPiece.x) {
                    operationsToRelocate.push(operation);
                }
            } else if (side === BACK) {
                if (boundingPositionOperation.z < boundingPositionPiece.z) {
                    operationsToRelocate.push(operation);
                }
            } else if (side === FRONT) {
                if (boundingPositionOperation.z > boundingPositionPiece.z) {
                    operationsToRelocate.push(operation);
                }
            }
        }
    })

    return operationsToRelocate;
}

export function recalculateCoupePosition(newDimensions, previousDimensions, coupe) {
    coupe.dimensions.height = newDimensions.height;

    if (newDimensions.width < coupe.dimensions.width) {
        coupe.dimensions.width = newDimensions.width;
    }

    if (coupe.additionalDimension.type === Values.CENTIMETERS) {
        coupe.dimensions.length = newDimensions.length - coupe.additionalDimension.value;
    }

    coupe.position = VectorHelper.getVectorForCoupe(newDimensions, coupe);

    return coupe;
}

export function recalculateRectangularCutOut({dimensions}, previousDimensions, rectangularCutOut) {
    let horizontalValue;

    if (rectangularCutOut.position.x > 0) {
        horizontalValue = rectangularCutOut.position.x + previousDimensions.length / 2 - rectangularCutOut.dimensions.length / 2;
    } else {
        horizontalValue = previousDimensions.length / 2 - Math.abs(rectangularCutOut.position.x);
    }

    rectangularCutOut.position = VectorHelper.getVectorForRectangularCutOut(
        dimensions,
        rectangularCutOut,
        {
            horizontalSide: LEFT,
            horizontalValue: horizontalValue
        },
        {
            verticalSide: FRONT,
            verticalValue: Math.abs(previousDimensions.width / 2 - rectangularCutOut.position.z - rectangularCutOut.dimensions.width / 2)
        },
    );

    return rectangularCutOut;
}

export function getDistanceFromLeft({length}, selectedSide, value) {
    if (selectedSide === LEFT) {
        return value;
    } else {
        return length - value;
    }
}

export function getDistanceFromFront({width}, selectedSide, value) {
    if (selectedSide === FRONT) {
        return value;
    } else {
        return width - value;
    }
}

/**
 * Get the vertical side that is closest to the operation
 * @param operation
 * @returns {string}
 */
export function getClosestVerticalSide(operation) {
    if (operation.position.z > 0) return FRONT;

    return BACK;
}

/**
 * Get the horizontal side that is closest to the operation
 * @param operation
 * @returns {string}
 */
export function getClosestHorizontalSide(operation) {
    if (operation.position.x > 0) return RIGHT;

    return LEFT;
}

export function getHeightCoupeWidth(coupe, pieceDimensions) {
    let degreesOfCoupe;

    if (coupe.additionalDimension.type === DEGREES) {
        degreesOfCoupe = coupe.additionalDimension.value;
    } else {
        degreesOfCoupe = VectorHelper.getAngleByOppositeAndAdjacentSides(
            coupe.additionalDimension.value,
            pieceDimensions.height
        )[0];
    }

    const remainingDegrees = 90 - degreesOfCoupe;

    return pieceDimensions.height * Math.sin(MathUtils.degToRad(degreesOfCoupe)) /
        Math.sin(MathUtils.degToRad(remainingDegrees));
}

export function shouldHaveExceptionalQuality(type, height, existingPieces = []) {
    return [TYPE_1, TYPE_2].includes(type) && height >= 6;
}

function getSideForBoundingPosition(initialDimensions, dimensionName, newDimensionValue, operation) {
    let side;

    if (operation.type === RECTANGULAR_CUT_OUT) {
        if (dimensionName === LENGTH) {
            if (operation.position.x > 0) {
                side = RIGHT;
            } else {
                side = LEFT;
            }
        } else if (dimensionName === WIDTH) {
            if (operation.position.z > 0) {
                side = FRONT;
            } else {
                side = BACK;
            }
        }
    } else {
        side = RIGHT;
    }

    return side;
}

