import * as ActionTypes from "../constants/ActionTypes";

export function setDomain(domain) {
    domain = domain.replace('www.', '');

    return {
        type: ActionTypes.SET_DOMAIN,
        domain: domain,
    }
}

export function setCurrentUser(user) {
    return {
        type: ActionTypes.SET_CURRENT_USER,
        payload: user,
    }
}

export function addAlertMessage(variant, content) {
    return {
        type: ActionTypes.ADD_ALERT_MESSAGE,
        payload: {variant: variant, content: content},
    }
}

export function removeAlertMessage(index) {
    return {
        type: ActionTypes.REMOVE_ALERT_MESSAGE,
        payload: {index: index},
    }
}

export function setCanvasIsLoading(payload) {
    return {
        type: ActionTypes.SET_CANVAS_IS_LOADING,
        payload: payload,
    }
}

export function setFormsAreLoading(payload) {
    return {
        type: ActionTypes.SET_FORMS_ARE_LOADING,
        payload: payload,
    }
}

export function setListIsLoading(isLoading) {
    return {
        type: ActionTypes.SET_LIST_IS_LOADING,
        payload: isLoading,
    }
}

export function setScreenIsLoading(isLoading) {
    return {
        type: ActionTypes.SET_SCREEN_IS_LOADING,
        payload: isLoading,
    }
}

export function setWindowIsLoading(isLoading) {
    return {
        type: ActionTypes.SET_WINDOW_IS_LOADING,
        payload: isLoading,
    }
}

export function setPriceIsLoading(isLoading) {
    return {
        type: ActionTypes.SET_PRICE_IS_LOADING,
        payload: isLoading,
    }
}

export function setActiveOperationModal(type, additionalType = '', data = null) {
    if (!type) type = null;

    return {
        type: ActionTypes.SET_ACTIVE_OPERATION_MODAL,
        payload: {type: type, additionalType: additionalType, data: data},
    }
}

export function setActiveInfoModal(isActive, content) {
    return {
        type: ActionTypes.SET_ACTIVE_INFO_MODAL,
        payload: {isActive: isActive, content: content}
    }
}

export function setShouldUpdatePrice(shouldUpdatePrice) {
    return {
        type: ActionTypes.SET_SHOULD_UPDATE_PRICE,
        payload: shouldUpdatePrice,
    }
}

export function setShouldUpdateLogo(shouldUpdateLogo) {
    return {
        type: ActionTypes.SET_SHOULD_UPDATE_LOGO,
        payload: shouldUpdateLogo,
    }
}

export function setPriceType(priceType) {
    return {
        type: ActionTypes.SET_PRICE_TYPE,
        priceType: priceType,
    }
}

export function setCanEdit(canEdit) {
    return {
        type: ActionTypes.SET_CAN_EDIT,
        canEdit: canEdit,
    }
}

export function setConfiguratorUnsavedDimension(dimensionName) {
    return {
        type: ActionTypes.SET_CONFIGURATOR_UNSAVED_DIMENSION,
        dimensionName: dimensionName,
    }
}

export function setConfirmationModal(data) {
    return {
        type: ActionTypes.SET_CONFIRMATION_MODAL,
        data: data,
    }
}

export function setSearchKeyword(searchKeyword) {
    return {
        type: ActionTypes.SET_SEARCH_KEYWORD,
        searchKeyword: searchKeyword,
    }
}

export function setSettings(settings) {
    return {
        type: ActionTypes.SET_SETTINGS,
        settings: settings,
    }
}

export function setIsAuthenticated(isAuthenticated) {
    return {
        type: ActionTypes.SET_IS_AUTHENTICATED,
        isAuthenticated: isAuthenticated,
    }
}

export function setPriceVersion(priceVersion) {
    return {
        type: ActionTypes.SET_PRICE_VERSION,
        currentPriceVersion: priceVersion,
    }
}

export function setRefreshModalIsActive(refreshModalIsActive) {
    return {
        type: ActionTypes.SET_REFRESH_MODAL_IS_ACTIVE,
        refreshModalIsActive: refreshModalIsActive,
    }
}
