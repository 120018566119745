import {Service} from "./Service";
import {Parser} from "../helpers/Parser";

class AddressService extends Service {

    async getAll(companyBranchId) {
        return await this.authenticatedRequest('get', 'addresses', {companyBranchId})
            .then(response => {
                if (response.data.success) {
                    response.data.data = response.data.data.map(addressData => {
                        return Parser.parseAddress(addressData);
                    });
                }

                return response.data;
            })
            .catch();
    }

    async create(address, companyBranchId = null) {
        const data = {
            companyBranchId,
            street: address.street,
            number: address.number,
            postalCode: address.postalCode,
            city: address.city,
            country: address.country,
        };

        return await this.authenticatedRequest('post', 'addresses', data)
            .then(response => {
                if (response.data.success) response.data.data = Parser.parseAddress(response.data.data);

                return response.data;
            });
    }

    async update(address) {
        const data = {
            street: address.street,
            number: address.number,
            postalCode: address.postalCode,
            city: address.city,
            country: address.country,
        };

        return await this.authenticatedRequest('put', 'addresses/' + address.id, data)
            .then(response => {
                if (response.data.success) response.data.data = Parser.parseAddress(response.data.data);

                return response.data;
            });
    }

    async delete(addressId) {
        return await this.authenticatedRequest('delete', 'addresses/' + addressId)
            .then(response => {
                return response.data;
            });
    }


}

export {AddressService};
