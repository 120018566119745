import {parseNumber, parseToCommaSeparated} from "../helpers/StringHelper";
import {User} from "./User";
import {INVOICE} from "../../constants/PaymentMethods";

class Order {

    constructor(id, name, configurations = []) {
        this.id = id;
        this.offerId = null;
        this.name = name;
        this.companyBranchId = null;
        this.comment = '';
        this.contactPerson = new User();
        this.timestamp = {date: '', time: ''};
        this.deliveryDate = null;
        this.deliveryType = null;
        this.paymentMethod = INVOICE;
        this.creator = '';
        this.handler = new User();
        this.statuses = [];
        this.address = null;
        this.price = null;
        this.margin = 0;
        this.configurations = configurations;
        this.outdoorConfigurations = [];
        this.isModified = false;
        this.vat = 0;
        this.isCreatedByAdmin = false;
        this.administrativeStatus = '';
        this.createdAt = null;
    }

    getPrice() {
        let price = 0;

        this.configurations.forEach(configuration => {
            price += parseNumber(configuration.price);
        });

        this.outdoorConfigurations.forEach(configuration => {
            price += parseNumber(configuration.price);
        });

        return price;
    }

    getFormattedPrice(withVat = false) {
        let formattedPrice = this.price;

        if (withVat) {
            const price = parseNumber(this.price) * (this.vat / 100 + 1);

            formattedPrice = parseToCommaSeparated(price.toFixed(2));
        }

        return `€ ${formattedPrice}`;
    }

    hasStatus(status) {
        return this.statuses.find(s => s.name === status) !== undefined;
    }
}

export {Order};
