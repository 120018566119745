import * as React from 'react';
import PropTypes from 'prop-types';

export const ActionsDropdown = (props) => {
    const getStyling = () => {
        let defaultStyle;

        if (props.buttonContent) {
            defaultStyle = 'button dropdown-toggle button--outline';
        } else {
            defaultStyle = 'button button--small button--icon dropdown-toggle';
        }

        if (props.variant !== "DARK") {
            return defaultStyle + ' button--light';
        }

        return defaultStyle;
    }

    const getContent = () => {
        let content;

        if (props.buttonContent) {
            content = props.buttonContent;
        } else {
            content = <i className="material-icons">more_vert</i>;
        }

        return content;
    }

    return (
        <div className="dropdown">
            <button
                data-cy={props.dataCy}
                className={getStyling()}
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                {getContent()}
            </button>
            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                {props.children}
            </div>
        </div>
    );
};

ActionsDropdown.propTypes = {
    buttonContent: PropTypes.string,
    variant: PropTypes.string,
    dataCy: PropTypes.string
}
