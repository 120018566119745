import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {ValidatedInput} from "../forms/inputs/ValidatedInput";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {SectionTitle} from "../headers/SectionTitle";
import {NumberSelect} from "../forms/selects/NumberSelect";
import {
    dividePieceInParts,
    getMaxLengthForType,
    getMaxPartAmount,
    getMinLength,
    getMinPartAmount,
} from "../../classes/helpers/PieceDivisionHelper";
import {Piece} from "../../classes/models/Piece";
import {Message} from "../messages/Message";
import {DANGER, WARNING} from "../../constants/Variants";
import {parseNumber} from "../../classes/helpers/StringHelper";
import {Part} from "../../classes/models/Part";
import {PieceService} from "../../classes/services/PieceService";
import {useDispatch, useSelector} from "react-redux";
import {addAlertMessage, setConfirmationModal, setWindowIsLoading} from "../../actions/GeneralActions";
import ConfigurableReducerHelper from "../../classes/helpers/ConfigurableReducerHelper";
import {ModalHolder} from "./ModalHolder";
import DimensionValidator from "../../classes/helpers/DimensionValidator";
import {LENGTH} from "../../constants/Dimensions";
import {INVALID_VALUE} from "../../constants/ValidationErrors";
import {forEach} from "lodash";
import {MAX_DELIVERY_LENGTH, MIN_DELIVERY_THICKNESS} from "../../constants/DeliveryOptions";
export const DividePieceModal = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.dividePieceModal.';

    const {register, errors, handleSubmit, reset, setError} = useForm();

    const [selectedAmountOfParts, setSelectedAmountOfParts] = useState();
    const [partLengths, setPartLengths] = useState([]);
    const [showAlertMessage, setShowAlertMessage] = useState(false);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const [numberSelectMessage, setNumberSelectMessage] = useState('');
    const [sumOfPartLengths, setSumOfPartLengths] = useState(0);

    const {currentConfiguration} = useSelector(state => state.offerReducer);
    const canEdit = useSelector(state => state.generalReducer.canEdit);

    const dispatch = useDispatch();

    const pieceService = new PieceService();
    const configurableReducerHelper = new ConfigurableReducerHelper();
    const dimensionValidator = new DimensionValidator();

    useEffect(() => {
        if (props.piece) {
            const lengths = props.piece.parts.map(part => part.length);

            setPartLengths(lengths);
            setSelectedAmountOfParts(props.piece.parts.length);
            setShowAlertMessage(shouldShowAlertMessage(props.piece.dimensions.length, lengths));
        } else {
            setPartLengths([]);
            setSelectedAmountOfParts(0);
        }
    }, [props.piece]);

    useEffect(() => {
        const amount = getMinPartAmount(
            props.piece?.dimensions.length,
            props.piece?.dimensions.width,
            props.piece?.dimensions.height,
        );

        if (amount > 1) {
            setNumberSelectMessage(t(`${prefix}contactStoneApp`));
        }
    }, [props.piece?.dimensions]);

    useEffect(() => {
        setSumOfPartLengths(getSumOfLengths(partLengths));
        setShowAlertMessage(shouldShowAlertMessage(props.piece?.dimensions.length, partLengths));
    }, [partLengths]);

    const updateParts = async (parts) => {
        dispatch(setWindowIsLoading(true));

        let updatedPiece = Object.assign(new Piece(), props.piece);
        updatedPiece.parts = parts;

        return pieceService.update(currentConfiguration, updatedPiece)
            .then(response => {
                if (response.success) {

                    configurableReducerHelper.updatePiece(response.data);

                    return true;
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'updatePieceFailed')));
                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const getLabel = (partNumber) => {
        return `${t(prefix + 'part')} ${partNumber}`;
    }

    const getSumOfLengths = (lengths) => {
        if (!lengths || !lengths.length) return 0;

        return Math.round((parseFloat(lengths.reduce((sum, partLength) => parseFloat(sum) + parseNumber(partLength))) + Number.EPSILON) * 100) / 100
    }

    const dividePiece = (amountOfParts = null) => {
        if (!amountOfParts) {
            if (selectedAmountOfParts === 1 && props.piece.dimensions.length > MAX_DELIVERY_LENGTH) {
                amountOfParts = 2;
                setSelectedAmountOfParts(amountOfParts);
            } else {
                amountOfParts = selectedAmountOfParts;
            }
        }

        const parts = dividePieceInParts(
            props.piece.dimensions.length,
            props.piece.dimensions.width,
            props.piece.dimensions.height,
            currentConfiguration.options.type,
            amountOfParts
        );
        setPartLengths(parts.map(part => parseFloat(part.length).toFixed(2)));

        // Reset the form so the new lengths will all be filled, and the user specified lengths will be overridden
        reset();
    }

    const validateLengthsForStock = (fields) => {
        let validationError;
        let errorHasOccurred = false;

        if (currentConfiguration?.options.isConfiguredForStock()) {
            Object.keys(fields).forEach(inputFieldName => {
                validationError = dimensionValidator.validateStockDimension(LENGTH, fields[inputFieldName]);

                if (validationError === INVALID_VALUE) {
                    setError(inputFieldName, {
                        type: INVALID_VALUE,
                        message: t(prefix + 'invalidValueStockLength'),
                        shouldFocus: true,
                    });

                    errorHasOccurred = true;
                }
            })

        }

        return errorHasOccurred;
    }

    const onLengthUpdate = (length, index) => {
        if (partLengths.length >= index + 1) {
            length = parseNumber(length);
            const newLengths = partLengths.map((partLength, i) => {
                if (i === index) return length;
                return partLength;
            });

            setPartLengths(newLengths);
            setShowWarningMessage(shouldShowWarningMessage(newLengths, props.piece.dimensions.height));
        }
    }

    const shouldShowAlertMessage = (totalLength, lengths) => {
        let showMessage = false;

        totalLength = parseFloat(totalLength);

        const sumOfLengths = getSumOfLengths(lengths);

        if (sumOfLengths !== totalLength) {
            showMessage = true;
        }

        return showMessage;
    }

    const shouldShowWarningMessage = (lengths, pieceHeight) => {
        let showMessage = false;

        forEach(lengths, length => {
            if (length > MAX_DELIVERY_LENGTH && pieceHeight <= MIN_DELIVERY_THICKNESS) {
                showMessage = true;
            }
        });
        return showMessage;
    }

    const onAmountUpdate = (newAmount) => {
        let parts = partLengths.concat([]);
        newAmount = parseInt(newAmount);


        if (newAmount > selectedAmountOfParts) {
            const difference = newAmount - selectedAmountOfParts;

            for (let i = 1; i <= difference; i++) {
                parts.push(null);
            }

            setPartLengths(parts);
        } else {
            const difference = selectedAmountOfParts - newAmount;

            for (let i = 1; i <= difference; i++) {
                parts.splice(parts.length - 1);
            }

            dividePiece(newAmount);
        }

        setSelectedAmountOfParts(newAmount);
    }

    function checkDeliverablityLengths(fields) {
        let onePartIsTooLong = false;
        forEach(fields, (field) => {
            if (field > MAX_DELIVERY_LENGTH) {
                onePartIsTooLong = true;
            }
        });
        return onePartIsTooLong;
    }

    const updateFields = (fields) => {
        const stockValidationFailed = validateLengthsForStock(fields);
        if (stockValidationFailed) return;

        if (shouldShowAlertMessage(props.piece.dimensions.length, Object.values(fields))) {
            setShowAlertMessage(true);
            return;
        }

        updateParts(Object.values(fields).map(field => new Part(0, parseNumber(field))))
            .then(success => {
                if (success) props.onClose(true);
            });
    }

    const onClose = (fields) => {
        if (fields) {
            if (props.piece.dimensions.height <= MIN_DELIVERY_THICKNESS && checkDeliverablityLengths(fields)) {
                dispatch(setConfirmationModal({
                    isActive: true,
                    content: t(prefix + 'pieceNotDeliverable'),
                    onAccept: () => {
                        updateFields(fields);
                    },
                    onClose: () => {
                        dividePiece();
                    },
                }));
            } else {
                updateFields(fields);
            }
        } else {
            props.onClose();
        }
    }

    const getMinAmount = () => {
        return getMinPartAmount(
            props.piece?.dimensions.length,
            props.piece?.dimensions.width,
            props.piece?.dimensions.height,
            currentConfiguration?.options.type
        );
    }

    const renderInputFields = () => {
        const minLength = getMinLength();
        const maxLength = getMaxLengthForType(
            props.piece?.dimensions.length,
            props.piece?.dimensions.width,
            props.piece?.dimensions.height,
            currentConfiguration?.options.type
        );

        return partLengths.map((length, index) => {
            return <ValidatedInput
                dataCy={`dividePieceModal-pieceLength-${index + 1}`}
                key={`partLengthInput${index}`}
                register={register}
                name={`partLengthInput${index}`}
                label={getLabel(index + 1)}
                value={length}
                required={true}
                onChange={(value) => onLengthUpdate(value, index)}
                min={minLength}
                max={maxLength}
                error={errors[`partLengthInput${index}`]}
                disabled={!canEdit}
            />
        })
    }

    const renderFooterButtons = () => {
        const submitButton = <button
            data-cy="dividePieceModal-submitButton"
            key={'dividePieceFooterButton1'}
            className="button button--primary"
            type="submit"
            form="dividePieceForm"
        >
            {t(prefix + 'submitButton')}
        </button>;

        let buttons = [
            <button
                data-cy="dividePieceModal-cancelButton"
                key={'dividePieceFooterButton2'}
                className="button button--outline"
                type="button"
                onClick={() => onClose()}>
                {t(prefix + 'cancelButton')}
            </button>
        ];

        if (canEdit) {
            buttons.unshift(submitButton);
        }

        return buttons;
    }

    return (
            <ModalHolder isActive={props.isActive}>
                <Modal show={props.isActive} onHide={() => onClose()} animation={true} className="modal">
                    <Modal.Header>
                        <Modal.Title>{t(prefix + 'header')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form id="dividePieceForm"
                              onSubmit={handleSubmit(onClose)}>

                            <p>{t(prefix + 'description')}</p>

                            <SectionTitle content={t(prefix + 'amountTitle')}/>

                            <div className="row m-0">
                                <div className="col-8 p-0 p-r-1">
                                    <NumberSelect
                                        dataCy="dividePieceModal-numberSelect"
                                        size="lg"
                                        value={selectedAmountOfParts}
                                        rangeStart={getMinAmount()}
                                        rangeEnd={getMaxPartAmount(props.piece?.dimensions.length)}
                                        message={numberSelectMessage}
                                        onUpdate={(newAmount) => onAmountUpdate(newAmount)}
                                        disabled={!canEdit}
                                    />

                                </div>
                                <div className="col-4 p-0">
                                    <button
                                        data-cy="dividePieceModal-divideButton"
                                        type="button"
                                        className="button button--active w-100"
                                        onClick={() => dividePiece(selectedAmountOfParts)}
                                        disabled={!canEdit}
                                    >
                                        {t(prefix + 'divideButton')}
                                    </button>
                                </div>
                            </div>

                            <SectionTitle content={t(prefix + 'dimensionsTitle')}/>

                            <p>{t(prefix + 'lengthOfPieceLabel')}: <strong>{props.piece?.dimensions.length} cm</strong></p>
                            <p>{t(prefix + 'sumOfPartLengthsLabel')}:&nbsp;
                                <strong
                                    data-cy="dividePieceModal-sumOfPieces"
                                    className={showAlertMessage ? 'color--red' : ''}>
                                    {sumOfPartLengths} cm
                                </strong>
                            </p>

                            <p className="m-t-2 m-b-2"><strong>{t(prefix + 'dimensionsDescription')}</strong></p>

                            {renderInputFields()}

                            {
                                showAlertMessage && <div className="m-b-3">
                                    <Message
                                        content={t(
                                            prefix + 'sumOfPartsIsNotEqualToLengthMessage',
                                            {totalLength: props.piece?.dimensions.length}
                                        )}
                                        variant={DANGER}
                                    />
                                </div>
                            }

                        {
                            showWarningMessage && <div className="m-b-3">
                                <Message
                                    content={t(
                                        prefix + 'LengthAndThicknessDeliverabilityWarningMessage',
                                        {length: MAX_DELIVERY_LENGTH, height: MIN_DELIVERY_THICKNESS}
                                    )}
                                    variant={WARNING}
                                />
                            </div>
                        }

                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        {renderFooterButtons()}
                    </Modal.Footer>
                </Modal>
            </ModalHolder>
    );
};

DividePieceModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    piece: PropTypes.instanceOf(Piece),
}

DividePieceModal.defaultProps = {
    parts: [],
}
