export const COLLECT = 'COLLECT';
export const DELIVERY = 'DELIVERY';

export const MAX_DELIVERY_LENGTH = 200;
export const MIN_DELIVERY_THICKNESS = 6;

export const ALL_DELIVERY_OPTIONS = [
    COLLECT,
    DELIVERY,
]
