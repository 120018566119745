import React, {useEffect, useState} from 'react';
import {DeliveryInfo} from "./DeliveryInfo";
import {useSelector} from "react-redux";
import {CLOSED} from "../../../constants/Statuses";
import moment from "moment";
import PropTypes from "prop-types";
import {captureException} from "@sentry/react";
import {OrderService} from "../../../classes/services/OrderService";

export const DeliveryInfoHolder = (props) => {
    const [datesToShow, setDatesToShow] = useState([]);
    const [franco, setFranco] = useState([]);
    const {currentCompanyBranch} = useSelector(state => state.companyReducer);

    const orderService = new OrderService();

    useEffect(() => {
        getDeliveryDatesToShow();
    }, [currentCompanyBranch]);

    useEffect(() => {
        if (props.shouldUpdateFranco === true && props.onFrancoUpdated) {
            getDeliveryDatesToShow();
            props.onFrancoUpdated();
        }
    }, [props.shouldUpdateFranco]);

    const getDeliveryDatesToShow = () => {
        let dates = [];

        if (!currentCompanyBranch) return dates;

        const closedDates = currentCompanyBranch.deliveryType.dates.filter(deliveryDate => {
            const today = moment();
            const date = moment(deliveryDate.date);

            return deliveryDate.status === CLOSED && !today.isAfter(date)
        });

        if (closedDates.length > 1) {
            dates.push(closedDates[closedDates.length - 1]);
        } else {
            dates.push(...closedDates);
        }

        const nextDate = currentCompanyBranch.deliveryType.getNextDeliveryDate();

        if (nextDate) dates.push(nextDate);

        getFrancoForDates(dates);
        setDatesToShow(dates);
    }

    const getFrancoForDates = (dates) => {
        if (!currentCompanyBranch) return 0;

        let mounted = true;

        if (currentCompanyBranch) {
            const promises = [];

            dates.forEach(date => {
                promises.push(orderService.getFranco(currentCompanyBranch.id, date.id));
            });

            Promise.all(promises).then(responses => {
                const francoData = [];

                responses.forEach(response => {
                    francoData.push({
                        deliveryDateId: response.data.delivery_date_id,
                        franco: response.data.franco
                    });
                });

                if (mounted) {
                    setFranco(francoData);
                }
            }).catch(error => {
                captureException(error);
            });
        }

        return () => {
            mounted = false;
        }
    }

    const findFrancoByDeliveryDate = (date) => {
        const francoData = franco.find(f => f.deliveryDateId === date.id);

        if (francoData) {
            return francoData.franco;
        }

        return 0;
    }

    if (!currentCompanyBranch) return null;

    return (
        <div data-cy="deliveryInfoHolder" className={props.styling}>
            {
                datesToShow.map(date => {
                    return <div key={`deliveryInfo${date.id}`} className="m-l-1">
                        <DeliveryInfo
                            deliveryType={currentCompanyBranch.deliveryType}
                            deliveryDate={date}
                            franco={findFrancoByDeliveryDate(date)}
                        />
                    </div>
                })
            }
        </div>
    );
}

DeliveryInfoHolder.propTypes = {
    styling: PropTypes.string,
    shouldUpdateFranco: PropTypes.bool,
    onFrancoUpdated: PropTypes.func,
}
