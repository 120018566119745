//region Marble
export const AZUL_BATEIG_HONED = 'AZUL_BATEIG_HONED';
export const AZUL_VALVERDE_HONED = 'AZUL_VALVERDE_HONED';
export const CARRARA_CD_SHINY = 'CARRARA_CD_SHINY';
export const CARRARA_C_SHINY = 'CARRARA_C_SHINY';
export const DOLOMITE_HONED = 'DOLOMITE_HONED';
export const JURA_YELLOW_SHINY = 'JURA_YELLOW_SHINY';
export const JURA_BANNED_SHINY = 'JURA_BANNED_SHINY';
export const JURA_POLAR_SHINY = 'JURA_POLAR_SHINY';
export const JURA_POLAR_HONED = 'JURA_POLAR_HONED';
export const FATIMA_LIGHT_SHINY = 'FATIMA_LIGHT_SHINY';
export const PETIT_GRANIT_POLI_SHINY = 'PETIT_GRANIT_POLI_SHINY';
export const PETIT_GRANIT_HONED_G300 = 'PETIT_GRANIT_HONED_G300';
export const TRAVERTIN_ROMAIN_SHINY = 'TRAVERTIN_ROMAIN_SHINY';
export const MOUNTAUK_BLACK_HONED = 'MOUNTAUK_BLACK_HONED';
export const ATLANTIC_BLUE_HONED = 'ATLANTIC_BLUE_HONED';
export const JURA_YELLOW_SABLINO = 'JURA_YELLOW_SABLINO';
export const JURA_POLAR_SABLINO = 'JURA_POLAR_SABLINO';
export const MEDITERRANEAN_GREEN_ANCIENTO = 'MEDITERRANEAN_GREEN_ANCIENTO';
export const MOLEANOS_HONED = 'MOLEANOS_HONED';
export const TORRES_BLUE_HONED = 'TORRES_BLUE_HONED';
//endregion

//region Marble composite
export const PURE_WHITE = 'PURE_WHITE';
export const PURE_GREY = 'PURE_GREY';
export const PURE_BEIGE = 'PURE_BEIGE';
export const PURE_BLUE_HONED = 'PURE_BLUE_HONED';
//endregion

//region Granite
export const GHIBLI_SHINY = 'GHIBLI_SHINY';
export const JUPERANA_COLOMBO_SHINY = 'JUPERANA_COLOMBO_SHINY';
export const LABRADOR_BLUE_SHINY = 'LABRADOR_BLUE_SHINY';
export const MULTICOLOR_RED_SHINY = 'MULTICOLOR_RED_SHINY';
export const PARADISO_BASH_SHINY = 'PARADISO_BASH_SHINY';
export const RUSTENBURG_MEDIUM_HONED = 'RUSTENBURG_MEDIUM_HONED';
export const RUSTENBURG_MEDIUM_SHINY = 'RUSTENBURG_MEDIUM_SHINY';
export const RUSTENBURG_ANCIENTO = 'RUSTENBURG_ANCIENTO';
export const STARGATE_SILVER_SHINY = 'STARGATE_SILVER_SHINY';
export const SPI_HONED = 'SPI_HONED';
export const SPI_FLAMED = 'SPI_FLAMED';
export const TALILA_GREY_SHINY = 'TALILA_GREY_SHINY';
export const ZIMBABWE_BLACK_HONED = 'ZIMBABWE_BLACK_HONED';
export const ZIMBABWE_BLACK_SHINY = 'ZIMBABWE_BLACK_SHINY';
export const ZIMBABWE_BLACK_ANCIENTO = 'ZIMBABWE_BLACK_ANCIENTO';
//endregion

// region Bluestone
export const STANDARD = 'STANDARD';
export const BLUE = 'BLUE';
export const GRAY = 'GRAY';
// endregion

export const INTERIOR_GRANITE_COLORS = [
    LABRADOR_BLUE_SHINY,
    RUSTENBURG_MEDIUM_HONED,
    RUSTENBURG_MEDIUM_SHINY,
    RUSTENBURG_ANCIENTO,
    STARGATE_SILVER_SHINY,
    SPI_HONED,
    SPI_FLAMED,
    TALILA_GREY_SHINY,
    ZIMBABWE_BLACK_HONED,
    ZIMBABWE_BLACK_SHINY,
    ZIMBABWE_BLACK_ANCIENTO,
];

export const EXTERIOR_GRANITE_COLORS = [
    RUSTENBURG_MEDIUM_HONED,
    RUSTENBURG_MEDIUM_SHINY,
    SPI_FLAMED,
    ZIMBABWE_BLACK_HONED
];

export const EXTERIOR_BLUESTONE_COLORS = [
    STANDARD,
    BLUE,
    GRAY,
];

export const EXTERIOR_BLUESTONE_STOCK_COLORS = [
    STANDARD,
];

export const INTERIOR_BLUESTONE_COLORS = {
    CM1: [],
    CM2: [STANDARD, GRAY, BLUE, PETIT_GRANIT_HONED_G300, PETIT_GRANIT_POLI_SHINY],
    CM3: [STANDARD, GRAY, BLUE, PETIT_GRANIT_HONED_G300, PETIT_GRANIT_POLI_SHINY],
}

export const GRANITE_COLORS_HEIGHT = {
    CM1: [],
    CM2: [
        LABRADOR_BLUE_SHINY,
        RUSTENBURG_MEDIUM_HONED,
        RUSTENBURG_MEDIUM_SHINY,
        RUSTENBURG_ANCIENTO,
        SPI_HONED,
        SPI_FLAMED,
        TALILA_GREY_SHINY,
        ZIMBABWE_BLACK_HONED,
        ZIMBABWE_BLACK_SHINY,
        ZIMBABWE_BLACK_ANCIENTO,
        STARGATE_SILVER_SHINY,
    ],
    CM3: [
        LABRADOR_BLUE_SHINY,
        RUSTENBURG_MEDIUM_HONED,
        RUSTENBURG_MEDIUM_SHINY,
        RUSTENBURG_ANCIENTO,
        SPI_HONED,
        SPI_FLAMED,
        TALILA_GREY_SHINY,
        ZIMBABWE_BLACK_HONED,
        ZIMBABWE_BLACK_SHINY,
        ZIMBABWE_BLACK_ANCIENTO,
        STARGATE_SILVER_SHINY
    ]
}

export const MARBLE_COLORS_HEIGHT = {
    CM1: [],
    CM2: [
        CARRARA_C_SHINY,
        DOLOMITE_HONED,
        JURA_YELLOW_SHINY,
        JURA_POLAR_SHINY,
        JURA_POLAR_HONED,
        PETIT_GRANIT_HONED_G300,
        PETIT_GRANIT_POLI_SHINY,
        TRAVERTIN_ROMAIN_SHINY,
        MOUNTAUK_BLACK_HONED,
        ATLANTIC_BLUE_HONED,
        JURA_YELLOW_SABLINO,
        JURA_POLAR_SABLINO,
        MEDITERRANEAN_GREEN_ANCIENTO,
        MOLEANOS_HONED,
        TORRES_BLUE_HONED,

        PURE_WHITE,
        PURE_GREY,
        PURE_BEIGE,
        PURE_BLUE_HONED
    ],
    CM3: [
        CARRARA_C_SHINY,
        DOLOMITE_HONED,
        JURA_YELLOW_SHINY,
        JURA_POLAR_SHINY,
        JURA_POLAR_HONED,
        PETIT_GRANIT_HONED_G300,
        PETIT_GRANIT_POLI_SHINY,
        TRAVERTIN_ROMAIN_SHINY,
        MOUNTAUK_BLACK_HONED,
        ATLANTIC_BLUE_HONED,
        JURA_YELLOW_SABLINO,
        JURA_POLAR_SABLINO,
        MEDITERRANEAN_GREEN_ANCIENTO,
        MOLEANOS_HONED,
        TORRES_BLUE_HONED,

        PURE_WHITE,
    ]
}

export const COLORS_REMOVED_FROM_PRODUCT_RANGE = [
    JURA_BANNED_SHINY,
    GHIBLI_SHINY,
    JUPERANA_COLOMBO_SHINY,
    MULTICOLOR_RED_SHINY,
    AZUL_BATEIG_HONED,
    AZUL_VALVERDE_HONED,
    CARRARA_CD_SHINY,
    FATIMA_LIGHT_SHINY,
    PARADISO_BASH_SHINY,
];
