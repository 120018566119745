import * as React from 'react';
import PropTypes from 'prop-types';
import {Order} from "../../../classes/models/Order";
import {CANCELED, DELIVERED, MODIFIED, PARTIALLY_DELIVERED, RECEIVED} from "../../../constants/OrderStatusNames";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import moment from "moment";
import {CLOSED, OPEN} from "../../../constants/Statuses";

export const OrderStatus = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const constantsPrefix = 'constants.orderStatusNames.';

    const getStatusesToDisplay = () => {
        const statusesToDisplay = [];

        if (!props.order) return statusesToDisplay;

        props.order.statuses.forEach(status => {
            if ([CANCELED, DELIVERED, PARTIALLY_DELIVERED].includes(status.name)) {
                statusesToDisplay.push(status.name);
            }
        })

        if (props.order.isModified && !statusesToDisplay.includes(CANCELED)) {
            statusesToDisplay.push(MODIFIED);
        }

        // Filter duplicates
        return [...new Set(statusesToDisplay)];
    }

    const getDeliveryDateStatus = () => {
        let deliveryDateStatus = props.order.deliveryDate.status;

        const today = moment();
        const date = moment(props.order.deliveryDate.date);

        if (deliveryDateStatus === CLOSED && !today.isAfter(date)) {
            deliveryDateStatus = OPEN;
        }

        return deliveryDateStatus;
    }

    const getBadgeColor = (status) => {
        let badgeColor = '';

        switch (status) {
            case RECEIVED:
                badgeColor = 'badge--outline';
                break;
            case DELIVERED:
            case PARTIALLY_DELIVERED:
                badgeColor = 'badge--green';
                break;
            case MODIFIED:
                badgeColor = 'badge--orange';
                break;
            case CANCELED:
                badgeColor = 'badge--red';
                break;
            default:
                badgeColor = 'bade--outline';
        }

        return badgeColor;
    }

    return (
        <div className="order-status">
            {
                getStatusesToDisplay().map(status => {
                    return <div key={status} className={`badge ${getBadgeColor(status)} m-r-1`}>
                        {t(constantsPrefix + status).toUpperCase()}
                    </div>
                })
            }
        </div>
    );
};

OrderStatus.propTypes = {
    order: PropTypes.instanceOf(Order),
}

OrderStatus.defaultProps = {
    order: null,
}
