import {Operation} from "./Operation";
import {Vector3} from "three";
import {COUPE} from "../../constants/OperationTypes";

class CoupeOperation extends Operation {
    constructor(id, mainObjectId, side, angle = null, dimensions = {
        length: 0,
        width: 0,
        distance: 0
    }, position = new Vector3(), isFinished = false) {
        super(id, COUPE, dimensions, position, isFinished);
        this.mainObject = mainObjectId;
        this.side = side;
        this.angle = angle;
        this.additionalDimension = {type: null, value: 0};
        // Lengte or Breedte so L or B
        this.coupeType = 'B';
        this.connectedCoupe = null;
    }

    addConnectedCoupe(coupeOperation) {
        this.connectedCoupe = coupeOperation.id;
    }
}

export {CoupeOperation};
