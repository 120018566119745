import produce from "immer";
import * as ActionTypes from "../constants/ActionTypes";

const initialState = {
    articles: [],
    activeArticle: null,
    modalActive: false,
    articleConfig: null
}

function outdoorShopReducer(state = initialState, action) {
    return produce(state, draftState => {
        switch (action.type) {
            case ActionTypes.SET_ARTICLES:
                draftState.articles = action.payload;
                break;
            case ActionTypes.SET_ACTIVE_ARTICLE:
                draftState.activeArticle = action.payload;
                break;
            case ActionTypes.SET_ARTICLE_CONFIG:
                draftState.articleConfig = action.payload;
                break;
            case ActionTypes.SET_ARTICLE_MODAL_ACTIVE:
                draftState.modalActive = action.payload;
                break;
            default:
                break;
        }
    });
}

export default outdoorShopReducer;
