import {Vector3} from "three";
import {Piece} from "../../internal";

class Type1Piece extends Piece {

    constructor(id, dimensions = {length: 0, height: 0, width: 0}, operations = [], position = new Vector3()) {
        super(id, dimensions, operations, position);
    }

}

export {Type1Piece};
