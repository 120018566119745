import * as React from 'react';
import PropTypes from 'prop-types';
import {SectionTitle} from "../../headers/SectionTitle";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";

export const HeightCoupeInput = (props) => {
    const prefix = 'modals.connectPieceModal.';
    const {t} = useTranslation(TRANSLATION_NAMESPACE);

    return (
        <>
            <SectionTitle
                content={t(prefix + 'heightCoupeTitle')}
            />
            <div className="list__item">
                <div className="form-group">
                    <label>
                        <input
                            name="heightCoupeInput" type="number"
                            placeholder={t(prefix + 'degreesPlaceholder')}
                            onChange={(event) => props.onInputChange(event.target.value)}
                            disabled={props.disabled}
                        />
                    </label>
                </div>
            </div>
        </>
    );
};

HeightCoupeInput.propTypes = {
    onInputChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}
