export const PIECE = 'PIECE';
export const DIVIDE_PIECE = 'DIVIDE_PIECE';
export const DIVIDE_PIECE_MODAL_NAME = 'DIVIDE_PIECE_MODAL_NAME';
export const EDIT_COLOR = 'EDIT_COLOR';

export const ALL = [
    PIECE,
    DIVIDE_PIECE,
    EDIT_COLOR
];
