import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {NavigationBar} from "../../navigation/NavigationBar";
import {AppContent} from "../../layout/AppContent";
import {AppHolder} from "../../layout/AppHolder";
import {SearchBar} from "../../navigation/SearchBar";
import {useArticles} from "../../../classes/hooks/useArticles";
import {LoadingMessage} from "../../messages/LoadingMessage";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import ArticleModal from "../../modals/ArticleModal";
import {removeAlertMessage, setSearchKeyword, setWindowIsLoading} from "../../../actions/GeneralActions";
import EmptyArticleList from "../../empty/EmptyArticleList";
import ArticleCategory from "./categories/ArticleCategory";
import {MessageHolder} from "../../messages/MessageHolder";
import {AppHeader} from "../../headers/AppHeader";
import {filterArticlesByKeyword} from "../../../classes/helpers/OutdoorArticleHelper";

export const Articles = () => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = "constants.outdoorArticleCategories.";

    const [isEmpty, setIsEmpty] = useState(false);
    const [filteredArticles, setFilteredArticles] = useState([]);

    const {articles, modalActive} = useSelector((state) => state.outdoorShopReducer);
    const {windowIsLoading, searchKeyword, alertMessages} = useSelector((state) => state.generalReducer);

    const dispatch = useDispatch();

    const {closeArticleModal} = useArticles();

    useEffect(() => {
        dispatch(setWindowIsLoading(true));

        filterArticlesByKeyword(articles, searchKeyword)
            .then(filtered => {
                setFilteredArticles(filtered);

                if (Object.keys(filtered).length < 1) setIsEmpty(true);
                else {
                    if (isEmpty) setIsEmpty(false);
                }
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }, [searchKeyword, articles]);

    const inputHandler = event => dispatch(setSearchKeyword(event));

    return (
        <AppHolder>
            {windowIsLoading && (<LoadingMessage variant="overlay"/>)}

            {modalActive && (
                <ArticleModal
                    isActive={modalActive}
                    onClose={closeArticleModal}
                    isEditing={false}
                />
            )}

            <NavigationBar/>

            <AppContent>

                <AppHeader title={t("pages.articles.title")}/>

                <SearchBar
                    onInput={_.debounce(inputHandler, 500)}
                />

                <div className="p-l-4 p-r-4 p-t-2">
                    {
                        Object.keys(filteredArticles)
                            .map((key, index) => {
                                return <React.Fragment key={`${key}${index}`}>
                                    <div key={key} className="article__category">
                                        {t(`${prefix}${key.toUpperCase()}`)}
                                    </div>

                                    {
                                        Object.keys(filteredArticles[key])
                                            .map((category2Key, index) => {
                                                return <React.Fragment key={`${category2Key}${index}`}>
                                                    {
                                                        Object.keys(filteredArticles[key][category2Key])
                                                            .map((category3Key, index) => {
                                                                return <ArticleCategory
                                                                    key={`${category3Key}${index}`}
                                                                    category={category2Key}
                                                                    subCategory={category3Key}
                                                                    articles={filteredArticles[key][category2Key][category3Key]}
                                                                />
                                                            })
                                                    }
                                                </React.Fragment>
                                            })
                                    }
                                </React.Fragment>
                            })
                    }
                </div>

                <MessageHolder
                    messages={alertMessages}
                    onClose={(index) => dispatch(removeAlertMessage(index))}
                />

                {(isEmpty && !windowIsLoading) && <EmptyArticleList/>}
            </AppContent>
        </AppHolder>
    );
};
