import * as React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {AppHeader} from "../../headers/AppHeader";
import {NEW_USERS} from "../../../constants/AdminPages";

export const NewUserList = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.admin.newUserList.';
    const constantsPrefix = 'constants.adminPages.';

    return (
        <>
            <AppHeader title={t(constantsPrefix + NEW_USERS)}/>

            <div className="p-l-4 p-r-4">

                <p>{t(prefix + 'description')}</p>
                <br/><br/>
                <table className="table--bordered">
                    <tbody>
                    <tr>
                        <th>{t(prefix + 'nameLabel')}:</th>
                        <th>{t(prefix + 'emailLabel')}</th>
                        <th>{t(prefix + 'companyNameLabel')}</th>
                        <th width="1">Actions</th>
                    </tr>

                    {
                        props.users.map(user => {
                            return <UserItem
                                key={user.id}
                                name={user.getFullName()}
                                email={user.email}
                                companyName={user.company.name}
                                handleRegistration={(accepted) => props.handleRegistration(user.id, accepted)}
                            />
                        })
                    }
                    </tbody>
                </table>
            </div>
        </>
    );
};

NewUserList.propTypes = {
    users: PropTypes.array.isRequired,
    handleRegistration: PropTypes.func.isRequired,
}

const UserItem = (props) => {
    return (
        <tr>
            <td>{props.name}</td>
            <td>{props.email}</td>
            <td>{props.companyName}</td>
            <td>
                <span className="button button--icon button--small button--success"
                      onClick={() => props.handleRegistration(true)}>
                    <i className="material-icons">check</i> Accepteren
                </span>
                <span className="button button--icon button--small button--outline-danger"
                      onClick={() => props.handleRegistration(false)}>
                    <i className="material-icons">close</i> Weigeren
                </span>
            </td>
        </tr>
    )
}

UserItem.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    handleRegistration: PropTypes.func.isRequired,
}
