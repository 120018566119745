import {Parser, Service} from "../../internal";

class PieceService extends Service {

    async create(configuration, pieceToCreate, operationsToCreate) {
        const data = {
            piece: pieceToCreate,
            operations: operationsToCreate,
        }

        return await this.authenticatedRequest('post', `configurations/${configuration.id}/pieces`, data)
            .then(response => {
                if (response.data.success) response.data.data = Parser.parsePiece(response.data.data, configuration.options);

                return response.data;
            })
            .catch(error => {
                throw error.response;
            });
    }

    async connectNewPiece(configuration, pieceId, newPiece, alignment = null, alignmentDistance = null) {
        const data = {piece: newPiece};

        if (alignment) {
            data.alignment = alignment;
            data.alignmentDistance = alignmentDistance;
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configuration.id}/pieces/${pieceId}`,
            data
        )
            .then(response => {
                if (response.data.success) {
                    response.data.data = {
                        newPiece: Parser.parsePiece(response.data.data.newPiece, configuration.options),
                        updatedPiece: Parser.parsePiece(response.data.data.updatedPiece, configuration.options)
                    }
                }

                return response.data;
            })
            .catch(error => {
                throw error.response;
            });
    }

    async update(configuration, piece) {
        Object.keys(piece.dimensions).forEach((key) => {
            let value = parseFloat(piece.dimensions[key]);

            if (isNaN(value)) piece.dimensions[key] = 0;
        });

        return await this.authenticatedRequest('put', `configurations/${configuration.id}/pieces/${piece.id}`, piece)
            .then(response => {
                if (response.data.success) response.data.data = Parser.parsePiece(response.data.data, configuration.options);

                return response.data;
            }).catch(error => {
                throw error.response;
            });
    }

    async updateName(configurationId, pieceId, name) {
        const URL = `configurations/${configurationId}/pieces/${pieceId}/name`

        return await this.authenticatedRequest('put', URL, {name})
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.response != null) {
                    throw error.response;
                }
            });
    }

    async updateAmount(configurationId, pieceId, amount) {
        return await this.authenticatedRequest(
            'put',
            `configurations/${configurationId}/pieces/${pieceId}/amount`,
            {amount}
        )
            .then(response => {
                return response.data;
            })
            .catch(error => {
                if (error.response != null) {
                    throw error.response;
                }
            });
    }

    async setFinishedSides(configurationId, piece, finishedSides) {
        return await this.authenticatedRequest('put', `configurations/${configurationId}/pieces/${piece.id}/finishedsides`, {finishedSides})
            .then(response => {
                if (response.data.success) response.data.data = Parser.parseOperations(piece, response.data.data);

                return response.data;
            }).catch(error => {
                return error.response;
            });
    }

    async setWaterlists(configurationId, piece, waterlists) {
        return await this.authenticatedRequest(
            'put',
            `configurations/${configurationId}/pieces/${piece.id}/waterlists`,
            {waterlists}
        )
            .then(response => {
                if (response.data.success) response.data.data = Parser.parseOperations(piece, response.data.data);

                return response.data;
            });
    }

    async delete(configurationId, pieceId) {
        return await this.authenticatedRequest('delete', `configurations/${configurationId}/pieces/${pieceId}`)
            .then(response => {
                return response.data;
            })
            .catch(error => {
                throw error.response.data;
            })
    }

}

export {PieceService};
