import * as React from 'react';
import PropTypes from 'prop-types';
import {FinishModal} from "./FinishModal";
import {NotchModal} from "./NotchModal";
import {CoupeModal} from "./CoupeModal";
import {DrillHoleModal} from "./DrillHoleModal";
import {CornerCutoutModal} from "./CornerCutoutModal";
import {GroovesModal} from "./GroovesModal";
import {RoundedCornerModal} from "./RoundedCornerModal";
import {HeightCoupeModal} from "./HeightCoupeModal";
import {CoupeOverLengthModal} from "./CoupeOverLengthModal";
import {DebaseRoughModal} from "./DebaseRoughModal";
import {RectangularCutOutModal} from "./RectangularCutOutModal";
import {AnchorHoleModal} from "./AnchorHoleModal";
import {ProfileOperationModal} from "./ProfileOperationModal";
import {GluedCushionModal} from "./GluedCushionModal";
import {ConnectPieceModal} from "./connectPieceModal/ConnectPieceModal";
import {DIVIDE_PIECE, PIECE} from "../../constants/PieceActionTypes";
import {DividePieceModal} from "./DividePieceModal";
import {
    ANCHOR_HOLE,
    RECTANGULAR_CUT_OUT,
    CHISELED_SIDE,
    CORNER_CUTOUT,
    COUPE, COUPE_OVER_LENGTH, DEBASING, DEBASING_ROUGH,
    DRILL_HOLE, EXTRA_SANDING_5CM_BOTTOM,
    FINISHED_SIDE, GLUED_CUSHION, GROOVES, HEIGHT_COUPE,
    NOTCH, NOTCH_OVER_LENGTH, PROFILE, RABAT, ROUNDED_CORNER,
    WATERLIST
} from "../../constants/OperationTypes";
import {DebasingModal} from "./DebasingModal";
import {Type9And10ProfileModal} from "./Type9And10ProfileModal";
import {TYPE_10, TYPE_9} from "../../constants/ObjectTypes";
import {useSelector} from "react-redux";
import {RabatModal} from "./RabatModal";
import {ExtraSandingModal} from "./ExtraSandingModal";
import {NotchOverLengthModal} from "./NotchOverLengthModal";

export const OperationModal = (props) => {
    const {currentConfiguration} = useSelector(state => state.offerReducer);

    return (
        <>
            <FinishModal
                isActive={[FINISHED_SIDE, CHISELED_SIDE, WATERLIST].includes(props.activeOperationModal?.type)}
                onClose={(finishedSides, waterlists) => props.closeFinishedSidesModal(finishedSides, waterlists)}
                finishedSides={props.currentPiece?.getFinishedSides}
                waterlistSides={props.currentPiece?.waterlists.map(waterlist => waterlist.side)}
            />

            <NotchModal
                isActive={props.activeOperationModal?.type === NOTCH}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <CoupeModal
                isActive={props.activeOperationModal?.type === COUPE}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <DrillHoleModal
                isActive={props.activeOperationModal?.type === DRILL_HOLE}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <CornerCutoutModal
                isActive={props.activeOperationModal?.type === CORNER_CUTOUT}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <GroovesModal
                isActive={props.activeOperationModal?.type === GROOVES}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <RoundedCornerModal
                isActive={props.activeOperationModal?.type === ROUNDED_CORNER}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <HeightCoupeModal
                isActive={props.activeOperationModal?.type === HEIGHT_COUPE}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <CoupeOverLengthModal
                isActive={props.activeOperationModal?.type === COUPE_OVER_LENGTH}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <DebasingModal
                isActive={props.activeOperationModal?.type === DEBASING}
                onClose={() => props.closeOperationModal()}
            />

            <DebaseRoughModal
                isActive={props.activeOperationModal?.type === DEBASING_ROUGH}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <RectangularCutOutModal
                isActive={props.activeOperationModal?.type === RECTANGULAR_CUT_OUT}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <AnchorHoleModal
                isActive={props.activeOperationModal?.type === ANCHOR_HOLE}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <ProfileOperationModal
                isActive={props.activeOperationModal?.type === PROFILE &&
                ![TYPE_9, TYPE_10].includes(currentConfiguration?.options.type)}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <Type9And10ProfileModal
                isActive={props.activeOperationModal?.type === PROFILE &&
                [TYPE_9, TYPE_10].includes(currentConfiguration?.options.type)}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <GluedCushionModal
                isActive={props.activeOperationModal?.type === GLUED_CUSHION}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <RabatModal
                isActive={props.activeOperationModal?.type === RABAT}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <NotchOverLengthModal
                isActive={props.activeOperationModal?.type === NOTCH_OVER_LENGTH}
                onClose={(shouldRefreshCanvas) => props.closeOperationModal(shouldRefreshCanvas)}
            />

            <ConnectPieceModal
                isActive={props.activeOperationModal?.type === PIECE}
                onClose={(shouldRefreshCanvas) => props.closeConnectPieceModal(shouldRefreshCanvas)}
            />

            <DividePieceModal
                isActive={props.activeOperationModal?.type === DIVIDE_PIECE}
                onClose={(shouldRefreshCanvas) => props.closeDividePieceModal(shouldRefreshCanvas)}
                piece={props.currentPiece}
            />

            <ExtraSandingModal
                isActive={props.activeOperationModal?.type === EXTRA_SANDING_5CM_BOTTOM}
                onClose={(shouldRefreshCanvas) => props.closeDividePieceModal(shouldRefreshCanvas)}
            />
        </>
    );
};

OperationModal.propTypes = {
    currentPiece: PropTypes.object,
    activeOperationModal: PropTypes.object,
    closeOperationModal: PropTypes.func.isRequired,
    closeConnectPieceModal: PropTypes.func.isRequired,
    closeDividePieceModal: PropTypes.func.isRequired,
    closeFinishedSidesModal: PropTypes.func.isRequired,
}

OperationModal.defaultProps = {
    currentPiece: null,
    activeOperationModal: null,
}
