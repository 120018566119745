import {
    BoxGeometry,
    Color,
    FontLoader,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    OrthographicCamera,
    Scene,
    Vector3,
    WebGLRenderer,
} from "three";
import {MeshCreator} from "../helpers/MeshCreator";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {SurfaceMeasurementLineCreator} from "./SurfaceMeasurementLineCreator";
import {BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP} from "../../constants/ObjectSides";
import {TYPE_1, TYPE_2, TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8} from "../../constants/ObjectTypes";
import {cloneDeep} from 'lodash';
import {getConnectedPieces} from "../helpers/CollectionHelper";
import {
    addPromiseToSortedList,
    calculateFontSizeByZoom,
    getAspectByPreset,
    getAspectsToDraw,
    getImageName,
    getSortedImageObjects,
    ImageHelper2D,
    removeBaseDimensionsFromSpecialType,
    removeDefaultOperations,
} from "../helpers/ImageHelper2D";
import {getPointsForIntersection} from "../helpers/measurementLines/IntersectionMeasurementLineHelper";
import {PILLARS, UPRIGHT_PRESETS, VERTICAL_DISPLAYED_PRESETS} from "../../constants/Presets";
import {SideMeasurementLineCreator} from "./SideMeasurementLineCreator";
import {INTERSECTION} from "../../constants/CameraAngles";
import {getBoundingBox} from "../helpers/ThreeHelper";
import {MAX_HEIGHT} from "../../constants/Values";
import {CHISELED_SIDE, FINISHED_SIDE, WATERLIST} from "../../constants/OperationTypes";
import {normalizePiece} from "../helpers/NormalizePiece";
import {calculateArrowSize, createArrowsForFinishedSides} from "../helpers/FinishedSidesHelper";

export const generalImageType = 'general';
export const connectedImageType = 'connected';

class ImageCreator {
    constructor(configurations) {
        this.standardTypes = [
            TYPE_1,
            TYPE_2,
        ];

        this.specialTypes = [
            TYPE_3,
            TYPE_4,
            TYPE_5,
            TYPE_6,
            TYPE_7,
            TYPE_8,
        ];

        this.configurations = cloneDeep(configurations);

        this.meshCreator = new MeshCreator();
        this.surfaceMeasurementLineCreator = new SurfaceMeasurementLineCreator();
        this.sideMeasurementLineCreator = new SideMeasurementLineCreator();
        this.font = null;
        this.scene = null;
        this.renderer = null;
        this.camera = null;
        this.imageWidth = 1920;
        this.imageHeight = 1080;

        this.cameraWidth = 640;
        this.cameraHeight = 400;

        this.imageHelper2D = new ImageHelper2D();

        this.__initialize();
    }

    async getImages(sortedByHall = false) {
        let promises = [];

        if (!this.font) {
            await this.__loadFont().then(font => this.font = font);
        }

        this.configurations.forEach(configuration => {
            const connectedPieces = getConnectedPieces(configuration.pieces);

            connectedPieces.forEach(pieceList => {
                if (pieceList.length > 1) {
                    const promise = this.__getPromiseForDrawingConnectedPieces(configuration, pieceList);
                    promises = addPromiseToSortedList(promises, configuration, promise, sortedByHall ? connectedImageType : generalImageType);

                    // Erase the connection with the other pieces so all lines get drawn
                    pieceList = pieceList.map(piece => {
                        piece.eraseConnectionsWithOtherPieces();
                        return piece;
                    });
                }

                // Filter all pieces from the configuration that don't have any operations worth drawing
                this.imageHelper2D.resetIntersections();
                configuration = this.imageHelper2D.removeStandardPieces(configuration);

                pieceList.forEach(piece => {
                    // Set connectedObjects to an empty array so all lines will be drawn
                    piece.connectedObjects = [];
                    piece = normalizePiece(configuration, piece);

                    // Normalize rotation and position so the piece will appear without rotation
                    piece.rotation = {x: 0, y: 0, z: 0};
                    piece.position = {x: 0, y: 0, z: 0};

                    const hallNumber = configuration.getHallNumber(piece.dimensions.width);
                    const imageType = sortedByHall ? 'hall' + hallNumber : generalImageType;

                    const aspectsToDraw = getAspectsToDraw(configuration.options, removeDefaultOperations(configuration, piece));

                    aspectsToDraw.forEach(aspect => {
                        this.__clearCanvas();
                        const settings = {
                            hideArrows: false,
                            hideWaterlistLine: aspect !== TOP,
                            aspect: aspect
                        };

                        const object3D = this.__createObject(configuration, piece, settings);
                        this.scene.add(object3D);

                        // Use the Piece object for calculating the bounding box so no "world" data like rotations, ...
                        // is used in the calculation
                        const cameraPosition = this.__getCameraPositionForAspect(getBoundingBox([piece]), aspect, configuration.options.preset);
                        this.camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
                        this.camera.lookAt(0, 0, 0);

                        // Use the 3D object for calculating the bounding box so the arrows for finished sides are
                        // included as well
                        this.camera.zoom = this.__getZoomLevel(configuration.options.preset, getBoundingBox(object3D), aspect);
                        this.camera.updateProjectionMatrix();

                        this.renderer.render(this.scene, this.camera);
                        const promise = this.getImageFromCanvas(this.renderer, configuration, piece.name, aspect)
                        promises = addPromiseToSortedList(promises, configuration, promise, imageType);
                    });

                    if (this.specialTypes.includes(configuration.options.type)) {
                        const specialTypeSettings = {hideArrows: false, hideWaterlistLine: false, aspect: INTERSECTION};

                        // Remove operations that don't need rendering on the intersection image
                        piece = cloneDeep(piece);
                        piece.operations = piece.operations.filter(o => [WATERLIST, FINISHED_SIDE, CHISELED_SIDE].includes(o.type));

                        const object3D = this.__createObject(configuration, piece, specialTypeSettings);
                        let promise = this.__drawIntersection(configuration, piece, object3D);
                        promises = addPromiseToSortedList(promises, configuration, promise, imageType);
                    }
                });
            });
        });

        return this.__getImages(promises);
    }

    getImageFromCanvas(renderer, configuration, pieceName, aspect) {
        const name = getImageName(configuration.name, configuration.options.preset, pieceName, aspect);

        return new Promise(function (resolve) {
            renderer.domElement.toBlob(function (blob) {
                resolve({name: name, blob: blob});
            }, 'image/png', 5.0);
        });
    }

    __initialize() {
        this.scene = new Scene();
        this.scene.background = new Color(0xFFFFFF);

        this.renderer = new WebGLRenderer({preserveDrawingBuffer: true});
        this.renderer.setSize(1920, 1080);

        this.camera = new OrthographicCamera(this.cameraWidth / -2, this.cameraWidth / 2, this.cameraHeight / 2, this.cameraHeight / -2, -200, 500);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enabled = false;
        this.controls.target.set(0, 0, 0);
    }

    __loadFont() {
        return new Promise(function (resolve) {
            let loader = new FontLoader();

            loader.load(process.env.PUBLIC_URL + '/fonts/Poppins_Regular.json', (font) => resolve(font));
        });
    }

    __clearCanvas() {
        while (this.scene?.children.length > 0) {
            this.scene.remove(this.scene.children[0]);
        }
    }

    __getCameraPositionForAspect({center = new Vector3()}, aspect, preset) {
        let xPos = center.x;
        let yPos = center.y;
        let zPos = center.z;

        switch (aspect) {
            case FRONT:
                if (UPRIGHT_PRESETS.includes(preset)) {
                    zPos -= 10;
                } else {
                    zPos += 10;
                }
                break;
            case LEFT:
                xPos -= 10;
                break;
            case BACK:
                zPos -= 10;
                break;
            case RIGHT:
                xPos += 10;
                break;
            case BOTTOM:
                yPos -= 10;
                break;
            default:
                // TODO: delete this when it's sure that upright presets do not have to be rendered upright

                // if (UPRIGHT_PRESETS.includes(preset)) {
                //     zPos -= 10;
                // } else {
                //     yPos += 10;
                // }

                yPos += 10;
                break;
        }

        return new Vector3(
            xPos,
            yPos,
            zPos
        );
    }

    __getMeasurementLines(piece, type) {
        let lines;

        if ([TYPE_1, TYPE_2].includes(type)) {
            lines = [];
        } else {
            lines = getPointsForIntersection(this.font, piece.dimensions, type);
        }

        return lines;
    }

    __drawIntersection(configuration, piece, object3D) {
        this.camera.position.set(
            -(piece.dimensions.length / 2) - 10,
            piece.dimensions.height + 10,
            piece.dimensions.width
        );

        this.__clearCanvas();

        this.camera.lookAt(
            -(piece.dimensions.length / 2) + 10,
            0,
            0
        );

        this.camera.zoom = 12;
        this.camera.updateProjectionMatrix();

        let lines = this.__getMeasurementLines(piece, configuration.options.type);

        let whitePlane = new Mesh(new BoxGeometry(1, 100, 150), new MeshBasicMaterial({color: 'white'}));
        whitePlane.position.add(new Vector3(-(piece.dimensions.length / 2) + 20));

        this.scene.add(object3D, ...lines, whitePlane);
        this.renderer.render(this.scene, this.camera);

        this.imageHelper2D.addIntersection(removeBaseDimensionsFromSpecialType(configuration.options.type, piece.dimensions));

        return this.getImageFromCanvas(this.renderer, configuration, piece.name);
    }

    __getPromiseForDrawingConnectedPieces(configuration, pieceList) {
        this.__clearCanvas();
        const configurationObject = new Object3D();
        const boundingBox = getBoundingBox(pieceList);

        configurationObject.add(...pieceList.map(piece => {
            const aspect = UPRIGHT_PRESETS.includes(configuration.options.preset) ? BACK : TOP;
            const settings = {
                hideArrows: true,
                hideWaterlistLine: false,
                aspect: aspect,
                hideMeasurementLines: false
            };
            const object = this.__createObject(
                configuration,
                piece,
                settings,
                this.__getZoomLevel(configuration.options.preset, boundingBox, aspect)
            );

            // Add arrows for finished sides
            const arrowSize = calculateArrowSize(boundingBox.length > boundingBox.width ? boundingBox.length : boundingBox.width);
            const arrows = createArrowsForFinishedSides(piece, configuration.getFinishedSidesIncludedInPrice(), arrowSize);
            const arrowObject = new Object3D().add(...arrows);
            arrowObject.position.add(piece.position);
            arrowObject.rotateX(MathUtils.degToRad(piece.rotation.x));
            arrowObject.rotateY(MathUtils.degToRad(piece.rotation.y));
            arrowObject.rotateZ(MathUtils.degToRad(piece.rotation.z));
            object.add(arrowObject);

            return object;
        }));

        this.scene.add(configurationObject);

        // Bounding box with arrows for finished sides
        this.__setupCameraForConfigurationImage(configuration.options.preset, getBoundingBox(configurationObject));
        this.renderer.render(this.scene, this.camera);

        return this.getImageFromCanvas(this.renderer, configuration, null, TOP);
    }

    __createObject(configuration, piece, {
                       hideArrows = false,
                       hideWaterlistLine = false,
                       aspect = TOP,
                       hideMeasurementLines = false,
                   },
                   zoom = null
    ) {
        piece = [LEFT, RIGHT].includes(aspect) ?
            this.__removeFinishedSides(piece, [LEFT, RIGHT, BOTTOM, TOP]) :
            piece;

        let object = this.meshCreator.createMeshFromPiece(
            piece,
            {
                type: configuration.options.type,
                preset: configuration.options.preset,
                standardFinishedSides: configuration.getFinishedSidesIncludedInPrice(),
            },
            null,
            true,
            {hideArrows: hideArrows, hideWaterlistLine: hideWaterlistLine}
        );

        if (!hideMeasurementLines) object = this.__addLinesToObject(object, configuration, piece, aspect, zoom);
        object = this.__rotateObjectByPreset(configuration.options.preset, object, aspect);

        return object;
    }

    __getZoomLevel(preset, {length, width, height}, aspect) {
        // Margin of the image, so that there is some space between the edge of the object and the edge of the image
        let margin = 20;
        let zoom;

        if (VERTICAL_DISPLAYED_PRESETS.includes(preset)) {
            // Switch width and length, these presets are displayed upright like this: | not like this: -
            const tempWidth = width;
            width = length;
            length = tempWidth
        }

        if ([LEFT, RIGHT].includes(aspect)) {
            if (height > width) {
                zoom = this.cameraHeight / (height + margin);
            } else {
                if (UPRIGHT_PRESETS.includes(preset)) margin = 40;
                zoom = this.cameraWidth / (width + margin);
            }
        } else {
            if (height > MAX_HEIGHT) {
                zoom = this.cameraHeight / (height + margin);
            } else {
                const zoomLength = this.cameraWidth / (length + margin);
                const zoomWidth = this.cameraHeight / (width + margin);

                zoom = Math.min(zoomLength, zoomWidth);
            }
        }

        return zoom;
    }

    __rotateObjectByPreset(preset, object, aspect) {
        if (UPRIGHT_PRESETS.includes(preset)) {
            if ([LEFT, RIGHT].includes(aspect)) {
                object.rotateX(MathUtils.degToRad(90));
            }

            if ([FRONT, BACK].includes(aspect)) {
                object.rotateZ(MathUtils.degToRad(180));
            }
        }

        if (preset === PILLARS) {
            if (aspect === TOP) {
                object.rotateY(MathUtils.degToRad(90));
            } else if (aspect === FRONT) {
                object.rotateZ(MathUtils.degToRad(90));
            }
        }

        object.updateMatrix();

        return object;
    }

    __addLinesToObject(object, configuration, piece, aspect, zoom = null) {
        let lines;

        if (aspect === TOP) {
            lines = this.surfaceMeasurementLineCreator.createMeasurementLines(
                piece,
                piece.operations,
                configuration.type,
                this.font,
                calculateFontSizeByZoom(zoom),
            );
        } else {
            lines = this.sideMeasurementLineCreator.createMeasurementLinesForSides(
                piece,
                configuration,
                this.font,
                aspect
            );
        }

        if (lines.length) {
            let linesObject = new Object3D();
            linesObject.add(...lines);

            linesObject.rotateX(MathUtils.degToRad(piece.rotation.x));
            linesObject.rotateY(MathUtils.degToRad(piece.rotation.y));
            linesObject.rotateZ(MathUtils.degToRad(piece.rotation.z));

            linesObject.position.add(object.position);

            let object3D = new Object3D();
            object3D.add(object, linesObject);

            return object3D;
        } else {
            return object;
        }
    }

    __setupCameraForConfigurationImage(preset, boundingBox) {
        const cameraPosition = this.__getCameraPositionForAspect(
            boundingBox,
            getAspectByPreset(preset, TOP),
            preset
        );

        this.camera.position.set(
            cameraPosition.x,
            cameraPosition.y,
            cameraPosition.z
        );

        this.camera.lookAt(
            boundingBox.center.x,
            boundingBox.center.y,
            boundingBox.center.z
        );

        this.camera.zoom = this.__getZoomLevel(preset, boundingBox, getAspectByPreset(preset, TOP));

        this.camera.updateProjectionMatrix();
    }

    __removeFinishedSides(piece, finishedSidesToRemove) {
        piece = cloneDeep(piece);
        piece.operations = piece.operations.filter(o => !([FINISHED_SIDE, CHISELED_SIDE].includes(o.type) && finishedSidesToRemove.includes(o.side)));

        return piece;
    }

    async __getImages(promises) {
        return await getSortedImageObjects(promises).then(data => {
            return data;
        });
    }
}

export default ImageCreator;
