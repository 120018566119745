import {CoupeOperation, NotchOperation, Parser, Service} from "../../internal";
import {CENTIMETERS} from "../../constants/Values";

class OperationService extends Service {

    async createNotch(configurationId, piece, notch) {
        const data = {
            length: notch.dimensions.length,
            width: notch.dimensions.width,
            height: 0,
            additionalValue: notch.additionalDimension.value,
            additionalType: CENTIMETERS,
            side: notch.side,
            position: notch.position,
            isFinished: notch.isFinished,
        };

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/notch`,
            data)
            .then((response) => {
                if (response.data.success) response.data.data = Parser.parseOperation(piece, response.data.data);

                return response.data;
            })
            .catch((error) => {
                throw error.response;
            });
    }

    async createCoupe(configuration, pieceId, operation) {
        if (operation == null) {
            operation = new CoupeOperation();
        }

        const data = {
            connectedCoupe: operation.connectedCoupe,
            length: operation.dimensions.length,
            width: operation.dimensions.width,
            height: 0,
            additionalValue: operation.additionalDimension.value,
            additionalType: operation.additionalDimension.type,
            side: operation.side,
            angle: operation.angle,
            position: operation.position,
            isFinished: operation.isFinished,
        };

        return await this.authenticatedRequest(
            'post',
            `configurations/${configuration.id}/pieces/${pieceId}/operations/coupe`,
            data
        )
            .then(response => {
                if (response.data.success) response.data.data = Parser.parsePiece(response.data.data, configuration.options);

                return response.data;
            })
            .catch(error => {
                return error.response;
            });
    }

    async createCornerCutout(configurationId, piece, operation) {
        const data = {
            length: operation.dimensions.length,
            width: operation.dimensions.width,
            side: operation.side,
            additionalType: operation.additionalDimension.type,
            isFinished: operation.isFinished,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/cornercutout`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        })
    }

    async createGrooves(configurationId, piece, operation) {
        const data = {
            additionalType: operation.additionalType,
        };

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/grooves`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createRoundedCorner(configurationId, piece, operation) {
        const data = {
            length: operation.dimensions.length,
            side: operation.side,
            additionalType: operation.additionalDimension.type,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/roundedcorner`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createProfile(configurationId, piece, operation, sides) {
        const data = {
            sides: sides,
            additionalType: operation.additionalDimension.type,
            width: operation.dimensions.width,
            height: operation.dimensions.height,
            data: operation.data,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/profile`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = response.data.data.map(operationData => {
                    return Parser.parseOperation(piece, operationData);
                })
            }

            return response.data;
        });
    }

    async createDrillHole(configurationId, piece, operation) {
        const data = {
            length: operation.dimensions.length,
            position: operation.position,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/drillhole`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createHeightCoupe(configurationId, piece, operation) {
        const data = {
            side: operation.side,
            angle: operation.angle,
            width: operation.dimensions.width,
            length: operation.dimensions.length,
            additionalValue: operation.additionalDimension.value,
            additionalType: operation.additionalDimension.type,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/heightcoupe`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        })
    }

    async createCoupeOverLength(configurationId, piece, operation) {
        const data = {
            side: operation.side,
            width: operation.dimensions.width,
            additionalType: operation.additionalDimension.type,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/coupeoverlength`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createDebasingRough(configurationId, piece, operation) {
        const data = {
            height: operation.dimensions.height,
            additionalType: operation.additionalDimension.type,
            side: operation.side,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/debasingrough`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createRectangularCutOut(configurationId, piece, operation) {
        const data = {
            length: operation.dimensions.length,
            width: operation.dimensions.width,
            additionalType: operation.additionalDimension.type,
            position: operation.position,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/rectangularcutout`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createAnchorHole(configurationId, piece, operation) {
        const data = {
            position: operation.position,
            side: operation.side,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/anchorhole`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createRabat(configurationId, piece, operation) {
        const data = {
            side: operation.side,
            additionalType: operation.additionalDimension.type,
            width: operation.dimensions.width,
            height: operation.dimensions.height,
            isFinished: operation.isFinished ?? false,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/rabat`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async createNotchOverLength(configurationId, piece, operation) {
        const data = {
            side: operation.side,
            additionalType: operation.additionalDimension.type,
            additionalValue: operation.additionalDimension.value,
            width: operation.dimensions.width,
            height: operation.dimensions.height,
            isFinished: operation.isFinished ?? false,
            position: operation.position,
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/notchoverlength`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = Parser.parseOperation(piece, response.data.data);
            }

            return response.data;
        });
    }

    async updateGluedCushions(configurationId, piece, operations) {
        const data = {
            cushions: operations.map(operation => {
                return {
                    side: operation.side,
                    length: operation.dimensions.length,
                    height: operation.dimensions.height,
                    width: operation.dimensions.width,
                    additionalType: operation.additionalDimension.type,
                }
            })
        }

        return await this.authenticatedRequest(
            'post',
            `configurations/${configurationId}/pieces/${piece.id}/operations/gluedcushions`,
            data
        ).then(response => {
            if (response.data.success) {
                response.data.data = response.data.data.map(responseData => Parser.parseOperation(piece, responseData));
            }

            return response.data;
        });
    }

    async update(configurationId, piece, operation) {
        let data = {
            length: operation.dimensions.length,
            width: operation.dimensions.width,
            height: operation.dimensions.height,
            side: operation.side,
            position: operation.position,
            isFinished: operation.isFinished,
            additionalType: operation.additionalDimension.type,
            additionalValue: operation.additionalDimension.value,
            angle: operation.angle,
        };

        if (operation instanceof NotchOperation) {
            data.additionalType = CENTIMETERS;
        } else if (operation instanceof CoupeOperation) {
            data.additionalValue = operation.additionalDimension.value;
            data.additionalType = operation.additionalDimension.type;
            data.angle = operation.angle;
        }

        return await this.authenticatedRequest(
            'put',
            `configurations/${configurationId}/pieces/${piece.id}/operations/${operation.id}`,
            data
        )
            .then(response => {
                if (response.data.success) response.data.data = Parser.parseOperation(piece, response.data.data);

                return response.data;
            })
            .catch(error => {
                throw error.response;
            })
    }

    async updateSandedSides(configurationId, piece, sides) {
        return await this.authenticatedRequest(
            'put',
            `configurations/${configurationId}/pieces/${piece.id}/operations/0/sandedsides`,
            {sides}
        ).then(response => {
            if (response.data.success) {
                response.data.data = response.data.data.map(operationData => {
                    return Parser.parseOperation(piece, operationData);
                })
            }

            return response.data;
        })
    }

    async updateProfiles(configurationId, piece, additionalType, sides, dimensions, data = null) {
        const requestData = {
            sides: sides,
            additionalType: additionalType,
            width: dimensions.width,
            height: dimensions.height,
            data: data,
        }

        return await this.authenticatedRequest(
            'put',
            `configurations/${configurationId}/pieces/${piece.id}/operations/0/profiles`,
            requestData
        ).then(response => {
            if (response.data.success) {
                response.data.data = response.data.data.map(operationData => {
                    return Parser.parseOperation(piece, operationData);
                })
            }

            return response.data;
        })
    }

    async deleteProfiles(configurationId, pieceId, additionalType, surface) {
        return await this.authenticatedRequest(
            'delete',
            `configurations/${configurationId}/pieces/${pieceId}/profiles`,
            {additionalType, surface}
        )
            .then(response => {
                return response.status === 204;
            })
            .catch(error => {
                throw error.response;
            });
    }

    async delete(configurationId, pieceId, operationId) {
        return await this.authenticatedRequest(
            'delete',
            `configurations/${configurationId}/pieces/${pieceId}/operations/${operationId}`
        )
            .then(response => {
                return response.status === 204;
            })
            .catch(error => {
                throw error.response;
            });
    }

}

export {OperationService};
