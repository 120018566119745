import * as React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";

export const RemovedProductsBadge = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'shared.badges.removedProductsBadge.';

    return (
        <small
            className="badge badge--red badge--opacity-1 m-r-1"
            data-tip={t(prefix + 'tooltip', {removedProducts: props.removedProducts})}>
            {t(prefix + 'content')}
        </small>
    );
};

RemovedProductsBadge.propTypes = {
    removedProducts: PropTypes.string.isRequired,
}
