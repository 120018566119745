import * as React from 'react';
import PropTypes from 'prop-types';
import {Title} from '../../internal';
import ReactTooltip from "react-tooltip";

export const SideBar = (props) => {
    const getStyling = () => {
        let style = 'app__sidebar';

        if (props.right) {
            style += ' app__sidebar--right'
        }

        return style;
    }

    const renderBackButton = () => {
        if (props.onReturnClick) {
            return <i data-cy={`${props.dataCy}-backButton`} className='material-icons pointer' onClick={() => props.onReturnClick()}>arrow_back</i>
        }
    }

    return (
        <div data-cy={props.dataCy} className={getStyling()}>

            <div className="app__content__header">
                {props.title ? <Title dataCy={`${props.dataCy}-title`} title={props.title}>{renderBackButton()}</Title> : null}

                {props.openModal ?
                    <div className="app__content__header__actions">
                        <button className="button button--icon" data-tip={props.tooltipContent}
                                onClick={() => props.openModal()}>
                            <i className="material-icons">add</i>
                        </button>
                    </div> :
                    null
                }

                {props.tooltipContent ? <ReactTooltip/> : null}

            </div>

            {props.children}

        </div>
    );
};

SideBar.propTypes = {
    title: PropTypes.string,
    openModal: PropTypes.func,
    right: PropTypes.bool,
    onReturnClick: PropTypes.func,
    dataCy: PropTypes.string,
}

SideBar.defaultProps = {
    right: false,
}
