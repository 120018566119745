import {useEffect} from "react";
import OutdoorShopService from "../services/OutdoorShopService";
import {useDispatch} from "react-redux";
import {setArticleModalActive, setArticles} from "../../actions/OutdoorShopActions";
import {setWindowIsLoading} from "../../actions/GeneralActions";

export const useArticles = () => {
    const outdoorShopService = new OutdoorShopService();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setWindowIsLoading(true));
        outdoorShopService
            .getArticles()
            .then((articles) => dispatch(setArticles(articles)))
            .finally(() => dispatch(setWindowIsLoading(false)));
    }, []);

    const closeArticleModal = () => {
        dispatch(setArticleModalActive(false));
    }

    return {
        closeArticleModal,
    };
};
