import {
    CoupeOperation,
    OperationBuilder,
    Type1Piece,
    Type2Piece,
    Type3Piece,
    Type4Piece,
    Type5Piece,
    Type6Piece,
    Type7Piece,
    Type8Piece
} from "../../internal.js";
import {BACK, FRONT, LEFT, RIGHT} from "../../constants/ObjectSides";
import * as ObjectTypes from "../../constants/ObjectTypes";
import {dividePieceInParts} from "../helpers/PieceDivisionHelper";
import {COUPE} from "../../constants/OperationTypes";
import {INNER, OUTER} from "../../constants/Angles";

export class PieceBuilder {

    constructor() {
        this.operationBuilder = new OperationBuilder();
    }

    buildWithCoupe(dimensions, anchorObject, coupe, additionalDimension, type, coupeType, newCoupeWidth) {
        if (anchorObject == null || coupe == null) {
            return;
        }

        let anchorCoupe = Object.assign(new CoupeOperation(), coupe);

        // Create a new piece with the same dimensions
        let piece = this.__createPieceByType(0, dimensions, type);
        piece.rotation.y = 0;

        piece.parts = dividePieceInParts(
            piece.dimensions.length,
            piece.dimensions.width,
            piece.dimensions.height,
            type
        );

        piece = this.__addCoupe(piece, additionalDimension, anchorCoupe, coupeType, newCoupeWidth);

        piece.updateObjectRotationAndPosition();

        return piece;
    }

    build(name, dimensions, type) {
        let newPiece = this.__createPieceByType(0, dimensions, type);
        newPiece.name = name;

        newPiece.parts = dividePieceInParts(
            dimensions.length,
            dimensions.width,
            dimensions.height,
            type
        );

        return newPiece;
    }

    __createPieceByType(id, dimensions, type) {
        let piece;

        switch (type) {
            case ObjectTypes.TYPE_1:
                piece = new Type1Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_2:
                piece = new Type2Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_3:
                piece = new Type3Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_4:
                piece = new Type4Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_5:
                piece = new Type5Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_6:
                piece = new Type6Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_7:
                piece = new Type7Piece(id, dimensions, []);
                break;
            case ObjectTypes.TYPE_8:
                piece = new Type8Piece(id, dimensions, []);
                break;
            default:
                piece = new Type1Piece(id, dimensions, []);
                break;
        }

        return piece;
    }

    __addCoupe(piece, additionalDimension, anchorCoupe, coupeType, coupeWidth) {
        const side = this.__getSide(anchorCoupe);
        let angle;

        if (anchorCoupe.type === COUPE) angle = anchorCoupe.angle;
        else angle = anchorCoupe.angle === INNER ? OUTER : INNER;

        let coupeOperation = this.operationBuilder.buildCoupe(piece, additionalDimension, angle, side, coupeType, coupeWidth);
        coupeOperation.addConnectedCoupe(anchorCoupe);

        piece.addCoupe(coupeOperation);

        return piece;
    }

    __getSide(anchorCoupe) {
        let side;

        switch (anchorCoupe.side) {
            case LEFT:
                side = RIGHT;
                break;
            case RIGHT:
                side = LEFT;
                break;
            case FRONT:
                side = BACK;
                break;
            case BACK:
                side = FRONT;
                break;
        }

        return side;
    }
}
