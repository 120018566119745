import {BACK, FRONT, LEFT, RIGHT} from "../../constants/ObjectSides";
import {RECTANGULAR_CUT_OUT, NOTCH} from "../../constants/OperationTypes";
import {Vector3} from "three";

export function getPieceBoundingPosition(dimensions, side = RIGHT) {
    let xPos = 0;
    let zPos = 0;

    if (side === RIGHT) {
        xPos = dimensions.length / 2;
    } else if (side === BACK) {
        zPos = dimensions.width / -2;
    } else if (side === FRONT) {
        zPos = dimensions.width / 2;
    }

    return new Vector3(
        xPos,
        0,
        zPos,
    );
}

export function getOperationBoundingPosition(operation, side = RIGHT) {
    let position;

    switch (operation.type) {
        case NOTCH:
            position = getNotchBoundingPosition(operation, side);
            break;
        case RECTANGULAR_CUT_OUT:
            position = getRectangularCutOutBoundingPosition(operation, side);
            break;
    }

    return position;
}

function getNotchBoundingPosition(notch, side) {
    let xPos, zPos;

    if (side === RIGHT) {
        xPos = notch.position.x + notch.dimensions.length / 2;
        zPos = notch.position.z;
    }

    return new Vector3(
        xPos,
        0,
        zPos
    );
}

function getRectangularCutOutBoundingPosition(operation, side) {
    let xPos, zPos;

    switch (side) {
        case LEFT:
            xPos = operation.position.x - operation.dimensions.length;
            zPos = operation.position.z;
            break;
        case RIGHT:
            xPos = operation.position.x + operation.dimensions.length / 2;
            zPos = operation.position.z + operation.position.z;
            break;
        case FRONT:
            xPos = operation.position.x;
            zPos = operation.position.z + operation.dimensions.width / 2;
            break;
        case BACK:
            xPos = operation.position.x;
            zPos = operation.position.z - operation.dimensions.width / 2;
            break;
    }

    return new Vector3(
        xPos,
        0,
        zPos,
    );
}
