import * as React from 'react';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import CompanyService from "../../../classes/services/CompanyService";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addAlertMessage, setShouldUpdateLogo, setWindowIsLoading} from "../../../actions/GeneralActions";
import {DANGER, SUCCESS, WARNING} from "../../../constants/Variants";
import {LoadingMessage} from "../../messages/LoadingMessage";
import {SectionTitle} from "../../headers/SectionTitle";
import logo from '../../../assets/img/logo.svg';
import {CompanyBranchService} from "../../../classes/services/CompanyBranchService";
import {Message} from "../../messages/Message";

export const CompanySettings = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.settings.company.';

    const [company, setCompany] = useState();
    const [currentLogo, setCurrentLogo] = useState(null);
    const [logoIsLoading, setLogoIsLoading] = useState(false);
    const [fileToUpload, setFileToUpload] = useState();
    const [invalidFileExtensionUploaded, setInvalidFileExtensionUploaded] = useState(false);

    const companyService = new CompanyService();
    const companyBranchService = new CompanyBranchService();

    const dispatch = useDispatch();
    const windowIsLoading = useSelector(state => state.generalReducer.windowIsLoading);
    const {currentCompanyBranch} = useSelector(state => state.companyReducer);

    useEffect(() => {
        getCompanyInfo();
        getCurrentLogo();
    }, []);

    useEffect(() => {
        if (fileToUpload) {
            if (fileToUpload.type.includes('image') && invalidFileExtensionUploaded) {
                setInvalidFileExtensionUploaded(false);
            }

            if (!fileToUpload.type.includes('image') && !invalidFileExtensionUploaded) {
                setInvalidFileExtensionUploaded(true);
            }
        }
    }, [fileToUpload]);

    const getCompanyInfo = () => {
        dispatch(setWindowIsLoading(true));

        companyService.getCompanyOfCurrentUser()
            .then(response => {
                if (response.success) {
                    setCompany(response.data);
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'fetchCompanyFailed')));
                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const getCurrentLogo = () => {
        setLogoIsLoading(true);

        companyBranchService.getCurrentLogo(currentCompanyBranch.id)
            .then(response => {
                if (response instanceof Blob) {
                    setCurrentLogo(URL.createObjectURL(response));
                } else {
                    if (response.status === 400) {
                        throw Error(response.message);

                        //ELSE 404 No logo exists
                    }
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'fetchLogoFailed')));

                throw error;
            })
            .finally(() => setLogoIsLoading(false));
    }

    const updateLogo = () => {
        if (!fileToUpload) {
            dispatch(addAlertMessage(WARNING, t(prefix + 'noFileSelectedMessage')));
            return;
        }

        dispatch(setWindowIsLoading(true));

        companyBranchService.updateLogo(currentCompanyBranch.id, fileToUpload)
            .then(response => {
                if (response.message) {
                    throw Error(response.message);
                } else {
                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateLogoSuccess')));

                    setCurrentLogo(URL.createObjectURL(response));
                    addLogoToLocalstorage(response);

                    setFileToUpload(null);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateLogoFailed')))

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const addLogoToLocalstorage = (blob) => {
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            let base64Logo = reader.result;

            localStorage.setItem('logo', base64Logo);
            dispatch(setShouldUpdateLogo(true));
        }
    }

    const getFileInputContent = () => {
        let content;

        if (fileToUpload) {
            content = fileToUpload.name;
        } else {
            content = t(prefix + 'fileInputContent');
        }

        return content;
    }

    const renderContent = () => {
        if (company) {
            return <div className="p-l-4 p-r-4">
                <div className="message message--info">
                    {t(prefix + 'infoMessage')}
                </div>

                <div className="m-t-2 m-b-2">
                    <div className="form-group">
                        <label>{t(prefix + 'companyNameLabel')}</label>
                        <input type="text" disabled value={company.name}/>
                    </div>

                    <div className="form-group">
                        <label>{t(prefix + 'vatNumberLabel')}</label>
                        <input type="text" disabled value={company.vatNumber}/>
                    </div>

                    <div className="form-group">
                        <label>{t(prefix + 'addressLabel')}</label>
                        <input type="text" disabled value={company.address}/>
                    </div>
                </div>

                <br/>

                <SectionTitle content="Logo"/>

                {
                    invalidFileExtensionUploaded ?
                        <div className="m-b-3">
                            <Message variant={WARNING} content={t(prefix + 'invalidFileTypeMessage')}/>
                        </div>
                        : null
                }

                <div className="d-flex space-between">
                    {
                        logoIsLoading ? <LoadingMessage variant="inline"/> :
                            <span className="settings__logo">
                                {
                                    currentLogo ? <img src={currentLogo} alt="logo"/> : <img alt="logo" src={logo}/>
                                }
                            </span>
                    }

                    <div className="form-group m-r-2">
                        {/*<label>{t(prefix + 'logoLabel')} {company.logo.name}</label>*/}
                        <div className="form-group__file">
                            <i className="material-icons">attachment</i> {getFileInputContent()}
                            <input
                                name="fileUploader"
                                type="file"
                                onChange={(event) => setFileToUpload(event.target.files[0])}
                            />
                        </div>
                    </div>
                    <button
                        type="button"
                        className="button button--primary"
                        onClick={() => updateLogo()}
                        disabled={invalidFileExtensionUploaded}>
                        {t(prefix + 'updateLogoButton')}
                    </button>
                </div>
            </div>
        }
    }

    return (
        <div>
            {
                windowIsLoading ?
                    <LoadingMessage variant="overlay"/> :
                    renderContent()
            }
        </div>
    );
};
