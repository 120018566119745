//@flow
import * as React from 'react';
import {useEffect, useState} from 'react';
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable,} from "@tanstack/react-table";
import PropTypes from "prop-types";
import {Order} from "../../../classes/models/Order";
import {hasOneOfRoles, isAdmin} from "../../../classes/helpers/UserHelper";
import {OrderHandledButton} from "../../shared/OrderHandledButton";
import {EmptyPage} from "../../empty/EmptyPage";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {OrderStatus} from "./OrderStatus";
import {OrderItemActionsDropdown} from "./OrderItemActionsDropdown";
import {ADMIN, SUPERVISOR} from "../../../constants/RoleNames";
import {DeliveryDateInfo} from "./DeliveryDateInfo";
import {useDispatch, useSelector} from "react-redux";
import {setOrderPaginationSettings} from "../../../actions/OrderActions";
import {COLLECT} from "../../../constants/DeliveryOptions";
import {AdsolutBadge} from "../../shared/AdsolutBadge";

export const OrderTable = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.orders.orderTable.';
    const dispatch = useDispatch();
    const [orderTableRows, setOrderTableRows] = useState([]);

    useEffect(() => {
        if (props.hasCompanyBranchSelected || !isAdmin()) {
            setOrderTableRows(props.orders);
        } else {
            setOrderTableRows(props.orders.map(order => {
                    return {
                        ...order,
                        companyName: props.companyBranches.find(branch => branch.id === order.companyBranchId)?.name
                    }
                })
            );
        }
    }, [props.orders, props.companyBranches, props.hasCompanyBranchSelected]);

    const {paginationSettings} = useSelector(state => state.orderReducer);

    const onHandledClick = (event, order) => {
        event.stopPropagation();

        if (isAdmin()) {
            props.onHandledClick(order);
        }
    }
    const getLabel = (order) => {
        if (order.deliveryType === COLLECT) {
            return t(prefix + 'collectionLabel');
        }

        return t(prefix + 'deliveryLabel');
    };

    const columnHelper = createColumnHelper<Order>();

    const openOrderStatusModal = (order) => {
        props.onEditOrderStatusClick(order);
    }

    const columns = [
        columnHelper.display({
            id: 'handled',
            cell: props => <OrderHandledButton
                order={Object.assign(new Order(), props.row.original)}
                onClick={(event) => onHandledClick(event, props.row.original)}
            />
        }),
        columnHelper.accessor('id', {header: t(prefix + 'orderId')}),
        columnHelper.accessor('name', {header: t(prefix + 'name')}),
        columnHelper.display({
            id: 'adsolut',
            header: t(prefix + "adsolut"),
            cell: props => props.row.original.isCreatedByAdmin ?
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><AdsolutBadge/>
                </div> : null
        }),
        columnHelper.accessor(row => row.creator.firstName + ' ' + row.creator.lastName, {
            id: 'creator',
            header: t(prefix + 'creator')
        }),
        columnHelper.accessor('companyName', {header: t(prefix + 'company')}),
        columnHelper.accessor(row => `€ ${row.price}`, {id: 'price', header: t(prefix + 'price')}),
        columnHelper.accessor('timestamp.date', {header: t(prefix + 'orderedDate')}),
        columnHelper.display({
            header: t(prefix + 'status'),
            id: 'statuses',
            cell: props => <OrderStatus order={Object.assign(new Order(), props.row.original)}/>
        }),
        columnHelper.display({
            id: "delivery",
            header: t(prefix + 'deliveryType'),
            cell: props => getLabel(props.row.original)
        }),
        columnHelper.display({
            id: "date",
            header: t(prefix + 'deliveryDate'),
            cell: props => <DeliveryDateInfo order={Object.assign(new Order(), props.row.original)}/>
        }),
        columnHelper.display({
            id: "actions",
            cell: props => <OrderItemActionsDropdown
                onEditStatusClick={() => openOrderStatusModal(props.row.original)}/>
        }),
    ]

    const getOnOrderClick = (order) => {
        if (!order.isCreatedByAdmin || hasOneOfRoles([ADMIN, SUPERVISOR])) return props.onOrderClick(order);

        return () => {
        };
    }

    const changePage = (page) => {
        const pagination = {
            ...paginationSettings,
            current_page: page
        }
        dispatch(setOrderPaginationSettings(pagination));
    }

    //is in a function because 2 booleans don't work in columnVisibility
    const creatorShouldBeVisible = () => {
        return isAdmin() && props.hasCompanyBranchSelected;
    }

    //is in a function because 2 booleans don't work in columnVisibility
    const companyShouldBeVisible = () => {
        return isAdmin() && !props.hasCompanyBranchSelected;
    }

    const isLastPage = () => {
        return paginationSettings.current_page === paginationSettings.last_page;
    }

    const isFirstPage = () => {
        return paginationSettings.current_page === 1;
    }

    //Determine styling by checking if column.id is id and if user is admin and then add extra styling
    const determineStyling = (id) => {
        let styling = "order__table--" + id;
        if (id === 'id' && !isAdmin()) {
            styling += " p-l-3";
        }
        return styling;
    }

    if (props.orders.length < 1) {
        return <EmptyPage title={t(prefix + 'noOrdersFound')}/>;
    }

    const table = useReactTable({
        data: orderTableRows,
        columns,
        state: {
            columnVisibility: {
                handled: isAdmin(),
                actions: isAdmin(),
                creator: creatorShouldBeVisible(),
                companyName: companyShouldBeVisible(),
            }
        },
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <>
            <table className="table table--striped table__order m-r-2 m-l-2 m-t-2">
                <thead data-test="tableHeader">
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => {
                            let styling = determineStyling(header.column.id)
                            return (
                                <th key={header.id} className={styling} data-test={header.column.id + 'Header'}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            )
                        })}
                    </tr>
                ))}
                </thead>
                <tbody data-test="tableBody">
                {table.getRowModel().rows.map(order =>  (
                        <tr data-cy={`orderRowId-${order.original.id}`} key={order.id} className="pointer order__table--row"
                            onClick={() => getOnOrderClick(order.original)}>
                            {order.getVisibleCells().map(cell => {
                                let styling = determineStyling(cell.column.id)
                                return (
                                    <td key={cell.id} className={styling} data-test={cell.column.id + 'Cell'}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                )}
                </tbody>
            </table>
            <div className="pagination">
                <button
                    className="button button--primary"
                    onClick={() => changePage(1)}
                    disabled={isFirstPage()}
                    data-test='firstPage'
                >
                    {' << '}
                </button>
                <button
                    className="button button--primary"
                    onClick={() => changePage(paginationSettings.current_page - 1)}
                    disabled={isFirstPage()}
                    data-test='previousPage'
                >
                    {' < '}
                </button>
                <span>
                    {t(prefix + 'paginationInfo', {
                        index: paginationSettings.current_page,
                        total: paginationSettings.last_page
                    })}
                </span>
                <button
                    className="button button--primary"
                    onClick={() => changePage(paginationSettings.current_page + 1)}
                    disabled={isLastPage()}
                    data-test='nextPage'
                >
                    {' > '}
                </button>
                <button
                    className="button button--primary"
                    onClick={() => changePage(paginationSettings.last_page)}
                    disabled={isLastPage()}
                    data-test='lastPage'
                >
                    {' >> '}
                </button>
            </div>
        </>
    );
}

OrderTable.propTypes = {
    orders: PropTypes.array.isRequired,
    onHandledClick: PropTypes.func.isRequired,
    onEditOrderStatusClick: PropTypes.func.isRequired,
    onOrderClick: PropTypes.func.isRequired,
    hasCompanyBranchSelected: PropTypes.bool.isRequired,
    companyBranches: PropTypes.array.isRequired,
}
