import * as React from "react";
import {cloneDeep, isEmpty} from "lodash";

export const addUnitCode = (article, number, withSeparator = false) => {
    let text;
    const separator = withSeparator ? '/' : '';

    if (article.unit === 'M2') {
        text = `${number}${separator}m²`
    } else {
        text = `${number}${separator}st`;
    }

    return text;
}

export const filterArticlesByKeyword = (articles, keyword) => {
    return new Promise(resolve => {
        if (keyword) {
            let filteredArticles = cloneDeep(articles);

            Object.keys(filteredArticles)
                .forEach(category1 => {
                    Object.keys(filteredArticles[category1])
                        .forEach(category2 => {
                            Object.keys(filteredArticles[category1][category2])
                                .forEach(category3 => {
                                    filteredArticles[category1][category2][category3] = filteredArticles[category1][category2][category3]
                                        .filter(article => article.name.toLowerCase().includes(keyword.toLowerCase()))
                                })
                        })
                });

            filteredArticles = removeEmpty(filteredArticles);

            resolve(filteredArticles);
        } else {
            resolve(articles);
        }
    })
};

function removeEmpty(obj) {
    Object.keys(obj).forEach(category1 => {
        Object.keys(obj[category1]).forEach(category2 => {
            Object.keys(obj[category1][category2]).forEach(category3 => {
                if (!obj[category1][category2][category3].length) {
                    delete obj[category1][category2][category3];
                }
            })

            if (isEmpty(obj[category1][category2])) delete obj[category1][category2];
        })

        if (isEmpty(obj[category1])) delete obj[category1];
    })

    return obj;
}
