import produce from 'immer';
import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
    currentManufacturer: null,
};

function orderReducer(state = initialState, action) {
    return produce(state, draftState => {
        switch (action.type) {
            case ActionTypes.SET_CURRENT_MANUFACTURER:
                draftState.currentManufacturer = action.manufacturer;
                break;
            default:
                break;
        }
    });

}

export default orderReducer;