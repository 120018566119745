import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {CheckBox} from "../forms/selects/CheckBox";
import {SectionTitle} from "../headers/SectionTitle";
import {CheckboxWithIcon} from "../forms/inputs/CheckboxWithIcon";
import sideLeft from '../../assets/img/side-left.svg';
import sideLeftPillar from '../../assets/img/side-left-pillar.png';
import sideLeftPartially from '../../assets/img/side-left-particially.svg';
import sideRight from '../../assets/img/side-right.svg';
import sideRightPillar from '../../assets/img/side-right-pillar.png';
import sideRightPartially from '../../assets/img/side-right-particially.svg';
import sideBack from '../../assets/img/side-back.svg';
import sideFront from '../../assets/img/side-front.svg';
import sideTop from '../../assets/img/side-top.svg';
import sideBottom from '../../assets/img/side-bottom.svg';
import sideNone from '../../assets/img/side-none.svg';
import {RadioButton} from "../forms/inputs/RadioButton";
import {COMPLETELY, NONE, PARTIALLY} from "../../constants/FinishedSideStates";
import {useSelector} from "react-redux";
import {EXTERIOR} from "../../constants/Values";
import {EXTERIOR_PRESETS_WITHOUT_WATERLISTS, LINTELS, PILLARS} from "../../constants/Presets";
import {getSideNameByPreset} from "../../classes/helpers/ObjectHelper";
import {ALL_SIDES, BACK, BOTTOM, FRONT, LEFT, RIGHT, TOP} from "../../constants/ObjectSides";
import {ModalHolder} from "./ModalHolder";
import {CHISELED_OBJECT_TYPES} from "../../constants/ObjectTypes";
import {COUPE} from "../../constants/OperationTypes";

export const FinishModal = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.finishModal.';
    const constantsPrefix = 'constants.objectSides.';

    const [updatedSides, setUpdatedSides] = useState([]);
    const [waterlists, setWaterlists] = useState([]);
    const [selectableSides, setSelectableSides] = useState([]);
    const [canAddWaterlists, setCanAddWaterlists] = useState(false);

    const {currentConfiguration, currentPiece} = useSelector(state => state.offerReducer);
    const {canEdit} = useSelector(state => state.generalReducer);

    useEffect(() => {
        if (props.isActive) {
            if (props.finishedSides) setUpdatedSides(props.finishedSides);
            if (props.waterlistSides) setWaterlists(props.waterlistSides);

            setCanAddWaterlists(
                currentConfiguration.options.decor === EXTERIOR &&
                !EXTERIOR_PRESETS_WITHOUT_WATERLISTS.includes(currentConfiguration.options.preset)
            );

            setSelectableSides(getSelectableSides());
        }
    }, [props.isActive]);

    const getDescription = () => {
        let description;

        if (EXTERIOR_PRESETS_WITHOUT_WATERLISTS.includes(currentConfiguration?.options.preset)) {
            description = t(prefix + 'uprightPresetsDescription');
        } else {
            description = t(prefix + 'description');
        }

        return description;
    }

    const getSelectableSides = () => {
        let sides = ALL_SIDES;

        if (!currentPiece) return sides;

        const coupes = currentPiece.getOperationsByType(COUPE);

        coupes.forEach(coupe => {
            if (coupe.connectedCoupe) {
                sides = sides.filter(s => s !== coupe.side);
            }
        });

        return sides;
    }

    const sideSelected = (name, type) => {
        if (type === NONE) {
            let index = -1;

            updatedSides.forEach((side, i) => {
                if (side.name === name) index = i;
            });

            return index < 0;
        }

        let index = -1;

        updatedSides.forEach((side, i) => {
            if (side.name === name && side.type === type) index = i;
        });

        return index > -1;
    }

    const sideClicked = (sideName, state = COMPLETELY) => {
        let tempSides = updatedSides;
        let existingSideIndex = -1;

        tempSides.forEach((side, index) => {
            if (side.name === sideName) existingSideIndex = index;
        });

        if ([LEFT, RIGHT].includes(sideName)) {
            // If exists
            if (existingSideIndex !== -1) {
                // Should it be deleted?
                if (state === NONE) {
                    tempSides.splice(existingSideIndex, 1);
                } else {
                    if (currentConfiguration?.options.preset === PILLARS && tempSides[existingSideIndex].type === state) {
                        // Toggle
                        tempSides.splice(existingSideIndex, 1);
                    } else {
                        // If not, the type can possibly change or be set the same as it is
                        tempSides[existingSideIndex].type = state;
                    }

                    // When the side state is partially, the waterlist should be removed if it exists
                    if (state === PARTIALLY) {
                        setWaterlists(waterlists.filter(waterlist => waterlist !== sideName));
                    }
                }
            } else {
                // When does not exist
                if (state !== NONE) {
                    tempSides.push({name: sideName, type: state});
                }
            }
        } else {
            // If exists -> delete
            if (existingSideIndex !== -1) {
                tempSides.splice(existingSideIndex, 1);
            } else {
                // Add side
                tempSides.push({name: sideName, type: state});
            }
        }

        setUpdatedSides([...tempSides]);
    }

    const toggleWaterlist = (side) => {
        const existingWaterlistIndex = waterlists.indexOf(side);

        if (existingWaterlistIndex >= 0) {
            waterlists.splice(existingWaterlistIndex, 1);
        } else {
            waterlists.push(side);
        }

        setWaterlists(waterlists.map(waterlist => waterlist));
    }

    const closeModal = (isSubmitting) => {
        if (isSubmitting) {
            props.onClose(updatedSides, waterlists);
        } else {
            props.onClose();
        }
    }

    const getSideCheckboxSmallText = (sideName, sideState) => {
        let smallText = '';

        if (currentConfiguration?.getFinishedSidesIncludedInPrice()
            .find(finishedSide => finishedSide.name === sideName && finishedSide.type === sideState)) {
            smallText = t(prefix + 'includedInPrice');
        }

        return smallText;
    }

    const getSideIcon = (side) => {
        let image;

        switch (side) {
            case FRONT:
                image = currentConfiguration?.options.preset === PILLARS ? sideRightPillar : sideFront;
                break;
            case BACK:
                image = currentConfiguration?.options.preset === PILLARS ? sideLeftPillar : sideBack;
                break;
            case TOP:
                image = sideTop;
                break;
            case BOTTOM:
                image = sideBottom;
                break;
            case LEFT:
                image = currentConfiguration?.options.preset === PILLARS ? sideFront : sideLeft;
                break;
            case RIGHT:
                image = currentConfiguration?.options.preset === PILLARS ? sideBack : sideRight;
                break;
            default:
                break;
        }

        return image;
    }

    const getSidesToRenderFirst = (preset) => {
        let renderFirst;

        if ([PILLARS, LINTELS].includes(preset)) {
            renderFirst = [
                FRONT,
                TOP,
                BACK,
            ];
        } else {
            renderFirst = [
                FRONT,
                BACK,
                TOP,
                BOTTOM,
            ];
        }

        return renderFirst;
    }

    const getSidesToRenderSecond = (preset) => {
        let renderSecond;

        if (preset === PILLARS) {
            renderSecond = [];
        } else {
            renderSecond = [
                LEFT,
                RIGHT,
            ];
        }

        return renderSecond;
    }

    const renderCheckboxes = () => {
        const renderFirst = getSidesToRenderFirst(currentConfiguration?.options.preset);
        const renderSecond = getSidesToRenderSecond(currentConfiguration?.options.preset);

        const firstElements = renderFirst.map((side, index) => {
            const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);
            let content;

            if (CHISELED_OBJECT_TYPES.includes(currentConfiguration?.options.type) && [TOP, BOTTOM].includes(sideName)) {
                content = `${t(constantsPrefix + sideName)} (${t(prefix + 'chiseledComment')})`
            } else {
                content = t(constantsPrefix + sideName);
            }

            return <CheckboxWithIcon
                key={'firstElements' + index}
                onChange={() => sideClicked(side)}
                content={content}
                checked={sideSelected(side, COMPLETELY)}
                smallText={getSideCheckboxSmallText(side, COMPLETELY)}
                image={getSideIcon(side)}
                disabled={!canEdit}
            >

                {
                    ![TOP, BOTTOM].includes(side) && canAddWaterlists &&
                    <CheckBox
                        content={t(prefix + 'waterlistCheckbox')}
                        checked={waterlists.includes(side)}
                        onChange={() => toggleWaterlist(side)}
                        disabled={!canEdit}
                    />
                }

            </CheckboxWithIcon>
        });

        const secondElements = renderSecond.map((side, index) => {
            const imagePartially = side === LEFT ? sideLeftPartially : sideRightPartially;
            const imageCompletely = getSideIcon(side)
            const sideName = getSideNameByPreset(currentConfiguration?.options.preset, side);
            const disabled = !selectableSides.includes(side) || !canEdit;

            return <div key={'secondElements' + index}>
                <SectionTitle content={t(constantsPrefix + sideName)}/>

                <RadioButton
                    name={side}
                    content={t(prefix + 'finishNone')}
                    smallText={getSideCheckboxSmallText(side, NONE)}
                    checked={sideSelected(side, NONE)}
                    onChange={() => sideClicked(side, NONE)}
                    image={sideNone}
                    disabled={disabled}
                />
                {
                    currentConfiguration?.options.preset !== LINTELS &&
                    <RadioButton
                        name={side}
                        content={t(prefix + 'finishPartially')}
                        smallText={getSideCheckboxSmallText(side, PARTIALLY)}
                        checked={sideSelected(side, PARTIALLY)}
                        onChange={() => sideClicked(side, PARTIALLY)}
                        image={imagePartially}
                        disabled={disabled}
                    />
                }
                <RadioButton
                    name={side}
                    content={t(prefix + 'finishCompletely')}
                    smallText={getSideCheckboxSmallText(side, COMPLETELY)}
                    checked={sideSelected(side, COMPLETELY)}
                    onChange={() => sideClicked(side, COMPLETELY)}
                    image={imageCompletely}
                    disabled={disabled}
                />

                {
                    canAddWaterlists ?
                        <CheckboxWithIcon
                            content={t(prefix + 'waterlistCheckbox')}
                            checked={waterlists.includes(side) && !sideSelected(side, PARTIALLY)}
                            onChange={() => toggleWaterlist(side)}
                            disabled={sideSelected(side, PARTIALLY) || disabled}
                        />
                        : null
                }
            </div>
        })

        return firstElements.concat(secondElements);
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <Modal show={props.isActive} onHide={() => closeModal(false)} animation={true} className="modal">
                <Modal.Header>
                    <Modal.Title>{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{getDescription()}</p>

                    <SectionTitle content=""/>

                    {renderCheckboxes()}
                </Modal.Body>
                <Modal.Footer>
                    {
                        canEdit &&
                        <div className="button button--primary"
                             onClick={() => closeModal(true)}>{t(prefix + 'submitButton')}
                        </div>
                    }
                    <div className="button button--outline"
                         onClick={() => closeModal(false)}>{t(prefix + 'cancelButton')}
                    </div>
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
};

FinishModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    finishedSides: PropTypes.array,
    waterlistSides: PropTypes.array,
    onClose: PropTypes.func.isRequired,
}
