export const CONTINUOUS = 'CONTINUOUS';
export const STOPPING_ROUND = 'STOPPING_ROUND';
export const STOPPING_RIGHT = 'STOPPING_RIGHT';
export const CHISELED = 'CHISELED';
export const LUPATO = 'LUPATO';

export const ALL_GROOVE_TYPES = [
    CONTINUOUS,
    STOPPING_ROUND,
    STOPPING_RIGHT,
    CHISELED,
    LUPATO,
];

export const BUSH_HAMMERED_TYPES = [
    CONTINUOUS,
    STOPPING_ROUND,
    STOPPING_RIGHT,
];

export const CHISELED_TYPES = [
    CHISELED,
];

export const LUPATO_TYPES = [
    LUPATO,
];
