import * as React from 'react';
import PropTypes from 'prop-types';
import {ActionsDropdownItem} from "../../dropdowns/items/ActionsDropdownItem";
import {DANGER} from "../../../constants/Variants";
import {ActionsDropdown} from "../../dropdowns/ActionsDropdown";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";

export const ConfigurationActionsDropdown = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'lists.items.sideBarListItem.';

    return (
        <ActionsDropdown dataCy="configurationActionsDropdown-dropdownToggle">
            <ActionsDropdownItem content={t(prefix + 'duplicate')} disabled/>
            <ActionsDropdownItem
                dataCy="configurationActionsDropdown-editButton"
                content={t(prefix + 'edit')} onClick={() => props.onUpdate()}
            />
            <ActionsDropdownItem
                dataCy="configurationActionsDropdown-deleteButton"
                content={t(prefix + 'delete')}
                variant={DANGER}
                onClick={() => props.onDelete()}
            />
        </ActionsDropdown>
    );
}

ConfigurationActionsDropdown.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}
