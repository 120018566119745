import {FINISHED_SIDE, OPERATIONS_TO_DRAW_2D_IMAGES, WATERLIST} from "../../constants/OperationTypes";
import {FRONT, INTERSECTION, TOP} from "../../constants/CameraAngles";
import {BACK, LEFT} from "../../constants/ObjectSides";
import {
    REAR_HEEL_CUSTOM,
    REAR_HEEL_TYPES,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7,
    TYPE_8
} from "../../constants/ObjectTypes";
import {cloneDeep, isEqual} from "lodash";
import i18n from "../../i18n";
import {getSideNameByPreset} from "./ObjectHelper";
import {DIMENSION_TEXT_FONT_SIZE} from "../../constants/MeasurementLineConstants";
import {connectedImageType, generalImageType} from "../services/ImageCreator";
import {UPRIGHT_PRESETS} from "../../constants/Presets";
import {getAspectsForOperationByPreset} from "./AspectHelper";

const specialTypes = [
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7,
    TYPE_8,
]

export class ImageHelper2D {
    constructor() {
        this.intersectionsDrawn = [];
    }

    removeStandardPieces(configuration) {
        if (specialTypes.includes(configuration.options.type)) {
            configuration.pieces = configuration.pieces.filter(piece => {
                const shouldDrawIntersection = this.__shouldDrawIntersection(configuration, piece);
                // TODO: Is dit hier nodig? Bespreken volgende meeting (geschreven op 09/06/21)
                // const hasStandardDimensions = compareDimensions(
                //     getStandardMeasurementsByType(configuration.options.type, configuration.options.height),
                //     removeBaseDimensionsFromSpecialType(configuration.options.type, piece.dimensions)
                // );
                // if (!hasStandardDimensions && shouldDrawIntersection)

                if (shouldDrawIntersection) {
                    this.intersectionsDrawn.push(removeBaseDimensionsFromSpecialType(configuration.options.type, piece.dimensions));

                    return true;
                }


                return shouldDrawPiece(configuration, piece);
            })
        } else {
            configuration.pieces = configuration.pieces.filter(piece => shouldDrawPiece(configuration, piece));
        }

        return configuration;
    }

    addIntersection(intersection) {
        this.intersectionsDrawn.push(intersection);
    }

    resetIntersections() {
        this.intersectionsDrawn = [];
    }

    __shouldDrawIntersection(configuration, piece) {
        let shouldDraw = true;

        // Always draw intersection of type 7 and 8
        if (![TYPE_7, TYPE_8].includes(configuration.options.type)) {
            let intersectionDimensions = removeBaseDimensionsFromSpecialType(configuration.options.type, piece.dimensions);

            this.intersectionsDrawn.forEach(dimensions => {
                if (JSON.stringify(intersectionDimensions) === JSON.stringify(dimensions)) {
                    shouldDraw = false;
                }
            });
        }

        return shouldDraw;
    }

}

export function removeBaseDimensionsFromSpecialType(type, dimensions) {
    let updatedDimensions = Object.assign({}, dimensions);

    if (!REAR_HEEL_TYPES.includes(type)) {
        delete updatedDimensions.width;
        delete updatedDimensions.height;
    }

    delete updatedDimensions.length;

    return updatedDimensions;
}

export function getAspectsToDraw({preset, type}, piece) {
    let aspectsToDraw = [];

    piece.operations.forEach(operation => {
        getAspectsForOperationByPreset(preset, operation).forEach(aspect => {
            if (!aspectsToDraw.includes(aspect)) aspectsToDraw.push(aspect);
        })
    });

    if (type === REAR_HEEL_CUSTOM && !aspectsToDraw.includes(LEFT)) {
        aspectsToDraw.push(LEFT);
    }

    return aspectsToDraw;
}

export function getImageName(configurationName, preset, pieceName, aspect) {
    let name;
    const aspectTranslation = getAspectTranslation(aspect, preset);

    if (configurationName) name = configurationName;
    if (pieceName) {
        if (name) name += ` - ${pieceName}`;
        else name = pieceName;
    }
    if (aspectTranslation) {
        if (name) name += ` - ${aspectTranslation}`;
        else name = aspectTranslation;
    }

    return name;
}

export function removeDefaultOperations(configuration, piece) {
    const pieceToRemoveFrom = cloneDeep(piece);
    const standardFinishedSides = configuration.getStandardFinishedSides();
    const standardWaterlists = configuration.getStandardWaterlists();

    pieceToRemoveFrom.operations = pieceToRemoveFrom.operations.filter(operation => {
        let shouldPass = true;

        if (operation.type === FINISHED_SIDE) {
            standardFinishedSides.forEach(side => {
                if (side.name === operation.side && side.type === operation.additionalDimension.type) {
                    shouldPass = false;
                }
            });
        }

        if (shouldPass && operation.type === WATERLIST) {
            standardWaterlists.forEach(waterlist => {
                if (operation.side === waterlist) shouldPass = false;
            });
        }

        return shouldPass;
    });

    return pieceToRemoveFrom;
}

export function calculateFontSizeByObjectLength(length) {
    let factor;

    if (length <= 120) {
        factor = 1
    } else if (length > 120 && length <= 180) {
        factor = 1.4;
    } else if (length > 180 && length <= 240) {
        factor = 1.8;
    } else if (length > 240 && length <= 300) {
        factor = 3;
    } else if (length > 300 && length <= 400) {
        factor = 3.8;
    } else if (length > 400 && length <= 500) {
        factor = 4.8;
    } else if (length > 500 && length <= 600) {
        factor = 5.6;
    } else if (length > 600 && length <= 700) {
        factor = 6.4;
    } else if (length > 700 && length <= 800) {
        factor = 7.2;
    } else if (length > 800 && length <= 900) {
        factor = 8;
    } else {
        factor = length / 100;
    }

    return DIMENSION_TEXT_FONT_SIZE * factor;
}

export function calculateFontSizeByZoom(zoom) {
    if (!zoom) return null;

    return DIMENSION_TEXT_FONT_SIZE / zoom * 10;
}

export function addPromiseToSortedList(promiseList, configuration, promise, imageType) {
    if (!promiseList[configuration.id]) {
        promiseList[configuration.id] = {
            general: [],
            hall2: [],
            hall3: [],
            hall4: [],
            connected: [],
        }
    }

    if (promiseList[configuration.id][imageType]) {
        promiseList[configuration.id][imageType].push(promise);
    } else {
        promiseList[configuration.id][imageType] = [promise];
    }

    return promiseList;
}

export function getSortedImageObjects(promiseList) {
    let promises = [];

    promiseList.forEach(promiseObject => {
        let afs = {};

        const callBack = (propertyName, images) => {
            afs[propertyName] = images;
        };

        const promise = Promise.all(promiseObject.general).then((images) => callBack(generalImageType, images))
            .then(() => Promise.all(promiseObject.hall2).then((images) => callBack('hall2', images)))
            .then(() => Promise.all(promiseObject.hall3).then((images) => callBack('hall3', images)))
            .then(() => Promise.all(promiseObject.hall4).then((images) => callBack('hall4', images)))
            .then(() => Promise.all(promiseObject.connected).then((images) => callBack(connectedImageType, images)))
            .then(() => {
                return afs;
            });

        promises.push(promise);
    });

    return Promise.all(promises);
}

export function getAspectByPreset(preset, aspect) {
    let updatedAspect;

    if (UPRIGHT_PRESETS.includes(preset)) {
        if ([FRONT, TOP].includes(aspect)) updatedAspect = BACK;
    }

    if (!updatedAspect) updatedAspect = aspect;

    return updatedAspect;
}

function shouldDrawPiece(configuration, piece) {
    const sort = (sideA, sideB) => {
        let textA = sideA.name;
        let textB = sideB.name;

        let result;

        if (textA < textB) result = -1;
        else if (textA > textB) result = 1;
        else result = 0;

        return result;
    }

    const hasStandardFinishedSides = isEqual(piece.getFinishedSides.sort(sort), configuration.getFinishedSidesIncludedInPrice().sort(sort));
    const hasStandardWaterlists = isEqual(
        piece.waterlists.map(operation => operation.side).sort(sort),
        configuration.getStandardWaterlists().sort(sort)
    );
    const hasOperationsToDraw = piece.operations.some(operation => OPERATIONS_TO_DRAW_2D_IMAGES.includes(operation.type));

    return hasOperationsToDraw || !hasStandardFinishedSides || !hasStandardWaterlists;
}

function getAspectTranslation(aspect, preset) {
    if (!aspect) return '';

    let translation;

    if (aspect === LEFT) {
        translation = i18n.t('app:constants.cameraAngles.' + INTERSECTION);
    } else {
        translation = i18n.t('app:constants.cameraAngles.' + getSideNameByPreset(preset, aspect));
    }

    return translation;
}
