import offerReducer from "./offerReducer";
import generalReducer from "./generalReducer";
import orderReducer from "./orderReducer";
import manufacturerReducer from "./manufacturerReducer";
import galleryImageReducer from "./galleryImageReducer";
import companyReducer from "./companyReducer";
import outdoorShopReducer from "./outdoorShopReducer";
import {combineReducers} from 'redux'
import configuratorReducer from "./configuratorReducer";

const rootReducer = (combineReducers)({
    generalReducer,
    offerReducer: offerReducer,
    orderReducer,
    manufacturerReducer,
    galleryImageReducer,
    companyReducer,
    outdoorShopReducer,
    configuratorReducer,
});

export default rootReducer;
