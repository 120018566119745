import * as React from 'react';
import PropTypes from 'prop-types';
import {LIGHT, SUCCESS} from "../../constants/Variants";

export const AppHeaderDropDown = (props) => {
    const getStyle = () => {
        const defaultStyle = 'button button--icon dropdown-toggle';

        return props.variant === SUCCESS ? `${defaultStyle} button--success` : defaultStyle;
    }

    return (
        <div className={'dropdown m-r-1'} data-cy={props.dataCy ? props.dataCy : "appHeaderDropDown"}>
            <button className={getStyle()} type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <i className="material-icons">{props.icon}</i> <span className="m-l-1">{props.content}</span>
            </button>

            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                {props.children}
            </div>
        </div>
    );
};

AppHeaderDropDown.propTypes = {
    content: PropTypes.string,
    variant: PropTypes.string,
    icon: PropTypes.string,
    dataCy: PropTypes.string
}

AppHeaderDropDown.defaultProps = {
    variant: LIGHT,
    icon: 'more_vert',
}
