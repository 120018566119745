import * as React from 'react';
import PropTypes from 'prop-types';
import {Piece} from "../../../classes/models/Piece";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";

export const PieceItem = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.configurationList.';

    const renderDetailsIcon = () => {
        const iconName = props.showDetails ? 'indeterminate_check_box' : 'add_box';

        return <i
            className="material-icons pointer"
            data-tip={t(prefix + 'detailsToolTip')}
            onClick={() => props.toggleDetails()}>
            {iconName}
        </i>
    }

    const renderOperationsCount = () => {
        let content;
        const count = props.piece.operations.length;

        if (count === 1) {
            content = `(${count} ${t(prefix + 'operationsSingular')})`;
        } else {
            content = `(${count} ${t(prefix + 'operationsPlural')})`;
        }

        return content;
    }

    return (
        <tr className="offer__table__level1">
            <td className="pointer" onClick={() => props.toggleDetails()}>
                <div className="offer__table__content">
                    {renderDetailsIcon()}
                </div>
            </td>
            <td>
                <small>
                    {`${props.piece.getSummary()} ${renderOperationsCount()}`}
                </small>
            </td>
            <td width="1">{`${t(prefix + 'amount')} ${props.piece.amount}x`}</td>
            <td>€ {props.piece.price}</td>
            <td>
                {
                    props.allowChanges ?
                        <span className="button button--icon button--small" data-tip={t(prefix + 'editToolTip')}
                              onClick={() => props.editPiece()}>
                                <i className="material-icons">edit</i>
                        </span>
                        : null
                }
            </td>
        </tr>
    );
};

PieceItem.propTypes = {
    piece: PropTypes.instanceOf(Piece).isRequired,
    editPiece: PropTypes.func.isRequired,
    toggleDetails: PropTypes.func.isRequired,
    showDetails: PropTypes.bool.isRequired,
    allowChanges: PropTypes.bool,
}

