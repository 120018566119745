import * as React from 'react';
import PropTypes from 'prop-types';
import {ActionsDropdown} from "../../../dropdowns/ActionsDropdown";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../../constants/TranslationConstants";
import {ActionsDropdownItem} from "../../../dropdowns/items/ActionsDropdownItem";
import {DropdownDivider} from "../../../headers/DropdownDivider";
import {DANGER} from "../../../../constants/Variants";
import {EmptyPage} from "../../../empty/EmptyPage";
import {OPEN} from "../../../../constants/Statuses";
import moment from "moment";

export const DeliveryDetail = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.admin.deliveries.';
    const statusPrefix = 'constants.statuses.';

    const getSubTextStyling = (status) => {
        const defaultStyling = 'list__item__content--subtext';

        const color = status === OPEN ? 'color--green' : 'color--red';

        return `${defaultStyling} ${color}`;
    }

    return (
        <>
            {
                props.deliveryType.dates.length > 0 ?
                    <div className="p-l-4 p-r-4 m-b-5">
                        {
                            props.deliveryType.dates.map(date => {
                                return <div key={`dateList${date.id}`}
                                            className="list__item list__item--bordered pointer">
                                    <div
                                        key={`deliveryDate${date.id}`}
                                        className="list__item__content"
                                        onClick={() => props.onDateClick(date.id)}>
                                        {date.getReadableDate()}
                                        <span className={getSubTextStyling(date.status)}>
                                            {t(statusPrefix + date.status)}
                                        </span>
                                        <small className="list__item__content--subtext m-t-1">
                                            {`${t(prefix + 'updatedAtLabel')} ${moment(date.updatedAt).format('YYYY-MM-DD HH:mm')}`}
                                        </small>
                                    </div>

                                    <ActionsDropdown>
                                        <ActionsDropdownItem
                                            content={t(prefix + 'editActionButton')}
                                            onClick={() => props.onDateClick(date.id)}
                                        />
                                        <DropdownDivider/>
                                        <ActionsDropdownItem
                                            content={t(prefix + 'deleteActionButton')}
                                            onClick={() => props.onDeleteClick(date.id)}
                                            variant={DANGER}
                                        />
                                    </ActionsDropdown>
                                </div>
                            })
                        }
                    </div>
                    :
                    <EmptyPage
                        title={t(prefix + 'emptyPageTitle')}
                        content={t(prefix + 'emptyPageContent')}
                        buttonText={t(prefix + 'emptyPageButtonText')}
                        onButtonClick={() => props.onDateClick()}
                    />
            }
        </>
    );
}

DeliveryDetail.propTypes = {
    deliveryType: PropTypes.object.isRequired,
    onDateClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
}
