import produce from 'immer';
import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
    status: null,
    configurableType: null,
};

function configuratorReducer(state = initialState, action) {
    return produce(state, draftState => {
        switch (action.type) {
            case ActionTypes.SET_CONFIGURATOR_STATUS:
                draftState.status = action.status;
                break;
            case ActionTypes.SET_CONFIGURABLE_TYPE:
                draftState.configurableType = action.configurableType;
                break;
            default:
                break;
        }
    });

}

export default configuratorReducer;
