import {Operation} from '../../internal';
import * as OperationTypes from '../../constants/OperationTypes';
import {Vector3} from "three";
import {FRONT} from "../../constants/ObjectSides";

class NotchOperation extends Operation {
    constructor(id, side = FRONT, notchType = 'D', dimensions = {
        length: 0,
        width: 0,
        distance: 0
    }, position = new Vector3(), isFinished = false) {
        super(id, OperationTypes.NOTCH, dimensions, position, isFinished);
        // Lengte or Dikte, so L or D
        this.notchType = notchType;
        this.side = side;
    }
}

export {NotchOperation};