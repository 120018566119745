import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {SectionTitle} from "../../headers/SectionTitle";
import {MaterialQualityList} from "../../lists/MaterialQualityList";
import {Message} from "../../messages/Message";
import {WARNING} from "../../../constants/Variants";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {BLUESTONE as BLUESTONE_QUALITIES, EXCEPTIONAL, NORMAL} from "../../../constants/MaterialQualities";
import {CUSHIONS, LINTELS, PILLARS, REAR_HEELS, SILLS_STOCK} from "../../../constants/Presets";
import {
    TYPE_1,
    TYPE_10,
    TYPE_2,
    TYPE_3,
    TYPE_4,
    TYPE_5,
    TYPE_6,
    TYPE_7,
    TYPE_8,
    TYPE_9
} from "../../../constants/ObjectTypes";
import {BLUESTONE} from "../../../constants/Materials";
import {useSelector} from "react-redux";
import {DEBASING_ROUGH} from "../../../constants/OperationTypes";
import {shouldHaveExceptionalQuality} from "../../../classes/helpers/OperationHelper";

export const QualitySelect = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.configurationModal.';

    const [showWarning, setShowWarning] = useState(false);
    const [qualities, setQualities] = useState([]);
    const [disabledQualities, setDisabledQualities] = useState([]);

    const {currentConfiguration} = useSelector(state => state.offerReducer);

    let qualityList = [];

    useEffect(() => {
        if (props.isVisible && props.selectedOptions.material === BLUESTONE) {
            setAvailableQualities();
        }

        let disabledQualityList = getDisabledQualities();

        if (disabledQualityList.includes(props.selectedOptions.quality) && qualities.length > disabledQualityList.length) {
            qualities.forEach(quality => {
                if (!disabledQualities.includes(quality)) {
                    props.setQuality(quality);
                }
            })
        }

        setDisabledQualities(disabledQualityList);
    }, [props.selectedOptions]);

    useEffect(() => {
        setShowWarning(qualities.length > 1 && props.selectedOptions.quality === EXCEPTIONAL);
    }, [props.selectedOptions.quality, qualities]);

    const getDisabledQualities = () => {
        const disabled = [];

        const shouldBeExceptionalWhenDebasing = shouldHaveExceptionalQuality(props.selectedOptions.type, props.selectedOptions.height);
        const configurationHasDebasing = props.isEditing && currentConfiguration.pieces.some(piece => {
            return piece.operations.some(operation => operation.type === DEBASING_ROUGH);
        });

        if (shouldBeExceptionalWhenDebasing && configurationHasDebasing) {
            disabled.push(NORMAL);
        }

        return disabled;
    }

    const removeNormal = () => {
        qualityList = qualityList.filter(quality => quality !== NORMAL);
        selectRemainingQuality(NORMAL);
    };

    const removeExceptional = () => {
        qualityList = qualityList.filter(quality => quality !== EXCEPTIONAL);
        selectRemainingQuality(EXCEPTIONAL);
    };

    const selectRemainingQuality = (removedQuality) => {
        if (props.selectedOptions.quality === removedQuality || !props.selectedOptions.quality) {
            if (qualityList.length > 0) {
                props.setQuality(qualityList[0]);
            } else {
                props.setQuality(null);
            }
        }
    }

    const setAvailableQualities = () => {
        qualityList = BLUESTONE_QUALITIES;
        if (props.selectedOptions.height == null || props.selectedOptions.height === '') return;

        if ([REAR_HEELS, CUSHIONS].includes(props.selectedOptions.preset)) {
            removeExceptional();
        } else {
            if ([TYPE_1, TYPE_2, TYPE_3, TYPE_4].includes(props.selectedOptions.type) && props.selectedOptions.height < 6) {
                removeExceptional();
            }

            if (props.selectedOptions.type === TYPE_5 || props.selectedOptions.type === TYPE_6) {
                if (props.selectedOptions.height < 6) removeExceptional();
                if (props.selectedOptions.height < 3 || props.selectedOptions.height > 5) removeNormal();
            }

            if ((props.selectedOptions.type === TYPE_7 || props.selectedOptions.type === TYPE_8)) {
                if (props.selectedOptions.height < 6) removeExceptional();
                if (props.selectedOptions.height < 4 || props.selectedOptions.height > 5) removeNormal();
            }

            if ([TYPE_9, TYPE_10].includes(props.selectedOptions.type)) {
                removeExceptional();
            }
        }

        if ([LINTELS, PILLARS].includes(props.selectedOptions.preset)) {
            if (props.selectedOptions.height >= 6) removeNormal();
        }

        if (props.selectedOptions.subPreset === SILLS_STOCK) {
            removeExceptional();
        }

        setQualities(qualityList);
    }

    return (
        <>
            {
                !!props.isVisible &&
                <>
                    <SectionTitle
                        content={t(prefix + 'materialQualityTitle')}
                    />
                    <MaterialQualityList
                        qualities={qualities}
                        selectedQuality={props.selectedOptions.quality}
                        onSelect={(quality) => props.setQuality(quality)}
                        disabledQualities={disabledQualities}
                    />
                    {showWarning && <Message content={t('messages.extraCostsQuality')} variant={WARNING}/>}
                </>
            }
        </>
    );
};

QualitySelect.propTypes = {
    isVisible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    selectedOptions: PropTypes.object,
    setQuality: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
}
