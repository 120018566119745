import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";
import {ConfigurationItem} from "./items/ConfigurationItem";
import {PieceItem} from "./items/PieceItem";
import {OperationItem} from "./items/OperationItem";
import {getUniqueOperations} from "../../classes/helpers/OperationHelper";
import {setCurrentConfiguration, setCurrentPiece} from "../../actions/OfferActions";
import history from "../../config/history";
import {useDispatch, useSelector} from "react-redux";
import {ConfirmationModal} from "../modals/ConfirmationModal";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {ConfigurationService} from "../../classes/services/ConfigurationService";
import {addAlertMessage, setShouldUpdatePrice, setWindowIsLoading} from "../../actions/GeneralActions";
import {DANGER, SUCCESS} from "../../constants/Variants";
import ConfigurableReducerHelper from "../../classes/helpers/ConfigurableReducerHelper";
import {parseToCommaSeparated} from "../../classes/helpers/StringHelper";
import {OFFER, ORDER} from "../../constants/ConfigurableTypes";
import {ConfigurationOperationItem} from "./items/ConfigurationOperationItem";
import {useLocation} from "react-router-dom";
import {ORDERS_PATH_NAME} from "../../constants/PathNames";

export const ConfigurationList = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.configurationList.';
    const materialsPrefix = 'constants.materials.';
    const materialColorsPrefix = 'constants.materialColors.';

    const [showDetails, setShowDetails] = useState(false);
    const [pieceDetailsToShow, setPieceDetailsToShow] = useState([]);
    const [deleteConfigurationModalIsActive, setDeleteConfigurationModalIsActive] = useState(false);
    const [configurationToDelete, setConfigurationToDelete] = useState(null);
    const [isViewingOrder, setIsViewingOrder] = useState(false);

    const {currentOffer, currentConfiguration} = useSelector(state => state.offerReducer);
    const currentOrder = useSelector(state => state.orderReducer.currentOrder);
    const {priceIsLoading, canEdit} = useSelector(state => state.generalReducer);
    const {configurableType} = useSelector(state => state.configuratorReducer);
    const dispatch = useDispatch();

    const location = useLocation();

    const configurationService = new ConfigurationService();
    const configurableReducerHelper = new ConfigurableReducerHelper();

    useEffect(() => {
        setIsViewingOrder(location.pathname.includes(ORDERS_PATH_NAME));
    }, [location.pathname]);

    const deleteConfiguration = (configuration) => {
        dispatch(setWindowIsLoading(true));

        configurationService.delete(configuration.id)
            .then(response => {
                if (response.success) {
                    configurableReducerHelper.deleteConfiguration(
                        location.pathname.includes('offer') ? currentOffer : currentOrder,
                        configuration
                    );

                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'deleteConfigurationSuccess')));
                    dispatch(setShouldUpdatePrice(true));
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'deleteConfigurationFailed')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const goToConfigurator = (configuration, activePiece = null) => {
        if (activePiece) dispatch(setCurrentPiece(activePiece));

        const id = isViewingOrder ? currentOrder.id : currentOffer.id;
        const typeParameter = isViewingOrder ? ORDER : OFFER;

        if (currentConfiguration) dispatch(setCurrentConfiguration());

        history.push(`/configurator/${typeParameter}/${id}/${configuration.id}`);
    }

    const openDeleteConfigurationModal = (configuration) => {
        setConfigurationToDelete(configuration);

        setDeleteConfigurationModalIsActive(true);
    }

    const closeDeleteConfigurationModal = (accepted) => {
        if (accepted) {
            deleteConfiguration(configurationToDelete);
        }

        setConfigurationToDelete(null);
        setDeleteConfigurationModalIsActive(false);
    }

    const togglePieceDetails = (id) => {
        if (pieceDetailsToShow.includes(id)) {
            setPieceDetailsToShow(pieceDetailsToShow.filter(pieceId => pieceId !== id));
        } else {
            setPieceDetailsToShow(pieceDetailsToShow.concat([id]));
        }
    }

    const renderMaterial = (piece, {material, materialColor}) => {
        if (!pieceDetailsToShow.includes(piece.id)) return [];

        const summary = `${t(materialsPrefix + material)} - ${t(materialColorsPrefix + materialColor)}`;

        return <tr className="offer__table__level2">
            <td>
                <i className="material-icons">subdirectory_arrow_right</i> {t(prefix + 'material')}
            </td>
            <td colSpan="2">
                <small>{summary}</small>
            </td>
            <td>€ {parseToCommaSeparated(piece.materialPrice, 2)}</td>
            <td colSpan={2}/>
        </tr>
    }

    const renderOperations = ({id, operations}, configuration) => {
        if (!pieceDetailsToShow.includes(id)) return [];

        return getUniqueOperations(operations).map(operation => {
            return <OperationItem
                key={`operationId${operation.id}`}
                operation={operation}
                configurationOperations={configuration.operations}
            />
        });
    }

    const renderPrice = () => {
        if (priceIsLoading) return '...';

        let price = 0;

        if (currentOffer) {
            price = currentOffer.price;
        }

        if (currentOrder) {
            price = currentOrder.price;
        }

        if (price) {
            return `€ ${price}`;
        }
    }

    const renderButtons = () => {
        if (canEdit) {
            return [
                <tr key={1}>
                    <td colSpan="5" className="offer__table__add">
                        <span className="m-r-1" onClick={() => props.openConfigurationModal()}>
                            <span className="button button--success">
                                <i className="material-icons">add</i> {t(prefix + 'addConfigurationButton')}
                            </span>
                        </span>
                        <button className="button button--icon-before" type="button" disabled>
                            <i className='material-icons'>cloud_upload</i>
                            {t(prefix + 'uploadPlanButton')}
                        </button>
                    </td>
                </tr>,
                <tr key={2}>
                    <td colSpan="5" className="offer__table__divider">&nbsp;</td>
                </tr>
            ];
        }
    }

    if (props.configurations.length === 0) return null

    return (
        <div className="offer">
            <ConfirmationModal
                isActive={deleteConfigurationModalIsActive}
                onClose={(accepted) => closeDeleteConfigurationModal(accepted)}
                content={t(prefix + 'deleteModalContent')}
            />

            <div className="offer__table__holder">
                <table className="offer__table">
                    <tbody>
                    {
                        props.configurations.map((configuration) => {
                            return <React.Fragment key={`configuration${configuration.id}`}>
                                <ConfigurationItem
                                    configuration={configuration}
                                    editConfiguration={() => goToConfigurator(configuration)}
                                    deleteConfiguration={() => openDeleteConfigurationModal(configuration)}
                                    toggleDetails={() => setShowDetails(!showDetails)}
                                    allowChanges={canEdit && !isViewingOrder}
                                />
                                {
                                    !!configuration.operations.length && configuration.operations.map(operationData => {
                                        return <ConfigurationOperationItem
                                            key={`${operationData.code}`}
                                            amount={operationData.amount}
                                            price={operationData.price}
                                            type={operationData.type}
                                        />
                                    })
                                }
                                {
                                    configuration.pieces.map((piece) => {
                                        return <React.Fragment key={`pieceFragment${piece.id}`}>
                                            <PieceItem
                                                piece={piece}
                                                editPiece={() => goToConfigurator(configuration, piece)}
                                                showDetails={pieceDetailsToShow.includes(piece.id)}
                                                toggleDetails={() => togglePieceDetails(piece.id)}
                                                allowChanges={canEdit && !isViewingOrder}
                                            />
                                            {renderMaterial(piece, configuration.options)}
                                            {renderOperations(piece, configuration)}
                                        </React.Fragment>
                                    })
                                }
                                <tr>
                                    <td colSpan="5" className="offer__table__divider">&nbsp;</td>
                                </tr>
                            </React.Fragment>
                        })
                    }

                    <tr className="offer__table__summary">
                        <td><strong>{t(prefix + 'priceTotal')}</strong></td>
                        <td/>
                        <td>{renderPrice()}</td>
                        <td colSpan={2}/>
                    </tr>

                    </tbody>
                </table>

                <ReactTooltip/>

            </div>
        </div>
    );
};

ConfigurationList.propTypes = {
    configurations: PropTypes.array.isRequired,
    openConfigurationModal: PropTypes.func,
}

ConfigurationList.defaultProps = {
    showAmount: true
}
