import React, {Fragment, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {useDispatch, useSelector} from 'react-redux';
import {SectionTitle} from '../headers/SectionTitle';
import * as ObjectSides from '../../constants/ObjectSides';
import {ConfirmationModal} from './ConfirmationModal';
import {DANGER, SUCCESS} from '../../constants/Variants';
import {OperationService} from '../../classes/services/OperationService';
import {addAlertMessage, setShouldUpdatePrice, setWindowIsLoading} from '../../actions/GeneralActions';
import {Operation} from '../../classes/models/Operation';
import ConfigurableReducerHelper from '../../classes/helpers/ConfigurableReducerHelper';
import {CheckboxWithIcon} from "../forms/inputs/CheckboxWithIcon";
import {useForm} from 'react-hook-form';
import {RadioButton} from "../forms/inputs/RadioButton";
import {ValidatedInput} from "../forms/inputs/ValidatedInput";
import {normalizeConstantString, parseNumber} from "../../classes/helpers/StringHelper";
import {GLUED_CUSHION, PROHIBITED_OPERATION_TYPES} from "../../constants/OperationTypes";
import {ModalHolder} from "./ModalHolder";
import {sortBySide} from "../../classes/helpers/SortingHelper";

function GluedCushionModal(props) {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.gluedCushionModal.';
    const constantsPrefix = 'constants.objectSides.';

    const operationService = new OperationService();
    const configurableReducerHelper = new ConfigurableReducerHelper();

    const {errors, register, handleSubmit} = useForm();

    const [gluedCushions, setGluedCushions] = useState([]);
    const [confirmationModalIsActive, setConfirmationModalIsActive] = useState(false);
    const [availableSides, setAvailableSides] = useState([]);
    const [leftCushionHorizontalSide, setLeftCushionHorizontalSide] = useState(ObjectSides.FRONT);
    const [rightCushionHorizontalSide, setRightCushionHorizontalSide] = useState(ObjectSides.FRONT);

    const {currentConfiguration, currentPiece} = useSelector(state => state.offerReducer);
    const canEdit = useSelector(state => state.generalReducer.canEdit);

    const dispatch = useDispatch();

    useEffect(() => {
        determineUnavailableSides();

        if (currentPiece) {
            setGluedCushions(
                currentPiece.getOperationsByType(GLUED_CUSHION)
            )
        }
    }, [props.isActive]);

    const updateGluedCushion = (fields) => {
        const gluedCushionOperations = gluedCushions.map(gluedCushion => {
            if (gluedCushion.side === ObjectSides.LEFT) {
                gluedCushion = createOperation(gluedCushion.side, fields.widthLeft, fields.heightLeft, fields.lengthLeft);
            }
            if (gluedCushion.side === ObjectSides.RIGHT) {
                gluedCushion = createOperation(gluedCushion.side, fields.widthRight, fields.heightRight, fields.lengthRight);
            }

            return gluedCushion;
        });

        dispatch(setWindowIsLoading(true));

        operationService.updateGluedCushions(currentConfiguration.id, currentPiece, gluedCushionOperations)
            .then(response => {
                if (response.success) {
                    currentPiece.operations = currentPiece.operations.filter(operation => operation.type !== GLUED_CUSHION);
                    currentPiece.operations = currentPiece.operations.concat(response.data);

                    configurableReducerHelper.updatePiece(currentPiece, currentConfiguration);
                    dispatch(setShouldUpdatePrice(true));
                    props.onClose(true);
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'gluedCushionUpdateFailed')));
                throw error;
            }).finally(() => dispatch(setWindowIsLoading(false)));
    }

    const deleteGluedCushion = () => {
        dispatch(setWindowIsLoading(true));

        operationService.updateGluedCushions(currentConfiguration.id, currentPiece, [])
            .then(data => {
                if (data) {
                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'gluedCushionDeleteSuccess')));
                    currentPiece.operations = currentPiece.operations.filter(operation => operation.type !== GLUED_CUSHION);
                    configurableReducerHelper.updatePiece(currentPiece);

                    dispatch(setShouldUpdatePrice(true));

                    props.onClose(true);
                } else {
                    throw Error(data.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'gluedCushionDeleteFailed')));
                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const setChecked = (verticalSide, horizontalSide) => {
        if (verticalSide === ObjectSides.LEFT) {
            setLeftCushionHorizontalSide(horizontalSide);
        } else {
            setRightCushionHorizontalSide(horizontalSide);
        }
    }

    const isChecked = (verticalSide, horizontalSide) => {
        let checked;

        if (verticalSide === ObjectSides.LEFT) {
            checked = leftCushionHorizontalSide === horizontalSide;
        } else {
            checked = rightCushionHorizontalSide === horizontalSide;
        }

        return checked;
    }

    const closeModal = (fields) => {
        if (!fields) {
            props.onClose();
            return;
        }

        updateGluedCushion(fields);
    };

    const sideSelected = (name) => {
        const index = gluedCushions?.findIndex(side => side.side === name);
        return index > -1;
    }

    const sideClicked = (sideName) => {
        let tempGluedCushions = gluedCushions;

        const existingSideIndex = tempGluedCushions?.findIndex(gluedCushion => gluedCushion.side === sideName);

        // If exists -> delete
        if (existingSideIndex !== -1) {
            tempGluedCushions.splice(existingSideIndex, 1);
        } else {
            // Add side
            tempGluedCushions.push(createOperation(sideName, 0, 0, 0));
        }
        setGluedCushions([...tempGluedCushions]);
    }

    const determineUnavailableSides = () => {
        if (currentPiece) {
            setAvailableSides(
                currentPiece.getAvailableSides(PROHIBITED_OPERATION_TYPES.GLUED_CUSHION)
            );
        }
    }

    const renderCheckboxes = () => {
        const renderSides = [
            ObjectSides.LEFT,
            ObjectSides.RIGHT,
        ];

        return renderSides.map((side, index) => {
            return <div key={index}>
                <CheckboxWithIcon
                    dataCy={`gluedCushionModal-sideSelect-${side.toLowerCase()}`}
                    onChange={() => sideClicked(side)}
                    content={t(constantsPrefix + side)}
                    checked={sideSelected(side)}
                    disabled={!availableSides.includes(side) || !canEdit}
                />

                {
                    sideSelected(side) ?
                        <div>
                            <RadioButton
                                dataCy={`gluedCushionModal-sideSelect-${side.toLowerCase()}-front`}
                                name={side + 'FrontRadioButton'}
                                checked={isChecked(side, ObjectSides.FRONT)}
                                className="m-l-2"
                                content={t(prefix + 'frontRadioButtonContent')}
                                onChange={() => setChecked(side, ObjectSides.FRONT)}
                                disabled={!canEdit}
                            />

                            <RadioButton
                                dataCy={`gluedCushionModal-sideSelect-${side.toLowerCase()}-back`}
                                name={side + 'BackRadioButton'}
                                checked={isChecked(side, ObjectSides.BACK)}
                                className="m-l-2"
                                content={t(prefix + 'backRadioButtonContent')}
                                onChange={() => setChecked(side, ObjectSides.BACK)}
                                disabled={!canEdit}
                            />
                        </div>
                        : null
                }
            </div>
        });
    }

    const renderInputFields = (operation, index) => {
        const title = operation.side === ObjectSides.LEFT ? t(prefix + 'dimensionLeftTitle') : t(prefix + 'dimensionRightTitle');
        const capitalizedSide = normalizeConstantString(operation.side, true);

        const barWidth = currentPiece?.dimensions.barWidth ? currentPiece?.dimensions.barWidth : 0

        // Used index as key because there could be two new cushions which both have id 0
        return <Fragment key={index}>
            <SectionTitle
                content={title}
            />

            <div className="row">
                <div className="col-4">
                    <ValidatedInput
                        dataCy={`gluedCushionModal-${operation.side.toLowerCase()}-length`}
                        register={register}
                        error={errors['length' + capitalizedSide]}
                        name={'length' + capitalizedSide}
                        label={t(prefix + 'lengthLabel')}
                        placeholder={t(prefix + 'lengthPlaceholder')}
                        value={operation.dimensions.width}
                        required={true}
                        min={1}
                        max={currentPiece?.dimensions.width - barWidth}
                        disabled={!canEdit}
                    />
                </div>
                <div className="col-4">
                    <ValidatedInput
                        dataCy={`gluedCushionModal-${operation.side.toLowerCase()}-width`}
                        register={register}
                        error={errors['width' + capitalizedSide]}
                        name={'width' + capitalizedSide}
                        label={t(prefix + 'widthLabel')}
                        placeholder={t(prefix + 'widthPlaceholder')}
                        value={operation.dimensions.length}
                        required={true}
                        min={1}
                        max={currentPiece?.dimensions.length / 2}
                        disabled={!canEdit}
                    />
                </div>
                <div className="col-4">
                    <ValidatedInput
                        dataCy={`gluedCushionModal-${operation.side.toLowerCase()}-height`}
                        register={register}
                        error={errors['height' + capitalizedSide]}
                        name={'height' + capitalizedSide}
                        label={t(prefix + 'heightLabel')}
                        placeholder={t(prefix + 'heightPlaceholder')}
                        value={operation.dimensions.height}
                        required={true}
                        min={1}
                        max={10}
                        disabled={!canEdit}
                    />
                </div>
            </div>
        </Fragment>
    }

    const renderFooterButtons = () => {
        let secondaryButtonStyle = 'button button--outline';
        let secondaryButtonContent = t(prefix + 'cancelButton');
        let secondaryButtonOnClick = () => closeModal();

        if (gluedCushions.filter(operation => operation.id > 0).length > 0 && canEdit) {
            secondaryButtonStyle = 'button button--danger';
            secondaryButtonContent = t(prefix + 'deleteButton');
            secondaryButtonOnClick = () => setConfirmationModalIsActive(true);
        }

        const submitButton = <button
            data-cy="gluedCushionModal-submitButton"
            key={'gluedCushionModalSubmitButton'}
            className="button button--primary"
            type="submit"
            form="gluedCushionForm">
            {t(prefix + 'doneButton')}
        </button>;

        let buttons = [
            <button
                key={'gluedCushionModalCancelButton'}
                type="button"
                className={secondaryButtonStyle}
                onClick={secondaryButtonOnClick}>{secondaryButtonContent}
            </button>
        ];

        if (canEdit) {
            buttons.unshift(submitButton);
        }

        return buttons;
    }

    const toggleConfirmationModal = (userHasAccepted) => {
        if (userHasAccepted) {
            deleteGluedCushion();
        }

        setConfirmationModalIsActive(!confirmationModalIsActive)
    };

    const createOperation = (side, width, height, length) => {
        let gluedCushion = new Operation(0, GLUED_CUSHION);

        gluedCushion.side = side;
        gluedCushion.dimensions.height = parseNumber(height);
        gluedCushion.dimensions.length = parseNumber(width);
        gluedCushion.dimensions.width = parseNumber(length);
        gluedCushion.additionalDimension.type = side === ObjectSides.LEFT ?
            leftCushionHorizontalSide : rightCushionHorizontalSide;

        return gluedCushion;
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <ConfirmationModal
                isActive={confirmationModalIsActive}
                onClose={(userHasAccepted) => toggleConfirmationModal(userHasAccepted)}
                content={t('modals.gluedCushionModal.deleteGluedCushion')}
            />

            <Modal show={props.isActive} onHide={() => closeModal()} animation={true}>
                <Modal.Header className="modal-header">
                    <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form id="gluedCushionForm"
                          onSubmit={handleSubmit(closeModal)}>

                        <p>{t(prefix + 'description')}</p>

                        <SectionTitle
                            content={t(prefix + 'sideTitle')}
                        />

                        <div className="form-group">
                            {renderCheckboxes()}
                        </div>

                        {
                            gluedCushions.sort(sortBySide).map((operation, index) => {
                                return renderInputFields(operation, index);
                            })
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    {renderFooterButtons()}
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
}

GluedCushionModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    sides: PropTypes.array,
    data: PropTypes.object,
}

export {GluedCushionModal};
