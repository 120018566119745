import React from "react";

const EmptyArticleList = () => {
    return (
        <div className="message message--empty">
            <h4>Geen resultaten gevonden...</h4>
            <p>Probeer een andere zoekterm?</p>
        </div>
    )
}

export default EmptyArticleList;
