import * as React from 'react';
import PropTypes from 'prop-types';
import loadingIcon from '../../assets/img/loading-dark.svg';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";

export const LoadingMessage = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'messages.'

    const getClassName = () => {
        if (props.variant === 'inline') return 'message--loading--inline';
        else if (props.variant === 'overlay') return 'message--loading--overlay';

        return 'message--loading';
    }

    return (
        <div className={getClassName()}>
            <img src={loadingIcon} alt="Loading icon"/> {t(prefix + 'loading')}
        </div>
    );
};

LoadingMessage.propTypes = {
    variant: PropTypes.string,
}

LoadingMessage.defaultProps = {
    variant: 'inline',
}
