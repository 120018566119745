import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {ModalHolder} from "./ModalHolder";

export function RefreshModal(props) {
    const prefix = 'modals.refreshModal.';
    const {t} = useTranslation(TRANSLATION_NAMESPACE);

    const [seconds, setSeconds] = useState(10);

    useEffect(() => {
        if (props.isActive) {
            const timer = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [props.isActive]);

    useEffect(() => {
        if (seconds < 1) location.reload();
    }, [seconds])

    return (
        <ModalHolder isActive={props.isActive}>
            <Modal show={props.isActive} onHide={() => location.reload()} animation={true} className="modal--prompt">
                <Modal.Header>
                    <Modal.Title>{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(prefix + 'content', {seconds: seconds})}</p>
                </Modal.Body>
                <Modal.Footer>
                    <div className="button button--primary" onClick={() => location.reload()}>
                        {t(prefix + 'refreshButton')}
                    </div>
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
}

RefreshModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
}

RefreshModal.defaultProps = {
    isActive: false,
}
