export class PaginationData {
    constructor({current_page, first_pageUrl, last_page, last_page_url, next_page_url, per_page, total} = defaultData) {
        this.currentPage = current_page;
        this.firstPageUrl = first_pageUrl;
        this.lastPage = last_page;
        this.lastPageUrl = last_page_url;
        this.nextPageUrl = next_page_url;
        this.perPage = per_page;
        this.total = total;
    }

    reset() {
        this.currentPage = 0;
        this.firstPageUrl = '';
        this.lastPage = 1;
        this.lastPageUrl = '';
        this.nextPageUrl = '';
        this.perPage = 0;
        this.total = 0;

        return this;
    }
}

const defaultData = {
    current_page: 0,
    first_pageUrl: '',
    last_page: 1,
    last_page_url: '',
    next_page_url: '',
    per_page: 0,
    total: 0
};