import MeasurementPoints from "../../../models/MeasurementPoints";
import {BACK, LEFT} from "../../../../constants/ObjectSides";
import {Vector3} from "three";
import {DEBASE_ROUGH_TYPES} from "../../../../constants/OperationTypes";

export function getPointsForDebasingRough(pieceDimensions, debasing, sideToDisplayOn = LEFT) {
    let debasedVerticalPoints = new MeasurementPoints(
        getDebasedVerticalPointA(pieceDimensions, debasing, sideToDisplayOn),
        getDebasedVerticalPointB(pieceDimensions, debasing, sideToDisplayOn),
    );

    debasedVerticalPoints.textSide = BACK;

    let remainingVerticalPoints = new MeasurementPoints(
        getRemainingVerticalPointA(pieceDimensions, debasing, sideToDisplayOn),
        getRemainingVerticalPointB(pieceDimensions, debasing, sideToDisplayOn),
    );

    remainingVerticalPoints.textSide = BACK;

    let heightVerticalPoints = new MeasurementPoints(
        getHeightPointA(pieceDimensions, debasing, sideToDisplayOn),
        getHeightPointB(pieceDimensions, debasing, sideToDisplayOn),
    );

    heightVerticalPoints.textSide = BACK;

    return {
        horizontal: null,
        vertical: [debasedVerticalPoints, remainingVerticalPoints, heightVerticalPoints],
        // true
    }
}

function getDebasedVerticalPointA(pieceDimensions, debasing, sideToDisplayOn) {
    const xPos = getXPosBySideToDisplayOn(sideToDisplayOn, pieceDimensions);
    const zPos = getZPos(pieceDimensions, debasing.additionalDimension.type);
    const yPos = pieceDimensions.height / 2;

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getDebasedVerticalPointB(pieceDimensions, debasing, sideToDisplayOn) {
    const xPos = getXPosBySideToDisplayOn(sideToDisplayOn, pieceDimensions);
    const zPos = getZPos(pieceDimensions, debasing.additionalDimension.type);
    const yPos = pieceDimensions.height / 2 - debasing.dimensions.height;

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getRemainingVerticalPointA(pieceDimensions, debasing, sideToDisplayOn) {
    const xPos = getXPosBySideToDisplayOn(sideToDisplayOn, pieceDimensions);
    const zPos = getZPos(pieceDimensions, debasing.additionalDimension.type);
    const yPos = pieceDimensions.height / 2 - debasing.dimensions.height;

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getRemainingVerticalPointB(pieceDimensions, debasing, sideToDisplayOn) {
    const xPos = getXPosBySideToDisplayOn(sideToDisplayOn, pieceDimensions);
    const zPos = getZPos(pieceDimensions, debasing.additionalDimension.type);
    const yPos = pieceDimensions.height / -2;

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getHeightPointA(pieceDimensions, debasing, sideToDisplayOn) {
    const xPos = getXPosBySideToDisplayOn(sideToDisplayOn, pieceDimensions);
    const zPos = getZPosForHeightPoints(pieceDimensions, debasing.additionalDimension.type);
    const yPos = pieceDimensions.height / 2;

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getHeightPointB(pieceDimensions, debasing, sideToDisplayOn) {
    const xPos = getXPosBySideToDisplayOn(sideToDisplayOn, pieceDimensions);
    const zPos = getZPosForHeightPoints(pieceDimensions, debasing.additionalDimension.type);
    const yPos = pieceDimensions.height / -2;

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getXPosBySideToDisplayOn(sideToDisplayOn, {length}) {
    const xPos = length / 2;

    return sideToDisplayOn === LEFT ? -xPos : xPos;
}

function getZPos({width}, debasingType) {
    let zPos;

    if (debasingType === DEBASE_ROUGH_TYPES.FRONT_TO_BACK) {
        zPos = width / -2;
    } else {
        zPos = width / 2;
    }

    return zPos;
}

function getZPosForHeightPoints({width}, debasingType) {
    let zPos;

    if (debasingType === DEBASE_ROUGH_TYPES.BACK_TO_FRONT) {
        zPos = width / -2;
    } else if(debasingType === DEBASE_ROUGH_TYPES.FRONT_TO_BACK) {
        zPos = width / 2;
    } else {
        zPos = 0;
    }

    return zPos;
}
