import produce from 'immer';
import * as ActionTypes from "../constants/ActionTypes";
import _ from 'lodash';

const initialState = {
    offers: [],
    currentOffer: null,
    configurations: [],
    currentConfiguration: null,
    currentPiece: null,
    currentOperation: null,
};

export default function offerReducer(baseState = initialState, action) {
    return produce(baseState, draftState => {
            switch (action.type) {
                case ActionTypes.SET_CURRENT_OFFER:
                    if (action.payload == null) {
                        draftState.currentOffer = null;
                        draftState.currentConfiguration = null;
                        draftState.currentPiece = null;
                        draftState.currentOperation = null;
                    } else {
                        draftState.currentOffer = _.cloneDeep(action.payload);
                    }

                    break;
                case ActionTypes.SET_CURRENT_CONFIGURATION:
                    if (action.payload == null) {
                        draftState.currentConfiguration = null;
                        draftState.currentPiece = null;
                    } else {
                        draftState.currentConfiguration = _.cloneDeep(action.payload);

                        if (action.payload.id !== baseState.currentConfiguration?.id) {
                            draftState.currentPiece = null;
                        }
                    }

                    break;
                case ActionTypes.SET_CURRENT_PIECE:
                    draftState.currentPiece = _.cloneDeep(action.payload);
                    break;
                case ActionTypes.SET_CURRENT_OPERATION:
                    draftState.currentOperation = action.payload;
                    break;
                default:
                    break;
            }
        }
    )
}
