import {store} from '../../store/index';
import {setCurrentConfiguration, setCurrentOffer, setCurrentPiece} from "../../actions/OfferActions";
import _ from 'lodash';
import {parseNumber, parseToCommaSeparated} from "./StringHelper";
import {Offer} from "../models/Offer";
import {setCurrentOrder} from "../../actions/OrderActions";
import {OFFER} from "../../constants/ConfigurableTypes";

class ConfigurableReducerHelper {
    updateConfigurable(configurable) {
        const state = store.getState();

        const currentConfiguration = state.offerReducer.currentConfiguration;
        const currentPiece = state.offerReducer.currentPiece;

        if (configurable instanceof Offer) store.dispatch(setCurrentOffer(configurable));
        else store.dispatch(setCurrentOrder(configurable));

        if (!configurable) {
            store.dispatch(setCurrentConfiguration(null));
            store.dispatch(setCurrentPiece(null));
        } else {
            if (currentConfiguration) {
                configurable.configurations.forEach(configuration => {
                    if (configuration.id === currentConfiguration.id) {
                        store.dispatch(setCurrentConfiguration(configuration));

                        if (currentPiece) {
                            configuration.pieces.forEach(piece => {
                                if (piece.id === currentPiece.id) {
                                    store.dispatch(setCurrentPiece(piece));
                                }
                            });
                        }
                    }
                });
            }
        }
    }

    addPiece(piece, currentConfiguration = null) {
        const state = store.getState();
        const configurableType = state.configuratorReducer.configurableType;

        let configurable = configurableType === OFFER ? state.offerReducer.currentOffer : state.orderReducer.currentOrder;

        if (currentConfiguration == null) currentConfiguration = state.offerReducer.currentConfiguration;

        currentConfiguration.pieces.push(piece);
        // Sort the pieces so that connected pieces are close to each other in the list
        currentConfiguration.pieces = [...currentConfiguration.sortPieces()];

        configurable = this.__replaceConfiguration(currentConfiguration, configurable);

        store.dispatch(setCurrentConfiguration(currentConfiguration));
        store.dispatch(configurableType === OFFER ? setCurrentOffer(configurable) : setCurrentOrder(configurable));
        store.dispatch(setCurrentPiece(piece));
    }

    deleteConfiguration(configurable, configurationToDelete) {
        configurable.configurations = configurable.configurations.filter(configuration => configuration.id !== configurationToDelete.id);

        store.dispatch(configurable instanceof Offer ? setCurrentOffer(configurable) : setCurrentOrder(configurable));
        store.dispatch(setCurrentConfiguration());
    }

    updateConfiguration(configurationToUpdate) {
        const state = store.getState();
        const configurableType = state.configuratorReducer.configurableType;

        let configurable = configurableType === OFFER ? state.offerReducer.currentOffer : state.orderReducer.currentOrder;
        let currentPiece = state.offerReducer.currentPiece;
        currentPiece = configurationToUpdate.pieces.find(piece => piece.id === currentPiece?.id);

        configurable = this.__replaceConfiguration(configurationToUpdate, configurable);

        store.dispatch(configurableType === OFFER ? setCurrentOffer(configurable) : setCurrentOrder(configurable));
        store.dispatch(setCurrentConfiguration(configurationToUpdate));
        store.dispatch(setCurrentPiece(currentPiece));
    }

    updatePiece(pieceToUpdate, currentConfiguration = null) {
        const state = store.getState();

        let currentPiece = state.offerReducer.currentPiece;

        if (currentConfiguration == null) currentConfiguration = state.offerReducer.currentConfiguration;

        currentConfiguration = this.__replacePiece(pieceToUpdate, currentConfiguration);

        this.updateConfiguration(currentConfiguration);
        if (currentPiece?.id === pieceToUpdate.id) store.dispatch(setCurrentPiece(pieceToUpdate));
    }

    __replaceConfiguration(configurationToReplace, configurable) {
        configurable.configurations = configurable.configurations.map(configuration => {
            if (configuration.id === configurationToReplace.id) {
                return _.cloneDeep(configurationToReplace);
            }

            return configuration;
        });

        configurable.price = this.__updateConfigurablePrice(configurable.configurations);

        return configurable;
    }

    __replacePiece(pieceToReplace, configuration) {
        configuration.pieces = configuration.pieces.map(piece => {
            if (piece.id === pieceToReplace.id) return _.cloneDeep(pieceToReplace);

            return piece;
        });

        return configuration;
    }

    __updateConfigurationPrice(pieces) {
        let price = 0;

        pieces.forEach(piece => {
            let piecePrice = parseFloat(piece.price.totalPrice?.replace(',', '.'));

            if (!isNaN(piecePrice)) price += piecePrice;
        });

        return parseToCommaSeparated(price, 2);
    }

    __updateConfigurablePrice(configurations) {
        let price = 0;

        configurations.forEach(configuration => {
            let configurationPrice = parseNumber(configuration.price);

            if (!isNaN(configurationPrice)) price += configurationPrice;
        });

        return parseToCommaSeparated(price, 2);
    }
}

export default ConfigurableReducerHelper;
