import {BACK, FRONT, LEFT, RIGHT} from "../../../../constants/ObjectSides";
import {Vector3} from "three";
import MeasurementPoints from "../../../models/MeasurementPoints";
import {INNER} from "../../../../constants/Angles";
import {getXPosForSide, getYPosForSide, getZPosForSide} from "../SurfaceMeasurementLineHelper";

export function getPointsForCoupeOverWidth(pieceDimensions, coupe, sideOfLine) {
    let horizontalPoints;
    let verticalPoints;

    const linePositionHorizontal = {
        xPos: 0,
        yPos: getYPosForSide(pieceDimensions),
        zPos: getZPosForSide(pieceDimensions, coupe.angle === INNER ? FRONT : BACK)
    };

    const linePositionVertical = {
        xPos: getXPosForSide(pieceDimensions, coupe.side),
        yPos: getYPosForSide(pieceDimensions),
        zPos: 0
    };

    if ([FRONT, BACK].includes(sideOfLine)) {
        const horizontalPointA = getHorizontalPointA(linePositionHorizontal, coupe);
        const horizontalPointB = getHorizontalPointB(linePositionHorizontal, coupe);

        horizontalPoints = new MeasurementPoints(horizontalPointA, horizontalPointB);
        horizontalPoints.visible = coupe.connectedCoupe == null;
    }

    if ([LEFT, RIGHT].includes(sideOfLine)) {
        const verticalPointA = getVerticalPointA(linePositionVertical, coupe);
        const verticalPointB = getVerticalPointB(linePositionVertical, coupe);

        verticalPoints = new MeasurementPoints(verticalPointA, verticalPointB);
        verticalPoints.visible = coupe.connectedCoupe == null;
    }

    return {
        horizontal: [FRONT, BACK].includes(sideOfLine) ? horizontalPoints : verticalPoints,
        vertical: null,
    };
}

function getHorizontalPointA({xPos, yPos, zPos}, coupe) {
    if (coupe.side === LEFT) {
        if (coupe.angle === INNER) {
            xPos = coupe.position.x;
        } else {
            // OUTER
            xPos = coupe.position.x + coupe.dimensions.length;
        }
    } else {
        if (coupe.angle === INNER) {
            xPos = coupe.position.x - coupe.dimensions.length;
        } else {
            // OUTER
            xPos = coupe.position.x;
        }
    }

    return new Vector3(
        xPos,
        yPos,
        zPos,
    );
}

function getHorizontalPointB({xPos, yPos, zPos}, coupe) {
    if (coupe.side === LEFT) {
        if (coupe.angle === INNER) {
            xPos = coupe.position.x + coupe.dimensions.length;
        } else {
            // OUTER
            xPos = coupe.position.x;
        }
    } else {
        if (coupe.angle === INNER) {
            xPos = coupe.position.x;
        } else {
            // OUTER
            xPos = coupe.position.x - coupe.dimensions.length;
        }
    }

    return new Vector3(
        xPos,
        yPos,
        zPos,
    );
}

function getVerticalPointA({xPos, yPos, zPos}, coupe) {
    if (coupe.side === LEFT) {
        zPos = coupe.position.z;
    } else {
        zPos = coupe.position.z;
    }

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}

function getVerticalPointB({xPos, yPos, zPos}, coupe) {
    if (coupe.side === LEFT) {
        zPos = coupe.position.z + coupe.dimensions.width;
    } else {
        zPos = coupe.position.z - coupe.dimensions.width;
    }

    return new Vector3(
        xPos,
        yPos,
        zPos
    );
}
