import * as React from 'react';
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import history from "../../../config/history";
import {AppHeaderDropDown} from "../../headers/AppHeaderDropDown";
import {AppHeaderDropdownItem} from "../../headers/AppHeaderDropdownItem";
import {exportPdf, exportSummary} from "./index";
import {DropdownDivider} from "../../headers/DropdownDivider";
import {AppHeader} from "../../headers/AppHeader";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {useSelector} from "react-redux";
import {Order} from "../../../classes/models/Order";
import {hasOneOfRoles, isAdmin} from "../../../classes/helpers/UserHelper";
import {ADMIN, SUPERVISOR} from "../../../constants/RoleNames";

export const OrderDetailAppHeader = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = "pages.orders.";
    const appHeaderPrefix = "headers.appHeader.actions.";

    const [headerTitle, setHeaderTitle] = useState('');

    const {currentCompanyBranch} = useSelector(state => state.companyReducer);

    useEffect(() => {
        let title = t(prefix + "title");
        if (props.order) title += ": " + props.order.name;

        setHeaderTitle(title);
    }, [props.order]);

    return (
        <AppHeader dataCy="orderDetail-title" title={headerTitle} onBackClick={() => history.push('/orders')}>
            <AppHeaderDropDown content={t(prefix + 'optionsButton')}>
                <AppHeaderDropdownItem
                    content={t(appHeaderPrefix + 'exportPdf')}
                    onClick={() => exportPdf(props.order, t(prefix + 'orderDownloadFailed'))}
                />

                {
                    // Alpine Digital, LN and all companies of Batipro
                    [1, 2, 3, 19, 29, 30].includes(currentCompanyBranch?.id) ?
                        <AppHeaderDropdownItem
                            content={t(appHeaderPrefix + 'exportSummary')}
                            onClick={() => exportSummary(props.order, t(prefix + 'summaryDownloadFailed'))}
                        /> : null
                }

                <AppHeaderDropdownItem content={t(appHeaderPrefix + 'duplicate')} disabled={true}/>

                {
                    hasOneOfRoles([ADMIN, SUPERVISOR]) &&
                    <>
                        <DropdownDivider/>
                        {
                            isAdmin() && <AppHeaderDropdownItem
                                content={t(appHeaderPrefix + 'editOrder')}
                                onClick={() => props.onEditClick()}
                            />
                        }
                        <AppHeaderDropdownItem
                            content={t(appHeaderPrefix + 'editDeliveryDate')}
                            onClick={() => props.onDeliveryDateUpdateClick()}
                        />
                    </>
                }
            </AppHeaderDropDown>
        </AppHeader>
    );
};

OrderDetailAppHeader.propTypes = {
    order: PropTypes.instanceOf(Order).isRequired,
    onEditClick: PropTypes.func,
    onDeliveryDateUpdateClick: PropTypes.func,
}
