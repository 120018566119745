import {Vector3} from "three";
import {ChiseledPiece} from "../../internal";

export class Type6Piece extends ChiseledPiece {

    constructor(id, dimensions = {frontHeight: 0, height: 0, width: 0, length: 0, extrusion: 0, barWidth: 0}, operations = [], position = new Vector3()) {
        super(id, dimensions, operations, position);
    }

}
