class User {
    constructor(id = 0) {
        this.id = id;
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.phoneNumber = '';
        this.roles = [];
        this.company = null;
        this.companyBranch = null;
        this.isBanned = false;
    }

    getFullName() {
        return this.firstName + ' ' + this.lastName;
    }
}

export {User};
