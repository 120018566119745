import * as React from 'react';
import {useTranslation} from "react-i18next";
import {ActionsDropdown} from "../../dropdowns/ActionsDropdown";
import {ActionsDropdownItem} from "../../dropdowns/items/ActionsDropdownItem";
import {DANGER, SUCCESS} from "../../../constants/Variants";
import {TRANSLATION_NAMESPACE} from '../../../constants/TranslationConstants';
import {SearchBar} from '../../navigation/SearchBar';
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addAlertMessage, setWindowIsLoading} from '../../../actions/GeneralActions';
import {AddressService} from '../../../classes/services/AddressService';
import {AppHolder} from '../../layout/AppHolder';
import {ConfirmationModal} from '../../modals/ConfirmationModal';
import {AddressModal} from '../../modals/AddressModal';
import {AppContent} from '../../layout/AppContent';

export const DeliveryAddressesManagement = (props) => {

    const prefix = 'pages.settings.deliveryAddressManager.';
    const constantsPrefix = 'constants.countries.';
    const {t} = useTranslation(TRANSLATION_NAMESPACE);

    const dispatch = useDispatch();

    const [addresses, setAddresses] = useState([]);
    const [allAddresses, setAllAddresses] = useState([]);
    const [addressModalIsActive, setAddressModalIsActive] = useState(false);
    const [deleteAddressModalIsActive, setDeleteAddressModalIsActive] = useState(false);
    const [addressToEdit, setAddressToEdit] = useState(null);
    const [addressToDelete, setAddressToDelete] = useState(null);

    const windowIsLoading = useSelector(state => state.generalReducer.windowIsLoading);

    const addressService = new AddressService();

    useEffect(() => {
        getAddresses();
    }, [])

    const openAddressModal = (address = null) => {
        if (address) {
            setAddressToEdit(address);
        }

        setAddressModalIsActive(true);
    }

    const closeAddressModal = (address = null) => {
        if (address) {
            if (addressToEdit) {
                // Edit address
                editAddress(address);
            } else {
                // Create new address
                createAddress(address);
            }
        }

        setAddressToEdit(null);
        setAddressModalIsActive(false);
    }

    const openDeleteAddressModal = (address) => {
        setDeleteAddressModalIsActive(true);
        setAddressToDelete(address);
    }

    const closeDeleteAddressModal = (hasAccepted) => {
        if (hasAccepted) {
            deleteAddress(addressToDelete);
        }

        setDeleteAddressModalIsActive(false);
    }

    const getAddresses = () => {
        dispatch(setWindowIsLoading(true));

        addressService.getAll()
            .then(response => {
                if (response.success) {
                    setAddresses(response.data);
                    setAllAddresses(response.data);
                } else {
                    dispatch(addAlertMessage(DANGER, t(prefix + 'getAddressesFailed')))
                }
            })
            .catch(() => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'getAddressesFailed')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const createAddress = (address) => {
        dispatch(setWindowIsLoading(true));

        addressService.create(address)
            .then(response => {
                if (response.success) {
                    setAddresses(addresses.concat([response.data]));
                    setAllAddresses(allAddresses.concat([response.data]));

                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'addAddressSuccess')));
                } else {
                    throw Error(t(prefix + 'addAddressFailed'))
                }
            })
            .catch(() => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'addAddressFailed')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const editAddress = (address) => {
        dispatch(setWindowIsLoading(true));

        addressService.update(address)
            .then(response => {
                if (response.success) {
                    setAddresses(addresses.map(a => {
                        if (a.id === address.id) {
                            return address;
                        }

                        return a;
                    }));

                    setAllAddresses(allAddresses.map(a => {
                        if (a.id === address.id) {
                            return address;
                        }

                        return a;
                    }));


                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateAddressSuccess')))
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'updateAddressFailed')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const deleteAddress = (deleteAddress) => {
        dispatch(setWindowIsLoading(true));

        addressService.delete(deleteAddress.id)
            .then(response => {
                if (response.success) {
                    setAddresses(addresses.filter(address => address.id !== deleteAddress.id));
                    setAllAddresses(allAddresses.filter(address => address.id !== deleteAddress.id));

                    setAddressToDelete(null);
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'deleteAddressFailed')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const filterAddresses = (input) => {
        let listItems = allAddresses.filter(address => address.street.toLowerCase()
            .concat(address.city.toLowerCase())
            .concat(address.country.toLowerCase())
            .concat(address.postalCode.toLowerCase())
            .concat(address.number.toLowerCase()).includes(input.toLowerCase()));
        setAddresses(listItems);
    }

    return (
        <AppHolder>

            <ConfirmationModal
                isActive={deleteAddressModalIsActive}
                onClose={(hasAccepted) => closeDeleteAddressModal(hasAccepted)}
                content={t(prefix + 'deleteAddressConfirmation')}/>

            <AddressModal
                address={addressToEdit}
                isActive={addressModalIsActive}
                onClose={(address) => closeAddressModal(address)}
            />

            <AppContent>

                <>

                    <SearchBar onInput={(input) => filterAddresses(input)}/>

                    <div className="p-4">
                        {
                            addresses.map(address =>
                                <label key={address.id} className="list__item list__item--bordered pointer">
                                    <div className="list__item__content">
                                        {address.street + ' ' + address.number}<br/>
                                        {address.postalCode + ' ' + address.city + ', ' + t(constantsPrefix + address.country)}
                                    </div>
                                    <ActionsDropdown>
                                        <ActionsDropdownItem
                                            content={t(prefix + 'viewOrdersAction')}
                                            disabled={true}
                                        />
                                        <ActionsDropdownItem
                                            content={t(prefix + 'viewMapAction')}
                                            disabled={true}
                                        />
                                        <ActionsDropdownItem
                                            content={t(prefix + 'editAction')}
                                            onClick={() => openAddressModal(address)}
                                        />
                                        <ActionsDropdownItem
                                            variant={DANGER}
                                            content={t(prefix + 'deleteAction')}
                                            onClick={() => openDeleteAddressModal(address)}
                                        />
                                    </ActionsDropdown>
                                </label>)
                        }

                        {
                            !windowIsLoading ?
                                <div className="list__item  list__item--bordered pointer"
                                     onClick={() => openAddressModal()}>
                                    <div className="list__item__icon">
                                        <i className="material-icons color--blue">add_circle</i>
                                    </div>
                                    <div className="list__item__content color--blue">
                                        {t(prefix + 'addAddress')}
                                    </div>
                                </div>
                                : null
                        }
                    </div>

                </>
            </AppContent>

        </AppHolder>

    );
};
