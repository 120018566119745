import * as React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {parseToCommaSeparated} from "../../../classes/helpers/StringHelper";
import {CHISELED_SIDE, DEBASING, FINISHED_SIDE} from "../../../constants/OperationTypes";

export const ConfigurationOperationItem = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.configurationList.';
    const configurationListConstantsPrefix = 'pages.offers.configurationList.constants.';
    const constantsPrefix = 'constants.operationTypes.';

    const getUnitOfMeasure = (operationType) => {
        let unitOfMeasure;

        if (operationType === DEBASING) {
            unitOfMeasure = 'm²';
        } else {
            // FINISHED_SIDE
            unitOfMeasure = 'm';
        }

        return unitOfMeasure;
    }

    const getOperationTypeTranslation = (type) => {
        let translation;

        if (type === FINISHED_SIDE) {
            translation = t(configurationListConstantsPrefix + 'FINISHED_SURFACE');
        } else {
            translation = t(constantsPrefix + props.type);
        }

        return translation;
    }

    return (
        <tr className="offer__table__level2">
            <td>
                <i className="material-icons">subdirectory_arrow_right</i> {getOperationTypeTranslation(props.type)}
                <small className="m-l-1">({t(prefix + 'minimumDebasingAmountInfo')})</small>
            </td>
            <td colSpan="2">
                <small>{parseToCommaSeparated(props.amount, 2)} {getUnitOfMeasure(props.type)}</small>
            </td>
            <td>€ {parseToCommaSeparated(props.price, 2)}</td>
            <td colSpan={2}/>
        </tr>
    );
};

ConfigurationOperationItem.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
}
