import PropTypes from "prop-types";
import * as React from "react";

export const ClientTypeItem = (props) => {
    return (
        <tr>
            <td>{props.clientType}</td>
            <td>{props.discountPieces}</td>
            <td>{props.discountOperations}</td>
        </tr>
    )
}

ClientTypeItem.propTypes = {
    clientType: PropTypes.string.isRequired,
    discountPieces: PropTypes.number.isRequired,
    discountOperations: PropTypes.number.isRequired,
}
