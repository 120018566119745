export function convert2DImageBlobsToBase64(configurationImages) {
    const callback = async (o) => {
        o = await blobToBase64(o.blob)
            .then(base64 => {
                o.blob = base64;
                return o;
            });

        return o;
    }

    const resultCallback = (data) => {
        return data;
    }

    return configurationImages.map(async imageObject => {
        let obj = {};

        const generalResult = imageObject.general ? imageObject.general.map(callback) : null;
        const hall2Result = imageObject.hall2.map(callback);
        const hall3Result = imageObject.hall3.map(callback);
        const hall4Result = imageObject.hall4.map(callback);
        const connectedResult = imageObject.connected ? imageObject.connected.map(callback) : null;

        const general = generalResult ? await Promise.all(generalResult).then(resultCallback) : null;
        const hall2 = await Promise.all(hall2Result).then(resultCallback);
        const hall3 = await Promise.all(hall3Result).then(resultCallback);
        const hall4 = await Promise.all(hall4Result).then(resultCallback);
        const connected = connectedResult ? await Promise.all(connectedResult).then(resultCallback) : null;

        obj.general = general;
        obj.hall2 = hall2;
        obj.hall3 = hall3;
        obj.hall4 = hall4;
        obj.connected = connected;

        return obj;
    })
}

export function blobToBase64(blob) {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
}
