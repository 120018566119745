import {Parser, Service,} from '../../internal';
import {DEFAULT} from "../../constants/PriceTypes";
import {PaginationData} from "../models/PaginationData";

class OfferService extends Service {

    async getById(offerId) {
        return await this.authenticatedRequest('get', 'offers/' + offerId)
            .then(response => {
                return Parser.parseOffer(response.data.data);
            })
            .catch(error => {
                throw error;
            });
    }

    async getByIdComplete(offerId, priceType = DEFAULT, updatePrice = false) {
        const data = {
            priceType: priceType,
            updatePrice: updatePrice
        };

        return await this.authenticatedRequest('get', 'offers/' + offerId + '/complete', data)
            .then(response => {
                if (response.data.success) {
                    response.data.data = Parser.parseOffer(response.data.data, true);
                }

                return response.data;
            })
            .catch(error => {
                throw error;
            });
    }

    async getAll(priceType, page = 1, companyBranchId = null, searchKeyword = '') {
        let data = {
            priceType: priceType,
            companyBranchId: companyBranchId,
            withConfigurations: true,
            searchKeyword: searchKeyword,
            page: page,
        };
        return await this.authenticatedRequest('get', 'offers', data)
            .then((response) => {
                let responseData = {success: false, data: [], paginationData: {}};

                if (response.data.success) {
                    responseData.success = true;
                    responseData.data = response.data.data.data.map(offerData => Parser.parseOffer(offerData, false));
                    responseData.paginationData  = new PaginationData(response.data.data);
                }

                return responseData;
            }).catch((error) => {
                return error.response.data;
            })
    }

    async getConfigurationsById(offerId) {
        return await this.authenticatedRequest('get', 'offers/' + offerId + '/configurations').then((response) => {
            return response.data.data.map((configurationData) => {
                return Parser.parseConfiguration(configurationData, true);
            });
        }).catch((error) => {
            if (error.response != null) {
                throw error.response.data;
            }
        })
    }

    async createOffer(name, customer, margin, vat, companyBranchId) {
        const data = {
            name,
            customer,
            margin,
            vat,
            companyBranchId,
        };

        return await this.authenticatedRequest('post', 'offers', data).then((response) => {
            if (response.data.success) response.data.data = Parser.parseOffer(response.data.data);

            return response.data;
        }).catch((error) => {
            return error.response.data;
        })
    }

    async update(id, name, customer, margin, vat, companyBranchId) {
        const data = {
            name,
            customer,
            margin,
            vat,
            companyBranchId
        };

        return await this.authenticatedRequest('put', 'offers/' + id, data).then((response) => {
            if (response.data.success) {
                response.data.data = Parser.parseOffer(response.data.data);
            }

            return response.data;
        }).catch((error) => {
            return error.response.data;
        })
    }

    async updateExpiryDate(id) {
        return await this.authenticatedRequest('post', 'offers/' + id)
            .then((response) => {
                if (response.data.success) {
                    response.data.data = Parser.parseOffer(response.data.data);
                }

                return response.data;
            }).catch((error) => {
                return new Error(error.response.message);
            })
    }

    async deleteOffer(id) {
        return await this.authenticatedRequest('delete', 'offers/' + id).then((response) => {
            return response.data;
        }).catch((error) => {
            return error.response.data;
        })
    }

}

export {OfferService};
