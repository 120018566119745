import * as React from 'react';
import PropTypes from 'prop-types';

export const RadioButton = (props) => {
    return (
        <div className={`m-b-${props.marginBottom}`}>
            <div className={'form-group form-group--toggle ' + props.className}>
                <label>
                    <input
                        data-cy={props.dataCy}
                        type="radio"
                        name={props.name}
                        checked={props.checked}
                        onChange={() => props.onChange()}
                        disabled={props.disabled}
                    />

                    <div className="form-group-content">
                        {props.image ? <img src={props.image} alt={props.content}/> : null}
                        <div className="form-group-content__inner">
                            {props.content ? props.content : ''}
                            {props.smallText ? (<small>{props.smallText}</small>) : null}
                        </div>
                    </div>
                </label>
            </div>
        </div>
    );
};

RadioButton.propTypes = {
    name: PropTypes.string.isRequired,
    content: PropTypes.string,
    image: PropTypes.node,
    smallText: PropTypes.string,
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dataCy: PropTypes.string
}

RadioButton.defaultProps = {
    checked: false,
    disabled: false,
    content: '',
    marginBottom: 2,
}
