import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {ModalHolder} from "./ModalHolder";
import {Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {SectionTitle} from "../headers/SectionTitle";
import {CheckboxWithIcon} from "../forms/inputs/CheckboxWithIcon";
import {Order} from "../../classes/models/Order";
import {CANCELED, DELIVERED, MODIFIED, PARTIALLY_DELIVERED} from "../../constants/OrderStatusNames";

export const OrderStatusModal = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.orderStatusModal.';

    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        const statusList = [];

        if (props.isActive && props.order) {
            if (props.order.isModified) statusList.push(MODIFIED);
            if (props.order.hasStatus(CANCELED)) statusList.push(CANCELED);
            if (props.order.hasStatus(PARTIALLY_DELIVERED)) statusList.push(PARTIALLY_DELIVERED);
            if (props.order.hasStatus(DELIVERED)) statusList.push(DELIVERED);
        }

        setStatuses(statusList);
    }, [props.isActive, props.order]);

    const updateStatusList = (status) => {
        let statusList = statuses.map(s => s);
        const index = statusList.indexOf(status);

        if (index > -1) {
            statusList.splice(index, 1);
        } else {
            statusList.push(status);
        }

        if (status === DELIVERED && statusList.includes(PARTIALLY_DELIVERED)) {
            statusList = statusList.filter(s => s !== PARTIALLY_DELIVERED);
        }

        if (status === PARTIALLY_DELIVERED && statusList.includes(DELIVERED)) {
            statusList = statusList.filter(s => s !== DELIVERED);
        }

        setStatuses(statusList);
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <Modal show={props.isActive} onHide={props.onClose} animation={true} className="modal--prompt">
                <Modal.Header>
                    <Modal.Title>{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SectionTitle content={t(prefix + 'statusTitle')}/>

                    <CheckboxWithIcon
                        checked={statuses.includes(MODIFIED)}
                        content={t(prefix + 'isModifiedCheckboxContent')}
                        onChange={() => updateStatusList(MODIFIED)}
                    />

                    <CheckboxWithIcon
                        checked={statuses.includes(CANCELED)}
                        content={t(prefix + 'isCanceledCheckboxContent')}
                        onChange={() => updateStatusList(CANCELED)}
                    />

                    <SectionTitle content={t(prefix + 'deliveryTitle')}/>

                    <CheckboxWithIcon
                        checked={statuses.includes(PARTIALLY_DELIVERED)}
                        content={t(prefix + 'isPartiallyDeliveredCheckboxContent')}
                        onChange={() => updateStatusList(PARTIALLY_DELIVERED)}
                    />

                    <CheckboxWithIcon
                        checked={statuses.includes(DELIVERED)}
                        content={t(prefix + 'isDeliveredCheckboxContent')}
                        onChange={() => updateStatusList(DELIVERED)}
                    />


                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="button button--primary"
                        type="button"
                        data-test="confirm"
                        onClick={() => props.onClose(statuses)}>
                        {t(prefix + 'submitButton')}
                    </button>
                    <button
                        type="button"
                        className="button button--outline"
                        data-test="cancel"
                        onClick={() => props.onClose()}>
                        {t(prefix + 'cancelButton')}
                    </button>
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
};

OrderStatusModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.instanceOf(Order),
}
