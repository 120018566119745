import produce from 'immer';
import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
    company: null,
    selectedCompany: null,
    currentCompanyBranch: null,
};

function companyReducer(state = initialState, action) {
    return produce(state, draftState => {
        switch (action.type) {
            case ActionTypes.SET_COMPANY:
                // Sync the selectedCompany state when it is the same as the company
                if (action.company?.id === draftState.company?.id) {
                    draftState.selectedCompany = action.company;
                }
                draftState.company = action.company;
                break;
            case ActionTypes.SET_SELECTED_COMPANY:
                draftState.selectedCompany = action.company;
                break;
            case ActionTypes.SET_CURRENT_COMPANY_BRANCH:
                draftState.currentCompanyBranch = action.companyBranch;
                break;
            default:
                break;
        }
    });

}

export default companyReducer;
