import * as React from 'react';
import PropTypes from 'prop-types';

export const ConfigOption = (props) => {
    return (
        <div className={`m-b-${props.marginBottom}`}>
            <div className="form-group form-group--toggle">
                <label>
                    <input data-cy={props.dataCy} type="radio" name={props.name} onChange={() => props.onClick()} checked={props.selected}/>
                    {props.image ? <img src={props.image} alt={props.content}/> : null}
                    <span>{props.content}</span>
                    {props.subContent ? <small>{props.subContent}</small> : null}
                </label>

                {props.children}

            </div>
        </div>
    );
};

ConfigOption.propTypes = {
    name: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    subContent: PropTypes.string,
    image: PropTypes.node,
    marginBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    dataCy: PropTypes.string,
}

ConfigOption.defaultProps = {
    marginBottom: 2,
}
