import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {store} from './store/index';
import {Provider} from 'react-redux';
import {httpService} from './internal';
import {Router} from 'react-router-dom';
import history from './config/history';
import i18n from './i18n/index';
import {I18nextProvider} from 'react-i18next';
import 'bootstrap';
import * as Sentry from '@sentry/react';
import {unregister} from "./serviceWorker";
import {BrowserTracing} from "@sentry/tracing";

unregister();

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://404b7816bc104c8595d0681262239614@o414149.ingest.sentry.io/5306790",
        release: "stoneapp@" + process.env.REACT_APP_VERSION,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

httpService.setupInterceptors(store, history);

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    </I18nextProvider>
    ,
    document.getElementById('root'));

if (process.env.NODE_ENV !== 'development') {
    console.log = () => {
    }
}
