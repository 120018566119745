export const BELGIUM = 'BELGIUM';
export const NETHERLANDS = 'NETHERLANDS';
export const FRANCE = 'FRANCE';
export const GERMANY = 'GERMANY';
export const LUXEMBOURG = 'LUXEMBOURG';

export const ALL = [
    BELGIUM,
    NETHERLANDS,
    FRANCE,
    GERMANY,
    LUXEMBOURG,
]
