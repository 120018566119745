import * as React from 'react';
import PropTypes from 'prop-types';
import {DANGER, STRONG} from "../../../constants/Variants";

export const ActionsDropdownItem = (props) => {
    const getStyling = () => {
        let defaultStyling = 'pointer dropdown-item';
        const disabledStyling = 'disabled';

        if (props.disabled) {
            return defaultStyling += ' ' + disabledStyling;
        }

        return defaultStyling;
    }

    const getContent = () => {
        let content;

        if (props.variant === DANGER) {
            content = <span className="color--red">{props.content}</span>;
        } else if (props.variant === STRONG) {
            content = <strong>{props.content}</strong>;
        } else {
            content = props.content;
        }

        return content;
    }

    return (
        <span
            data-cy={props.dataCy}
            className={getStyling()}
            onClick={() => props.onClick ? props.onClick() : null}
            data-test={props.dataTestTag}
        >
            {getContent()}
        </span>
    );
};

ActionsDropdownItem.propTypes = {
    content: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    dataTestTag: PropTypes.string,
    dataCy: PropTypes.string,
};

ActionsDropdownItem.defaultProps = {
    disabled: false,
}