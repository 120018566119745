class Address {
    constructor(id, street, number, postalCode, city, country) {
        this.id = id;
        this.street = street;
        this.number = number;
        this.postalCode = postalCode;
        this.city = city;
        this.country = country;
    }

}

export {Address};