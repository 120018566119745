import React, {Component} from 'react';

class ConfiguratorHolder extends Component {
    render() {
        return (
            <div className="configurator__holder">
                {this.props.children}
            </div>
        );
    }
}

export {ConfiguratorHolder};