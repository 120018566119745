import {Service} from "./Service";
import {Parser} from "../helpers/Parser";

export class PriceVersionService extends Service {
    async getCurrent() {
        return await this.authenticatedRequest('get', 'priceversions/current')
            .then(response => {
                if (response.data.success && response.data.data) {
                    return Parser.parsePriceVersion(response.data.data);
                } else {
                    throw Error('Error while fetching current price version');
                }
            })
    }
}
