import * as React from 'react';
import {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Title} from "../../headers/Title";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {EmptySidebarListItem} from "../../empty/EmptySidebarListItem";
import {useDispatch, useSelector} from "react-redux";
import {SearchBar} from "../../navigation/SearchBar";
import {LoadingMessage} from "../../messages/LoadingMessage";
import history from "../../../config/history";
import {ADMIN, SUPERVISOR} from "../../../constants/RoleNames";
import {setSearchKeyword} from "../../../actions/GeneralActions";
import {debounce} from 'lodash'
import {EmptyPage} from "../../empty/EmptyPage";
import {CompanyBranchSelect} from "../../shared/CompanyBranchSelect";
import {hasOneOfRoles} from "../../../classes/helpers/UserHelper";
import {DeliveryInfoHolder} from "../orders/DeliveryInfoHolder";
import {captureException} from "@sentry/react";
import {OrderService} from "../../../classes/services/OrderService";

export const OfferList = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.';

    const {windowIsLoading, searchKeyword} = useSelector(state => state.generalReducer);

    const dispatch = useDispatch();

    const updateCurrentOffer = (offerId) => {
        history.push('/offers/' + offerId);
    }

    const updateSearchKeyword = debounce((keyword) => {
        dispatch(setSearchKeyword(keyword));
    }, 650)

    const renderListItems = () => {
        if (props.offers.length < 1) {
            if (searchKeyword) {
                return <tr>
                    <td colSpan="7" className="text-center">
                        {t(prefix + 'noListItemsFound')}
                    </td>
                </tr>
            } else {
                return <EmptySidebarListItem content={t(prefix + 'emptySideBar')}/>
            }
        }

        return <tbody>
        {
            props.offers.map(offer => {
                return <tr data-cy={`offerRowId-${offer.id}`} key={offer.id} className="pointer"
                           onClick={() => updateCurrentOffer(offer.id)}>
                    <td width="1"><i className="material-icons color--blue p-l-1">description</i></td>
                    <td>
                    <span className="d-flex" data-cy={`offerId-${offer.id}`}>
                        {offer.id}
                    </span>
                    </td>
                    <td><strong>{offer.name}</strong></td>
                    <td data-cy={`price-offer-${offer.id}`}>
                        €{offer.price}
                    </td>
                    <td>{offer.timestamp.date}</td>
                    <td>{offer.getExpiryDate()}</td>
                    <td>{offer.customer?.name}</td>
                </tr>
            })
        }
        </tbody>
    }

    const renderOfferList = () => {
        if (props.offers.length < 1) {
            return !windowIsLoading && <EmptyPage title={t(prefix + 'noOffersFoundWithSearchKeyword')}/>;
        }

        return <div className="m-l-2 m-r-2 m-t-2">
            <table data-cy="offerList" className="table table--striped">
                <thead data-cy="offerList-thead">
                <tr>
                    <th/>
                    <th>{t(prefix + 'offerNumberTableHeader')}</th>
                    <th>{t(prefix + 'nameTableHeader')}</th>
                    <th>{t(prefix + 'priceTableHeader')}</th>
                    <th>{t(prefix + 'createdAtTableHeader')}</th>
                    <th>{t(prefix + 'validUntilTableHeader')}</th>
                    <th>{t(prefix + 'customerTableHeader')}</th>
                </tr>
                </thead>
                {renderListItems()}
            </table>
        </div>
    }

    return (
        <>
            {windowIsLoading ? <LoadingMessage variant="overlay"/> : null}

            <div className="app__content__header">
                <Title title={props.title}/>

                {
                    !!props.openModal &&
                    <div className="app__content__header__actions">
                        <DeliveryInfoHolder
                            styling="m-r-2 d-flex"
                        />

                        <button data-cy="offerList-addButton"
                                className="button button--icon button--primary"
                                data-tip={t(prefix + 'tooltipContent')}
                                onClick={() => props.openModal()}>
                            <i className="material-icons">add</i>
                            {t(prefix + 'addButtonContent')}
                        </button>
                    </div>
                }

                {props.tooltipContent ? <ReactTooltip/> : null}

            </div>

            <div className="m-l-2 m-r-2 m-t-2">
                <SearchBar
                    onInput={(input) => updateSearchKeyword(input)}
                    disabled={windowIsLoading}
                />
            </div>

            {
                hasOneOfRoles([ADMIN, SUPERVISOR]) &&
                <CompanyBranchSelect
                    dataCy="offerList-companyBranchSelect"
                    className="m-l-2 m-r-2 p-t-2"
                    branches={props.branches}
                />
            }

            {renderOfferList()}

        </>
    );
};

OfferList.propTypes = {
    title: PropTypes.string.isRequired,
    openModal: PropTypes.func.isRequired,
    offers: PropTypes.array.isRequired,
    companyBranch: PropTypes.object,
    branches: PropTypes.array.isRequired,
    onCompanyChange: PropTypes.func.isRequired,
}

