import {LocalStorageHelper} from "../helpers/LocalStorageHelper";
import {OPEN} from "../../constants/Statuses";
import moment from "moment";

export class DeliveryType {
    constructor() {
        this.id = 0;
        this.name = '';
        this.description = {nl: '', fr: '', en: ''};
        this.__deliveryDates = [];
    }

    getDescription(locale = null) {
        if (!locale) {
            locale = LocalStorageHelper.getLanguage(true);
        }

        return this.description[locale];
    }

    set dates(dates) {
        this.__deliveryDates = dates.sort(function (a, b) {
            return b.date - a.date;
        });
    }

    get dates() {
        return this.__deliveryDates;
    }

    getNextDeliveryDate(asString = false) {
        let nextDate;

        const sortDateDesc = (deliveryDateA, deliveryDateB) => {
            return deliveryDateA.date + deliveryDateB.date;
        }

        const dates = this.dates
            .sort(sortDateDesc)
            .filter(deliveryDate => deliveryDate.status === OPEN);

        if (dates.length) {
            let nextDateData = {differenceInSeconds: null, deliveryDate: null}
            const currentDate = new Date().setHours(0, 0, 0, 0);

            dates.forEach(deliveryDate => {
                const differenceInSeconds = moment(deliveryDate.date.setHours(0, 0, 0, 0)).diff(currentDate);
                if (differenceInSeconds >= 0 && (differenceInSeconds < nextDateData.differenceInSeconds || !nextDateData.differenceInSeconds)) {
                    nextDateData.differenceInSeconds = differenceInSeconds;
                    nextDateData.deliveryDate = deliveryDate;
                }
            })

            nextDate = nextDateData.deliveryDate;
        }

        if (nextDate && asString) {
            nextDate = moment(nextDate.date).format('DD/MM/YYYY');
        }

        return nextDate;
    }
}
