import {BACK, FRONT, LEFT, RIGHT} from "../../constants/ObjectSides";
import {createLineWithText} from "../helpers/measurementLines/LineHelper";
import OperationsSorter from "../helpers/measurementLines/OperationsSorter";
import {
    getPointsForEmptySpacesBetweenOperationsForFrontSide,
    getSidePointsForOperation
} from "../helpers/measurementLines/SideMeasurementLineHelper";
import {calculateFontSizeByObjectLength} from "../helpers/ImageHelper2D";
import {REAR_HEEL_CUSTOM} from "../../constants/ObjectTypes";
import {getPointsForRearHeel} from "../helpers/measurementLines/pieces/RearHeelMeasurementLineHelper";

class SideMeasurementLineCreator {

    constructor() {
        this.__clearMemberVariables();
    }

    createMeasurementLinesForSides(piece, configuration, font, aspect) {
        const {dimensions, operations, dimensionRatio} = piece;
        this.fontSettings = {
            type: font,
            size: calculateFontSizeByObjectLength(dimensions.length),
            multiplier: dimensionRatio
        }

        if (dimensions.length < 1 && dimensions.width < 1) return [];

        this.configuration = configuration;

        this.__sortOperations(operations);
        if (aspect === LEFT) this.__calculatePointsOperationsToShowOnLeft(piece);
        if (aspect === RIGHT) this.__calculatePointsOperationsToShowOnRight(piece);
        if ([FRONT, BACK].includes(aspect)) this.__calculatePointsOperationsToShowOnFront(piece, aspect);
        if (configuration?.options.type === REAR_HEEL_CUSTOM) this.__calculatePointsForRearHeelCustom(dimensions);

        const linesWithText = this.__createLinesWithText();

        this.__clearMemberVariables();

        return linesWithText?.filter(line => line !== undefined);
    }

    getPointsForLines(pointsForSide, piece) {
        let horizontalPoints = [];
        let verticalPoints = [];

        pointsForSide.forEach(operation => {
            const operationPoints = getSidePointsForOperation(piece, operation, this.configuration.options.preset);

            if (operationPoints.horizontal) {
                if (operationPoints.horizontal.length) {
                    horizontalPoints.push(...operationPoints.horizontal);
                } else {
                    horizontalPoints.push(operationPoints.horizontal);
                }
            }
            if (operationPoints.vertical) {
                if (operationPoints.vertical.length) {
                    verticalPoints.push(...operationPoints.vertical);
                } else {
                    verticalPoints.push(operationPoints.vertical);
                }
            }
        });

        let pointMap = new Map();
        pointMap.set('horizontalPoints', horizontalPoints);
        pointMap.set('verticalPoints', verticalPoints);

        return pointMap;
    }

    __sortOperations(operations) {
        const operationsSorter = new OperationsSorter();
        const sortedOperations = operationsSorter.sortOperationsToShowOnSides(operations);

        // Sort the operations by position
        this.operationsShownOnLeft = sortedOperations.left;
        this.operationsShownOnRight = sortedOperations.right;
        this.operationsShownOnFront = sortedOperations.front;
        // endregion
    }

    __clearMemberVariables() {
        this.configuration = null;

        this.operationsShownOnLeft = [];
        this.operationsShownOnRight = [];
        this.operationsShownOnFront = [];

        this.operationsShownOnLeftHorizontalPoints = [];
        this.operationsShownOnLeftVerticalPoints = [];

        this.operationsShownOnRightHorizontalPoints = [];
        this.operationsShownOnRightVerticalPoints = [];

        this.operationsShownOnFrontHorizontalPoints = [];
        this.operationsShownOnFrontVerticalPoints = [];
    }

    __calculatePointsOperationsToShowOnLeft(piece) {
        if (this.operationsShownOnLeft.length > 0) {
            const pointMap = this.getPointsForLines(this.operationsShownOnLeft, piece);

            this.operationsShownOnLeftHorizontalPoints = pointMap.get('horizontalPoints');
            this.operationsShownOnLeftVerticalPoints = pointMap.get('verticalPoints');
        }
    }

    __calculatePointsOperationsToShowOnRight(piece) {
        if (this.operationsShownOnRight.length > 0) {
            const pointMap = this.getPointsForLines(this.operationsShownOnRight, piece);

            this.operationsShownOnRightHorizontalPoints = pointMap.get('horizontalPoints');
            this.operationsShownOnRightVerticalPoints = pointMap.get('verticalPoints');
        }
    }

    __calculatePointsOperationsToShowOnFront(piece) {
        if (this.operationsShownOnFront.length > 0) {
            const pointMap = this.getPointsForLines(this.operationsShownOnFront, piece);

            this.operationsShownOnFrontHorizontalPoints = pointMap.get('horizontalPoints');
            this.operationsShownOnFrontVerticalPoints = pointMap.get('verticalPoints');
        }

        const pointsInBetween = getPointsForEmptySpacesBetweenOperationsForFrontSide(
            piece.dimensions,
            this.operationsShownOnFrontHorizontalPoints
        );

        this.operationsShownOnFrontHorizontalPoints = this.operationsShownOnFrontHorizontalPoints.concat(pointsInBetween);
        this.operationsShownOnFrontHorizontalPoints.sort((pointsA, pointsB) => pointsA.pointA.x - pointsB.pointA.x);
    }

    __createLinesWithText() {
        let linesWithText = [];

        linesWithText.push(...createLineWithText(
            this.fontSettings,
            this.operationsShownOnLeftHorizontalPoints,
            this.operationsShownOnLeftVerticalPoints,
            {sideOfLine: RIGHT, cameraAngle: LEFT, preset: this.configuration.options.preset},
            false
        ));

        linesWithText.push(...createLineWithText(
            this.fontSettings,
            this.operationsShownOnRightHorizontalPoints,
            this.operationsShownOnRightVerticalPoints,
            {sideOfLine: LEFT, cameraAngle: RIGHT, preset: this.configuration.options.preset},
            false
        ));

        linesWithText.push(...createLineWithText(
            this.fontSettings,
            this.operationsShownOnFrontHorizontalPoints,
            this.operationsShownOnFrontVerticalPoints,
            {sideOfLine: FRONT, cameraAngle: FRONT, preset: this.configuration.options.preset},
            false
        ));

        return linesWithText;
    }

    __calculatePointsForRearHeelCustom(dimensions) {
        const points = getPointsForRearHeel(dimensions);

        this.operationsShownOnLeftHorizontalPoints = [points.horizontal];
        this.operationsShownOnLeftVerticalPoints = [points.vertical];
    }

}

export {SideMeasurementLineCreator}
