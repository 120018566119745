import {Service} from "./Service";
import {Parser} from "../helpers/Parser";

export class SettingService extends Service {
    async getAll() {
        return await this.authenticatedRequest('GET', 'settings')
            .then(response => {
                if (response.data.success) {
                    response.data.data = response.data.data.map(settingData => {
                        return Parser.parseSetting(settingData);
                    })
                }

                return response.data;
            })
    }

    async update(key, value) {
        return await this.authenticatedRequest('PUT', 'settings', {key, value})
            .then(response => {
                if (response.data.success) {
                    response.data.data = Parser.parseSetting(response.data.data);
                }

                return response.data;
            })
    }
}
