import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {AddressChooser} from "./AddressChooser";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {AddressModal} from "../../modals/AddressModal";
import {addAlertMessage} from "../../../actions/GeneralActions";
import {DANGER, SUCCESS} from "../../../constants/Variants";
import {useDispatch, useSelector} from "react-redux";
import {AddressService} from "../../../classes/services/AddressService";
import {ConfirmationModal} from "../../modals/ConfirmationModal";
import {Address} from "../../../classes/models/Address";
import {LoadingMessage} from "../../messages/LoadingMessage";
import {COLLECT, DELIVERY} from "../../../constants/DeliveryOptions";
import {Title} from "../../headers/Title";

export const DeliveryChooser = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.deliveryManagement.';

    const [addresses, setAddresses] = useState([]);
    const [addressesAreLoading, setAddressesAreLoading] = useState(false);
    const [addressModalIsActive, setAddressModalIsActive] = useState(false);
    const [deleteAddressModalIsActive, setDeleteAddressModalIsActive] = useState(false);
    const [addressToEdit, setAddressToEdit] = useState(null);

    const {currentCompanyBranch} = useSelector(state => state.companyReducer);

    const dispatch = useDispatch();

    const addressService = new AddressService();

    useEffect(() => {
        setAddressesAreLoading(true);

        addressService.getAll(currentCompanyBranch?.id)
            .then(response => {
                if (response.success) {
                    setAddresses(response.data);

                    if (!props.selectedAddress && response.data.length > 0) {
                        props.setSelectedAddress(response.data[0]);
                    }
                } else {
                    dispatch(addAlertMessage(DANGER, t(prefix + 'getAddressesFailed')))
                }
            })
            .catch(() => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'getAddressesFailed')));
            })
            .finally(() => setAddressesAreLoading(false));
    }, [currentCompanyBranch]);

    const createAddress = (address) => {
        setAddressesAreLoading(true);

        addressService.create(address, currentCompanyBranch?.id)
            .then(response => {
                if (response.success) {
                    setAddresses(addresses.concat([response.data]));
                    props.setSelectedAddress(response.data);

                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'addAddressSuccess')));
                } else {
                    throw Error(t(prefix + 'addAddressFailed'))
                }
            })
            .catch(() => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'addAddressFailed')));
            })
            .finally(() => setAddressesAreLoading(false));
    }

    const editAddress = (address) => {
        setAddressesAreLoading(true);

        addressService.update(address)
            .then(response => {
                if (response.success) {
                    setAddresses(addresses.map(a => {
                        if (a.id === address.id) {
                            return address;
                        }

                        return a;
                    }));

                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateAddressSuccess')))
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'updateAddressFailed')));

                throw error;
            })
            .finally(() => setAddressesAreLoading(false));
    }

    const deleteAddress = () => {
        setAddressesAreLoading(true);

        addressService.delete(props.selectedAddress.id)
            .then(response => {
                if (response.success) {
                    setAddresses(addresses.filter(address => address.id !== props.selectedAddress.id));

                    props.setSelectedAddress(null);
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'deleteAddressFailed')));

                throw error;
            })
            .finally(() => setAddressesAreLoading(false));
    }

    const updateSelectedAddress = (address) => {
        props.setSelectedAddress(address);
    }

    const openAddressModal = (address = null) => {
        if (address) {
            setAddressToEdit(address);
        }

        setAddressModalIsActive(true);
    }

    const closeAddressModal = (address = null) => {
        if (address) {
            if (addressToEdit) {
                // Edit address
                editAddress(address);
            } else {
                // Create new address
                createAddress(address);
            }
        }

        setAddressToEdit(null);
        setAddressModalIsActive(false);
    }

    const openDeleteAddressModal = (address) => {
        props.setSelectedAddress(address);
        setDeleteAddressModalIsActive(true);
    }

    const closeDeleteAddressModal = (hasAccepted) => {
        if (hasAccepted) {
            deleteAddress();
        }

        setDeleteAddressModalIsActive(false);
    }

    return (
        <div>
            <ConfirmationModal
                isActive={deleteAddressModalIsActive}
                onClose={(hasAccepted) => closeDeleteAddressModal(hasAccepted)}
                content={t(prefix + 'deleteAddressConfirmation')}/>

            <AddressModal
                address={addressToEdit}
                isActive={addressModalIsActive}
                onClose={(address) => closeAddressModal(address)}
            />

            <Title dataCy={props.dataCy} title={t(prefix + 'title')}/>

            <div className="p-l-2 p-r-2 m-b-2">

                <p className="m-b-2">
                    <strong className="color--red">{t(prefix + 'warningPrefix')}</strong> {t(prefix + 'deliveryWarning')}
                </p>

                <div className="form-group form-group--toggle">
                    <label>
                        <input
                            data-cy="deliveryChooser-collect"
                            type="radio"
                            name="delivery"
                            onChange={() => props.setDeliveryOption(COLLECT)}
                            checked={props.deliveryOption === COLLECT}
                        />
                        {t(prefix + 'collect')}
                    </label>
                </div>
                <div className="form-group form-group--toggle">
                    <label style={props.isNotDeliverable ? {color: "lightgray"} : {}}>
                        <input
                            data-cy="deliveryChooser-delivery"
                            type="radio"
                            name="delivery"
                            onChange={() => props.setDeliveryOption(DELIVERY)}
                            checked={props.deliveryOption === DELIVERY}
                            disabled={props.isNotDeliverable}
                        />
                        {t(prefix + 'delivery')}
                    </label>
                    {!!props.isNotDeliverable && <small className='color--red m-t-1'>{t(prefix + 'notDeliverable')}</small>}
                </div>
            </div>

            {
                props.deliveryOption === DELIVERY ?
                    addressesAreLoading ? <LoadingMessage variant="inline"/> :
                        <>
                            <Title title={t(prefix + 'title')}/>

                            <div className="p-l-2 p-r-2 m-b-2">
                                <AddressChooser
                                    addresses={addresses}
                                    selectedAddress={props.selectedAddress}
                                    onAddressClick={(address) => updateSelectedAddress(address)}
                                    onAddressAdd={() => openAddressModal()}
                                    onEdit={(address) => openAddressModal(address)}
                                    onDelete={(address => openDeleteAddressModal(address))}
                                />
                            </div>
                        </>
                    :
                    null
            }
        </div>
    );
};

DeliveryChooser.propTypes = {
    deliveryOption: PropTypes.string.isRequired,
    setDeliveryOption: PropTypes.func.isRequired,
    selectedAddress: PropTypes.instanceOf(Address),
    setSelectedAddress: PropTypes.func.isRequired,
    dataCy: PropTypes.string,
    isNotDeliverable: PropTypes.bool.isRequired,
}
