// Colors
export const DEFAULT_OBJECT_COLOR = 0xd7dee6;
export const HIGHLIGHTED_OBJECT_COLOR = 0x7FB6FF;
export const HIGHLIGHTED_LINE_COLOR = 0x006EFF;
export const DEFAULT_LINE_COLOR = 0x000000;
export const MEASUREMENT_LINE = 0x333333;
export const BACKGROUND_COLOR = 0xF8F9FC;
export const STANDARD_ARROW_COLOR = 0x7D807C;
export const EXTRA_ARROW_COLOR = 0xDE4D2B;

export const HIGHLIGHT = 0xEBC634;
export const PICKED = 0xEB4034;

export const TEXT = 0x1D1E1E;
export const HIGHLIGHTED_TEXT = 0xFFFFFF;

// Fontsizes
export const PIECE_NAME_FONT_SIZE = 2;