export const DUTCH = 'DUTCH';
export const FRENCH = 'FRENCH';
export const ENGLISH = 'ENGLISH';

export const DUTCH_SHORT = 'nl';
export const FRENCH_SHORT = 'fr';
export const ENGLISH_SHORT = 'en';

export const LANGUAGE_LIST = [
    DUTCH,
    FRENCH,
    ENGLISH,
];

export const LANGUAGE_SHORT_LIST = [
    DUTCH_SHORT,
    FRENCH_SHORT,
    ENGLISH_SHORT,
];
