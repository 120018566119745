import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {useDispatch, useSelector} from "react-redux";
import {setActiveOperationModal} from "../../../actions/GeneralActions";
import {Piece} from "../../../classes/models/Piece";
import {setCurrentPiece} from "../../../actions/OfferActions";
import {DropdownDivider} from "../../headers/DropdownDivider";
import {DIVIDE_PIECE, DIVIDE_PIECE_MODAL_NAME, PIECE} from "../../../constants/PieceActionTypes";
import {getOperationIcon} from "../../../classes/helpers/OperationHelper";
import {REAR_HEEL_TYPES} from "../../../constants/ObjectTypes";
import {CUSHIONS, LINTELS, PILLARS} from "../../../constants/Presets";

export const PieceListItemActions = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.configurator.configurationPieceList.operationActions.';
    const constantsPrefix = 'constants.operationTypes.';
    const modalTypeConstantsPrefix = 'constants.pieceActionTypes.'

    const [selectableOperations, setSelectableOperations] = useState([]);

    const currentConfiguration = useSelector(state => state.offerReducer.currentConfiguration);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentConfiguration) {
            setSelectableOperations(currentConfiguration.getSelectableOperationTypes());
        }
    }, [currentConfiguration]);

    const openModal = (type) => {
        dispatch(setActiveOperationModal(type));
        dispatch(setCurrentPiece(props.piece));
    }

    return (
        <div className="configuration__objects__actions">
            <span className="configuration__objects__actions__item">
                <button
                    data-cy="pieceListItemActions-addButton"
                    className="button button--icon button--light dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <strong>{t(prefix + 'content')}</strong> <i className="material-icons">keyboard_arrow_down</i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {
                        selectableOperations.map((type, index) => {
                            return <span
                                data-cy={`pieceListItemActions-${type.toLowerCase()}Button`}
                                key={`selectableOperations${index}`}
                                className="pointer dropdown-item"
                                onClick={() => openModal(type)}
                            >
                                    <img src={getOperationIcon(type)} alt={type}/>{t(constantsPrefix + type)}
                            </span>
                        })
                    }

                    {selectableOperations.length && <DropdownDivider/>}

                    {
                        !!(!REAR_HEEL_TYPES.includes(currentConfiguration?.options.type) &&
                            ![CUSHIONS, LINTELS, PILLARS].includes(currentConfiguration?.options.preset)) &&
                        <span
                            data-cy="pieceListItemActions-pieceButton"
                            key={PIECE}
                            className="pointer dropdown-item"
                            onClick={() => openModal(PIECE)}
                        >
                                {t(modalTypeConstantsPrefix + PIECE)}
                            </span>
                    }

                    {
                        currentConfiguration?.options.preset !== CUSHIONS &&
                        <span
                            data-cy="pieceListItemActions-dividePieceButton"
                            key={DIVIDE_PIECE}
                            className="pointer dropdown-item"
                            onClick={() => openModal(DIVIDE_PIECE)}
                        >
                                {t(modalTypeConstantsPrefix + DIVIDE_PIECE_MODAL_NAME)}
                            </span>
                    }
                </div>
            </span>
        </div>
    );
};

PieceListItemActions.propTypes = {
    piece: PropTypes.instanceOf(Piece).isRequired,
    disabledActions: PropTypes.array,
}

PieceListItemActions.defaultProps = {
    disabledActions: [],
}
