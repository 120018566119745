import produce from 'immer';
import * as ActionTypes from '../constants/ActionTypes';

const initialState = {
    images: [],
};

function galleryImageReducer(state = initialState, action) {
    return produce(state, draftState => {
        switch (action.type) {
            case ActionTypes.SET_IMAGES:
                draftState.images = action.images.map(image => image);
                break;
            default:
                break;
        }
    });

}

export default galleryImageReducer;