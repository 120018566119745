import React, {useEffect, useState} from 'react';
import {CompanyModal} from "../../../modals/CompanyModal";
import CompanyService from "../../../../classes/services/CompanyService";
import {addAlertMessage, setWindowIsLoading} from "../../../../actions/GeneralActions";
import {DANGER} from "../../../../constants/Variants";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../../constants/TranslationConstants";
import {SearchBar} from "../../../navigation/SearchBar";
import {AppHeader} from "../../../headers/AppHeader";
import {COMPANIES, COMPANIES_URL_NAME} from "../../../../constants/AdminPages";
import {CompanyDetail} from "./CompanyDetail";
import Company from "../../../../classes/models/Company";
import {useHistory, useParams} from "react-router-dom";
import {AppContent} from "../../../layout/AppContent";
import {sortObjectsAlphabeticallyByName} from "../../../../classes/helpers/SortingHelper";
import {setCompany, setCurrentCompanyBranch} from "../../../../actions/CompanyActions";
import {LocalStorageHelper} from "../../../../classes/helpers/LocalStorageHelper";

export const CompanyList = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.admin.companyList.';
    const appHeaderPrefix = 'headers.appHeader.';
    const constantsPrefix = 'constants.adminPages.';

    const [companies, setCompanies] = useState([]);
    const [displayedCompanies, setDisplayedCompanies] = useState([]);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [searchBarInput, setSearchBarInput] = useState('');
    const [companyModalIsActive, setCompanyModalIsActive] = useState(false);

    const {page: pageName, id: companyUrlId} = useParams();
    const history = useHistory();

    const {company: currentUsersCompany, currentCompanyBranch} = useSelector(state => state.companyReducer);
    const {currentUser} = useSelector(state => state.generalReducer);

    const companyService = new CompanyService();

    const dispatch = useDispatch();

    useEffect(() => {
        getCompanies();
    }, []);

    useEffect(() => {
        if (companies.length <= 0) return;

        const company = companies.find(c => c.id === parseInt(companyUrlId));

        if (company) setCurrentCompany(company);
        else {
            updateCompanyIdInUrl();
            setCurrentCompany(null);
        }
    }, [companyUrlId]);

    useEffect(() => {
        let companiesToDisplay;

        if (searchBarInput) {
            companiesToDisplay = companies.filter(company =>
                company.name.toLowerCase().trim().includes(searchBarInput.toLowerCase().trim())
            );
        } else {
            companiesToDisplay = companies;
        }

        setDisplayedCompanies(companiesToDisplay.sort(sortObjectsAlphabeticallyByName));
    }, [companies, searchBarInput])

    const getCompanies = () => {
        dispatch(setWindowIsLoading(true));

        companyService.getAll()
            .then(response => {
                if (response.success) {
                    setCompanies(response.data);

                    if (companyUrlId) {
                        const companyWithUrlId = response.data.find(company => company.id === parseInt(companyUrlId));

                        if (companyWithUrlId) setCurrentCompany(companyWithUrlId);
                        else updateCompanyIdInUrl();
                    }
                } else {
                    throw Error(response.message);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'getCompaniesFailed')));

                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const updateCompanyIdInUrl = (id) => {
        if (pageName !== COMPANIES_URL_NAME) return;

        if (id) history.push(`/admin/${COMPANIES_URL_NAME}/${id}`);
        else history.push(`/admin/${COMPANIES_URL_NAME}`);
    }

    const updateCompanyState = (company) => {
        if (currentUsersCompany?.id === company.id) {
            dispatch(setCompany(company));
        }

        if (currentCompany && company.id === currentCompany.id) {
            // Update
            setCompanies(companies.map(c => {
                if (c.id === company.id) {
                    c = Object.assign(c, company);

                    c.branches.forEach(branch => {
                        if (branch.id === currentCompanyBranch.id) {
                            dispatch(setCurrentCompanyBranch(branch));
                        }
                    })
                }

                return c;
            }));
        } else {
            // Add
            setCompanies(companies.concat([company]));
        }
    }

    const deleteCompanyBranchFromCompany = (company, branch) => {
        setCompanies(companies.map(c => {
            if (c.id === company.id) {
                c.branches = c.branches.filter(b => b.id !== branch.id);
            }

            return c;
        }));
    }

    const openCompanyModal = (company) => {
        if (!company) {
            company = new Company();
            updateCompanyIdInUrl(company.id);
        }

        setCompanyModalIsActive(true);
    }

    const closeCompanyModal = (company) => {
        if (company) {
            updateCompanyState(company);
        }

        setCompanyModalIsActive(false);
    }

    const renderList = () => {
        return <>
            <SearchBar onInput={(input) => setSearchBarInput(input)}/>

            <div className="list">
                {
                    displayedCompanies.map(company => {
                        return <div
                            key={company.id}
                            className="pointer list__item" onClick={() => updateCompanyIdInUrl(company.id)}
                        >
                            <span className="list__item__icon">
                                {company.hasBranchThatCanOrder() && <i className="material-icons">shopping_cart</i>}
                            </span>
                            <div className="list__item__content">
                                {company.name}
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    }

    const renderAppHeader = () => {
        if (currentCompany?.id) {
            return <AppHeader title={currentCompany.name} onBackClick={() => updateCompanyIdInUrl(null)}>
                <button type="button" className="button button--icon-before"
                        onClick={() => openCompanyModal(currentCompany)}>
                    <i className="material-icons">edit</i> {t(appHeaderPrefix + 'actions.editOfferTemplate')}
                </button>
            </AppHeader>
        } else {
            return <AppHeader title={t(constantsPrefix + COMPANIES)}>
                <button type="button" className="button button--icon-before" onClick={() => openCompanyModal()}>
                    <i className="material-icons">add</i> {t(prefix + 'addCompanyButton')}
                </button>
            </AppHeader>;
        }
    }

    return (
        <AppContent>
            <CompanyModal
                isActive={companyModalIsActive}
                company={currentCompany}
                onClose={(company) => closeCompanyModal(company)}
            />

            {renderAppHeader()}

            {currentCompany && currentCompany.id > 0 ?
                <CompanyDetail
                    company={currentCompany}
                    updateCompanyState={(company) => updateCompanyState(company)}
                    deleteCompanyBranch={(company, branch) => deleteCompanyBranchFromCompany(company, branch)}
                />
                : renderList()}
        </AppContent>
    );
};

CompanyList.propTypes = {}
