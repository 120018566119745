import * as React from 'react';
import PropTypes from 'prop-types';
import {SectionTitle} from "../../headers/SectionTitle";
import {ValidatedInput} from "../../forms/inputs/ValidatedInput";
import {Configuration} from "../../../classes/models/Configuration";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";

export const Type9And10ProfileForm = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.shared.type9And10ProfileForm.';

    const noop = () => {
        console.log('noop');
    };

    return (
        <>
            <SectionTitle content={t(prefix + 'profileTitle')}/>

            <div className="row">
                <div className="col-6">
                    <ValidatedInput
                        register={props.formRegister ?? noop}
                        name="profileHeight"
                        label={t(prefix + 'profileHeightLabel')}
                        placeholder="2 cm"
                        required={true}
                        min={0.8}
                        max={props.maxHeight}
                        value={props.dimensions ? props.dimensions.height : ''}
                        error={props.formErrors?.profileHeight}
                        disabled={props.disabled}
                    />
                </div>
                <div className="col-6">
                    <ValidatedInput
                        register={props.formRegister ?? noop}
                        name="profileDepth"
                        label={t(prefix + 'profileDepthLabel')}
                        placeholder="2 cm"
                        required={true}
                        min={1}
                        max={20}
                        value={props.dimensions ? props.dimensions.width : ''}
                        error={props.formErrors?.profileDepth}
                        disabled={props.disabled}
                    />
                </div>
            </div>
        </>
    );
};

Type9And10ProfileForm.propTypes = {
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    formRegister: PropTypes.func,
    formErrors: PropTypes.object,
    dimensions: PropTypes.object,
    disabled: PropTypes.bool,
}
