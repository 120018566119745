import * as React from 'react';
import PropTypes from 'prop-types';
import {Configuration} from "../../../classes/models/Configuration";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {DANGER} from "../../../constants/Variants";
import {ActionsDropdownItem} from "../../dropdowns/items/ActionsDropdownItem";
import {ActionsDropdown} from "../../dropdowns/ActionsDropdown";
import {COLORS_REMOVED_FROM_PRODUCT_RANGE} from "../../../constants/MaterialColors";
import {RemovedProductsBadge} from "../../shared/RemovedProductsBadge";

export const ConfigurationItem = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.configurationList.';
    const materialColorPrefix = 'constants.materialColors.';

    const priceIsLoading = useSelector(state => state.generalReducer.priceIsLoading);

    const renderPrice = () => {
        let content;

        if (priceIsLoading) {
            content = '...';
        }

        if (props.configuration.price) {
            content = `€ ${props.configuration.price}`;
        }

        return content;
    }

    const renderStockBadge = () => {
        let text;

        if (props.configuration.options.isConfiguredForStock()) {
            text = t(prefix + 'stockBadge');
        }

        return text ? <small className="badge badge--blue m-r-1">{text}</small> : null;
    }

    const renderRemovedProductsFromProductLineBadge = () => {
        if (COLORS_REMOVED_FROM_PRODUCT_RANGE.includes(props.configuration.options.materialColor)) {
            return <RemovedProductsBadge
                removedProducts={t(materialColorPrefix + props.configuration.options?.materialColor)}
            />
        }
    }

    return (
        <tr data-cy={`configurationItem-${props.configuration.id}-item`} className="offer__table__level0">
            <td colSpan="2" className="pointer table__border--right--white" onClick={() => props.editConfiguration()}>
                <div className="p-t-1 p-b-1">
                    <span className="stock--badge">
                        {renderRemovedProductsFromProductLineBadge()}
                        {renderStockBadge()}
                        <strong>{t(prefix + 'configuration')}: {props.configuration.name}</strong><br/>
                    </span>
                    <small>{props.configuration.getOptionsSummary()}</small>
                </div>
            </td>
            <td colSpan="2" className="offer__table__config__price table__border--right--white">{renderPrice()}</td>
            <td>
                {
                    props.allowChanges &&
                    <ActionsDropdown dataCy="configurationItem-actionsDropdown" variant="DARK">
                        <ActionsDropdownItem
                            dataCy="configurationItem-actionsDropdownItem-edit"
                            content={t(prefix + 'edit')}
                            onClick={() => props.editConfiguration()}
                        />
                        <ActionsDropdownItem
                            dataCy="configurationItem-actionsDropdownItem-delete"
                            content={t(prefix + 'delete')}
                            variant={DANGER}
                            onClick={() => props.deleteConfiguration()}
                        />
                    </ActionsDropdown>
                }
            </td>
        </tr>
    );
};

ConfigurationItem.propTypes = {
    configuration: PropTypes.instanceOf(Configuration).isRequired,
    editConfiguration: PropTypes.func.isRequired,
    deleteConfiguration: PropTypes.func.isRequired,
    allowChanges: PropTypes.bool,
}

