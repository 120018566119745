import Cookies from "js-cookie";
import {ADMIN, SUPERVISOR} from "../../constants/RoleNames";

export const roleIsSet = () => {
    return Cookies.get('role') == true;
}

export const isAdmin = () => {
    return Cookies.get('role') === ADMIN;
}

export const isSupervisor = () => {
    return Cookies.get('role') === SUPERVISOR;
}

export const hasOneOfRoles = (roles) => {
    return roles.includes(Cookies.get('role'));
}
