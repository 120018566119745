import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {SectionTitle} from "../headers/SectionTitle";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {DeliveryTypeService} from "../../classes/services/DeliveryTypeService";
import {useDispatch, useSelector} from "react-redux";
import {addAlertMessage, setWindowIsLoading} from "../../actions/GeneralActions";
import {captureMessage} from "@sentry/react";
import {DANGER, DATE_PICKER, LIST_ITEM, SUCCESS} from "../../constants/Variants";
import {Order} from "../../classes/models/Order";
import moment from "moment";
import {SidebarListItem} from "../lists/items/SidebarListItem";
import {CLOSED, OPEN} from "../../constants/Statuses";
import {OrderService} from "../../classes/services/OrderService";
import {setCurrentOrder, setOrders} from "../../actions/OrderActions";
import {ModalHolder} from "./ModalHolder";
import {OTHER} from "../../constants/DeliveryTypes";
import {DeliveryDateModal} from "./DeliveryDateModal";
import {DeliveryDate} from "../../classes/models/DeliveryDate";
import {DELIVERY} from "../../constants/OrderPhases";

export const OrderDeliveryDateModal = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.orderDeliveryDateModal.';
    const statusPrefix = 'constants.statuses.';
    const deliveryTypePrefix = 'constants.deliveryTypes.';

    const LIST = 'LIST';
    const SINGLE = 'SINGLE';
    const DATE_TO_BE_DETERMINED = 'DATE_TO_BE_DETERMINED';

    const [deliveryDates, setDeliveryDates] = useState([]);
    const [selectedDeliveryDate, setSelectedDeliveryDate] = useState(null);
    const [variant, setVariant] = useState(LIST);

    const {currentCompanyBranch} = useSelector(state => state.companyReducer);
    const {orders} = useSelector(state => state.orderReducer);

    const deliveryTypeService = new DeliveryTypeService();
    const orderService = new OrderService();
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.isActive) {
            let variantToSet;

            if (props.order?.deliveryType !== currentCompanyBranch?.defaultDeliveryOption || currentCompanyBranch?.deliveryType.name === OTHER) {
                variantToSet = SINGLE;
            } else {
                variantToSet = LIST;
            }

            // Make sure these do not get fetched when the variant is SINGLE
            if (variantToSet === LIST && props.isActive && deliveryDates.length < 1) {
                getDeliveryDates();
            }

            setVariant(variantToSet);
        } else {
            setDeliveryDates([]);
        }

    }, [props.isActive]);

    const getDeliveryDates = () => {
        dispatch(setWindowIsLoading(true));

        const deliveryTypeId = currentCompanyBranch?.deliveryType.id;

        deliveryTypeService.getDeliveryDatesByType(deliveryTypeId)
            .then(dates => {
                setDeliveryDates(dates)
            })
            .catch(error => {
                captureMessage(error.message);

                dispatch(addAlertMessage(DANGER, t(prefix + 'fetchDeliveryDatesFailed')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const updateDeliveryDateForOrder = (deliveryDate) => {
        dispatch(setWindowIsLoading(true));

        orderService.updateDeliveryDate(props.order.id, deliveryDate)
            .then(order => {
                const newOrder = props.order;
                newOrder.deliveryDate = order.deliveryDate;
                dispatch(setCurrentOrder(newOrder));
                dispatch(setOrders(
                    orders.map(o => {
                        if (o.id === order.id) {
                            o.deliveryDate = order.deliveryDate;
                        }

                        return o;
                    })
                ));

                dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateOrderDeliveryDateSuccess')));
                props.onClose();
            })
            .catch(error => {
                captureMessage(error.message);

                dispatch(addAlertMessage(DANGER, t(prefix + 'updateOrderDeliveryDateFailed')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const closeModal = (submitted, newDate) => {
        if (submitted) {
            if (newDate) {
                setSelectedDeliveryDate(null);

                if (newDate === DATE_TO_BE_DETERMINED) {
                    updateDeliveryDateForOrder();
                } else {
                    const newDeliveryDate = new DeliveryDate();
                    newDeliveryDate.date = newDate;
                    newDeliveryDate.status = CLOSED;

                    updateDeliveryDateForOrder(newDeliveryDate);
                }
            } else {
                updateDeliveryDateForOrder(selectedDeliveryDate);
            }
        } else {
            props.onClose();
        }
    }

    const getDeliveryType = () => {
        const deliveryType = currentCompanyBranch?.deliveryType.name;

        return deliveryType ? t(deliveryTypePrefix + deliveryType) : '';
    }

    const getDeliveryDate = () => {
        if (props.order && props.order.deliveryDate.id) {
            return moment(props.order.deliveryDate.date).format('DD/MM/YY');
        } else {
            return t(prefix + 'toBeDetermined');
        }
    }

    if (variant === SINGLE) {
        return (
            <DeliveryDateModal
                isActive={props.isActive}
                deliveryDate={props.order?.deliveryDate}
                showStatus={false}
                onClose={(submitted, newDate) => closeModal(submitted, newDate ?? DATE_TO_BE_DETERMINED)}
                variant={props.order?.deliveryType === DELIVERY ? DATE_PICKER.DATE : DATE_PICKER.DATETIME}
                showUnknownDateOption
            />
        );
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <Modal show={props.isActive} onHide={closeModal} animation={true}>
                <Modal.Header className="modal-header">
                    <Modal.Title className="modal-title">{t(prefix + 'header')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t(prefix + 'description')}</p>

                    <SectionTitle content={t(prefix + 'deliveryDatesTitle')}/>

                    <div className="row">
                        <div className="col-6">
                            {t(prefix + 'deliveryTypeLabel')} <strong>{getDeliveryType()}</strong>
                        </div>
                        <div className="col-6">
                            {t(prefix + 'currentDateLabel')} <strong>{getDeliveryDate()}</strong>
                        </div>
                    </div>

                    <div className="m-t-3">
                        {
                            deliveryDates.map(deliveryDate => {
                                return <SidebarListItem
                                    key={deliveryDate.id}
                                    content={deliveryDate.getReadableDate()}
                                    onClick={() => setSelectedDeliveryDate(deliveryDate)}
                                    active={selectedDeliveryDate ? deliveryDate.id === selectedDeliveryDate.id : deliveryDate.id === props.order?.deliveryDate.id}
                                >
                                    <span
                                        className={deliveryDate.status === OPEN ? 'badge badge--green' : 'badge badge--red'}>
                                        {t(statusPrefix + deliveryDate.status)}
                                    </span>
                                </SidebarListItem>
                            })
                        }

                        <SidebarListItem
                            content={t(prefix + 'chooseSpecificDateLabel')}
                            subText={t(prefix + 'chooseSpecificDateSubText')}
                            onClick={() => setVariant(SINGLE)}
                            variant={LIST_ITEM.ADD}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="button button--primary" type="button" onClick={() => closeModal(true)}>
                        {t(prefix + 'submitButton')}
                    </button>
                    <button className="button button--outline" type="button" onClick={() => closeModal()}>
                        {t(prefix + 'cancelButton')}
                    </button>
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
};

OrderDeliveryDateModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    order: PropTypes.instanceOf(Order)
}

OrderDeliveryDateModal.defaultProps = {
    order: null,
}
