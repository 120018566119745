import MeasurementPoints from "../../../models/MeasurementPoints";
import {getXPosForSide, getYPosForSide} from "../SurfaceMeasurementLineHelper";
import {Vector3} from "three";
import {FRONT, LEFT} from "../../../../constants/ObjectSides";

export function getPointsForCoupeOverLength(pieceDimensions, coupeOverLength) {

    const linePosition = {
        xPos: getXPosForSide(pieceDimensions, coupeOverLength.additionalDimension.type),
        yPos: getYPosForSide(pieceDimensions),
    };

    const horizontalPoints = new MeasurementPoints(
        getHorizontalPointA(pieceDimensions, coupeOverLength, linePosition),
        getHorizontalPointB(pieceDimensions, coupeOverLength, linePosition)
    );

    return {
        horizontal: horizontalPoints,
        vertical: null
    };
}

function getHorizontalPointA(pieceDimensions, coupeOverLength, {xPos, yPos}) {
    let zPos;

    if (coupeOverLength.side === FRONT) {
        if (coupeOverLength.additionalDimension.type === LEFT) {
            zPos = pieceDimensions.width / -2 + coupeOverLength.dimensions.width;
        } else {
            zPos = pieceDimensions.width / 2;
        }
    } else {
        if (coupeOverLength.additionalDimension.type === LEFT) {
            zPos = pieceDimensions.width / -2;
        } else {
            zPos = pieceDimensions.width / 2 - coupeOverLength.dimensions.width;
        }
    }

    return new Vector3(
        xPos,
        yPos,
        zPos,
    );
}

function getHorizontalPointB(pieceDimensions, coupeOverLength, {xPos, yPos}) {
    let zPos;

    if (coupeOverLength.side === FRONT) {
        if (coupeOverLength.additionalDimension.type === LEFT) {
            zPos = pieceDimensions.width / 2
        } else {
            zPos = pieceDimensions.width / -2 + coupeOverLength.dimensions.width;
        }
    } else {
        if (coupeOverLength.additionalDimension.type === LEFT) {
            zPos = pieceDimensions.width / 2 - coupeOverLength.dimensions.width;
        } else {
            zPos = pieceDimensions.width / -2;
        }
    }

    return new Vector3(
        xPos,
        yPos,
        zPos,
    );
}
