import * as React from "react";
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {setActiveArticle, setArticleModalActive} from "../../../actions/OutdoorShopActions";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {OutdoorArticle} from "../../../classes/models/OutdoorArticle";
import {addUnitCode} from "../../../classes/helpers/OutdoorArticleHelper";
import icon400 from '../../../assets/icons/400x400.png'

export const ArticleItem = (props) => {
    const prefix = "pages.articles.";
    const {t} = useTranslation(TRANSLATION_NAMESPACE);

    const dispatch = useDispatch();

    const onItemClick = item => {
        dispatch(setArticleModalActive(true));
        dispatch(setActiveArticle(item));
    }

    return (
        <>
            <tbody className="pointer">
            <tr onClick={() => onItemClick(props.article)}>
                <td width="1">
                    <img src={icon400} alt="placeholder"/>
                </td>
                <td className="article__table-item">{props.article.getDescription()}</td>
                <td>
                    <small>{t(prefix + 'pallet')}: {addUnitCode(props.article, props.article.getPalletAmount())} - {props.article.getPalletWeight()}kg</small>
                </td>
                <td>
                    <strong>€ {addUnitCode(props.article, props.article.price, true)}</strong>
                </td>
                <td className="article__stockicon" aria-label={t(prefix + "inStockToolTipContent")}>
                    <i className="color--green material-icons"
                       data-tip={t(prefix + "inStockToolTipContent")}>check_circle</i>
                </td>
                <td width="1" aria-label={t(prefix + "cartTooltipContent")}>
                    <span className="button button--icon button--small">
                        <i className="material-icons" data-tip={t(prefix + "cartTooltipContent")}>shopping_cart</i>
                    </span>
                </td>
            </tr>
            </tbody>
        </>
    )
}

ArticleItem.propTypes = {
    article: PropTypes.instanceOf(OutdoorArticle),
}
