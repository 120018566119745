import React from "react";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {parseToCommaSeparated} from "../../classes/helpers/StringHelper";
import PropTypes from "prop-types";

const ArticleList = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);

    const prefix = "pages.offers.articleList.";

    const renderArticlePrice = (article) => {
        if (props.isInOrder) {
            return `€ ${parseToCommaSeparated(article.price, 2)}`;
        }
        return `€ ${parseToCommaSeparated(article.price?.total, 2)}`;
    };

    const renderPrice = () => {
        let price = 0;

        if (!props.outdoorConfigurations) return null;

        if (props.isInOrder) {
            props.outdoorConfigurations.forEach((config) => (price += config.price));
        } else {
            props.outdoorConfigurations.forEach(
                (config) => (price += config.price?.total)
            );
        }

        return `€ ${parseToCommaSeparated(price, 2)}`;
    };

    if (!props.outdoorConfigurations.length) return null;

    return (
        <div className="offer">
            <div className="offer__table__holder">
                <table className="offer__table article__table">
                    <tbody>
                    <tr className="offer__table__level0">
                        <td colSpan={5} className="table__border--right--white">
                            <div className="p-t-1 p-b-1">
                                <strong>{t(prefix + "article")}</strong>
                                <br/>
                            </div>
                        </td>
                    </tr>
                    <tr className="article__table__level1 article__table__header">
                        <td>{t(prefix + "title")}</td>
                        <td>{t(prefix + "articleName")}</td>
                        <td>{t(prefix + "articleAmount")}</td>
                        <td>{t(prefix + "configurationPrice")}</td>
                        {!props.isInOrder ? <td/> : null}
                    </tr>

                    {props.outdoorConfigurations.map((article) => (
                        <tr
                            className="article__table__level2 pointer"
                            key={article.id}
                            onClick={() => !props.isInOrder && props.updateArticle(article)}
                        >
                            <td>{article.title ?? "/"}</td>
                            <td>{article.article.name}</td>
                            <td>{parseToCommaSeparated(article.amount)}</td>
                            <td>{renderArticlePrice(article)}</td>
                            {!props.isInOrder && (
                                <td className="article__table__actions">
                                    {
                                        props.updateArticle ?
                                            <div
                                                className="pointer article__table__icon"
                                                data-tip={t(prefix + 'editTooltip')}
                                            >
                                                <i className="material-icons">mode_edit</i>
                                            </div> : null
                                    }
                                    {
                                        props.deleteArticle ?
                                            <div
                                                className="pointer article__table__icon article__table__icon--delete"
                                                onClick={(e) => props.deleteArticle(e, article)}
                                                data-tip={t(prefix + 'deleteTooltip')}
                                            >
                                                <i className="material-icons">delete</i>
                                            </div> : null
                                    }
                                </td>
                            )}
                        </tr>
                    ))}
                    <tr className="offer__table__summary">
                        <td colSpan={3}>
                            <strong>
                                {t("pages.offers.configurationList.priceTotal")}
                            </strong>
                        </td>
                        <td colSpan={2}>{renderPrice()}</td>
                    </tr>
                    </tbody>
                </table>

                <ReactTooltip/>
            </div>
        </div>
    );
};

ArticleList.propTypes = {
    outdoorConfigurations: PropTypes.array.isRequired,
    updateArticle: PropTypes.func,
    deleteArticle: PropTypes.func,
    isInOrder: PropTypes.bool,
};

ArticleList.defaultProps = {
    isInOrder: false,
};

export default ArticleList;
