import * as React from 'react';
import PropTypes from 'prop-types';

export const ModalHolder = (props) => {
    return (
        <>
            {
                props.isActive ? props.children : null
            }
        </>
    );
};

ModalHolder.propTypes = {
    isActive: PropTypes.bool.isRequired,
}
