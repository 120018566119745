import {Service} from "./Service";
import {Parser} from "../helpers/Parser";

export class CompanyBranchService extends Service {
    async getAll() {
        return await this.authenticatedRequest('get', 'companybranches')
            .then(response => {
                if (response.data.success) {
                    response.data.data = response.data.data.map(branchData => Parser.parseCompanyBranch(branchData));
                }

                return response.data;
            })
    }

    async getById(branchId) {
        return await this.authenticatedRequest('get', `companybranches/${branchId}`)
            .then(response => {
                if (response.data.success) {
                    response.data.data = Parser.parseCompanyBranch(response.data.data);
                }

                return response.data;
            })
    }

    async getCurrent() {
        return await this.authenticatedRequest('get', 'companybranches/current')
            .then(response => {
                if (response.data.success) {
                    response.data.data = Parser.parseCompanyBranch(response.data.data);
                }

                return response.data;
            })
    }

    async getCurrentLogo(branchId) {
        return await this.authenticatedFileRequest('get', `companybranches/${branchId}/logo`)
            .then(response => {
                if (response.status === 200) {
                    return new Blob(
                        [response.data],
                        {type: 'image/*'});
                } else {
                    return {status: response.status};
                }
            })
            .catch(error => {
                throw error;
            });
    }

    async getUsers(branchId) {
        return await this.authenticatedRequest('get', `companybranches/${branchId}/users`)
            .then(response => {
                if (response.data.success) {
                    response.data.data = response.data.data.map(userData => Parser.parseUser(userData));
                } else {
                    throw Error(response.data.message);
                }

                return response.data;
            })
    }

    async create(companyId, data) {
        return await this.authenticatedRequest('post', `companies/${companyId}/branches`, data)
            .then(response => {
                if (response.data.success) {
                    response.data.data = Parser.parseCompanyBranch(response.data.data);
                }

                return response.data;
            })
    }

    async update(companyId, companyBranchId, data) {
        return await this.authenticatedRequest('put', `companies/${companyId}/branches/${companyBranchId}`, data)
            .then(response => {
                if (response.data.success) {
                    response.data.data = Parser.parseCompanyBranch(response.data.data);
                }

                return response.data;
            })
    }

    async updateLogo(branchId, file) {
        let formData = new FormData();
        formData.append('file', file);

        return await this.authenticatedFileRequest('post', `companybranches/${branchId}/logo`, formData)
            .then(response => {
                if (response.status === 200) {
                    return new Blob(
                        [response.data],
                        {type: 'image/*'});
                } else {
                    return response.data;
                }
            });
    }

    async delete(companyId, companyBranchId, data) {
        return await this.authenticatedRequest('delete', `companies/${companyId}/branches/${companyBranchId}`)
            .then(response => {
                return response.data;
            })
    }
}
