import {
    Vector3
} from "three";
import MeasurementPoints from "../../models/MeasurementPoints";
import {createLineWithText} from "./LineHelper";
import {BACK, FRONT, LEFT, RIGHT} from "../../../constants/ObjectSides";
import {TYPE_3, TYPE_4, TYPE_5, TYPE_6, TYPE_7, TYPE_8} from "../../../constants/ObjectTypes";

export function getPointsForIntersection(font, pieceDimensions, type) {
    const distanceFromPiece = 0.5;
    const widthDistanceFromPiece = 1;
    const fontSettings = {
        size: 1,
        type: font
    };

    let lines = [];

    const widthPoints = __getWidthPoints(pieceDimensions, widthDistanceFromPiece);
    lines.push(...createLineWithText(fontSettings, [widthPoints], [], {LEFT}, false, true));

    const heightPoints = __getHeightPoints(pieceDimensions, distanceFromPiece, type);
    lines.push(...createLineWithText(fontSettings, [heightPoints], [], {LEFT}, false, true));

    const barWidthPoints = __getBarWidthPoints(pieceDimensions, distanceFromPiece, type);
    lines.push(...createLineWithText(fontSettings, [barWidthPoints], [], {FRONT}, false, true));

    const barHeightPoints = __getBarHeightPoints(pieceDimensions, distanceFromPiece, type);
    lines.push(...createLineWithText(fontSettings, [barHeightPoints], [], {FRONT}, false, true));

    if ([TYPE_3, TYPE_4].includes(type)) {
        const backsideHeightPoints = __getHeightPointsWithRearHeel(pieceDimensions, distanceFromPiece);
        lines.push(...createLineWithText(fontSettings, [backsideHeightPoints], [], {FRONT}, false, true));
    }

    if ([TYPE_5, TYPE_6, TYPE_7, TYPE_8].includes(type)) {
        const frontHeightPoints = __getPointsForFrontHeight(pieceDimensions, distanceFromPiece);
        lines.push(...createLineWithText(fontSettings, [frontHeightPoints], [], {FRONT}, false, true));
    }

    if ([TYPE_7, TYPE_8].includes(type)) {
        const cushionWidthPoints = __getCushionWidthPoints(pieceDimensions, distanceFromPiece);
        lines.push(...createLineWithText(fontSettings, [cushionWidthPoints], [], {LEFT}, false, true));

        if (pieceDimensions.protrusion > 0) {
            const protrusionPoints = __getProtrusionPoints(pieceDimensions, distanceFromPiece);
            lines.push(...createLineWithText(fontSettings, [protrusionPoints], [], {LEFT}, false, true));
        }
    }

    return lines;
}

function __getPointsForFrontHeight({length, width, height, frontHeight}, distanceFromPiece) {
    const pointA = new Vector3(
        -(length / 2) + 12.5,
        height / -2 + frontHeight,
        width / 2 + distanceFromPiece
    );

    const pointB = new Vector3(
        -(length / 2) + 12.5,
        height / -2,
        width / 2 + distanceFromPiece
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = FRONT;

    return measurementPoints;
}

function __getWidthPoints({length, height, width}, distanceFromPiece) {
    const xPos = length / -2 - distanceFromPiece;
    const yPos = height / -2;
    const zPosA = width / 2;
    const zPosB = width / -2;

    const pointA = new Vector3(
        xPos,
        yPos,
        zPosA,
    );

    const pointB = new Vector3(
        xPos,
        yPos,
        zPosB,
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = BACK;

    return measurementPoints;
}

function __getHeightPoints({length, height, width}, distanceFromPiece, type) {
    const yPosA = height / 2;
    const yPosB = height / -2;
    let xPos = -(length / 2);
    let zPos;

    if ([TYPE_3, TYPE_4].includes(type)) {
        xPos += 5
        zPos = width / 2 + distanceFromPiece
    } else {
        zPos = width / -2 - distanceFromPiece;
    }

    const pointA = new Vector3(
        xPos,
        yPosA,
        zPos
    );

    const pointB = new Vector3(
        xPos,
        yPosB,
        zPos
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = BACK;

    return measurementPoints;
}

function __getHeightPointsWithRearHeel({length, height, width, extrusion}, distanceFromPiece) {
    const yPosA = height / 2 + extrusion;
    const yPosB = height / -2;
    const xPos = -(length / 2);
    const zPos = width / -2 - distanceFromPiece;

    const pointA = new Vector3(
        xPos,
        yPosA,
        zPos
    );

    const pointB = new Vector3(
        xPos,
        yPosB,
        zPos
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = BACK;

    return measurementPoints;
}

function __getBarWidthPoints({length, height, width, barWidth, extrusion}, distanceFromPiece, type) {
    let xPos;
    let yPos;
    let zPosA, zPosB;

    if ([TYPE_3, TYPE_4].includes(type)) {
        xPos = length / -2 + 2.5;
        yPos = height / 2 + extrusion + distanceFromPiece;

        zPosA = width / -2;
        zPosB = width / -2 + barWidth;
    } else {
        xPos = length / -2 + 10;
        yPos = height / 2 + distanceFromPiece

        zPosA = -(width / 2)
        zPosB = -(width / 2) + barWidth;
    }

    const pointA = new Vector3(
        xPos,
        yPos,
        zPosA
    );

    const pointB = new Vector3(
        xPos,
        yPos,
        zPosB
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = RIGHT;

    return measurementPoints;
}

function __getBarHeightPoints({length, height, width, barWidth, extrusion}, distanceFromPiece, type) {
    let xPos;
    let yPosA, yPosB;
    let zPos;

    if ([TYPE_3, TYPE_4].includes(type)) {
        xPos = length / -2 + 5;

        yPosA = height / 2 + extrusion;
        yPosB = height / 2;

        zPos = -(width / 2) + barWidth + distanceFromPiece;
    } else {
        xPos = length / -2 + 12.5;

        yPosA = height / 2;
        yPosB = height / 2 - extrusion;

        zPos = width / -2 + barWidth + distanceFromPiece;
    }

    const pointA = new Vector3(
        xPos,
        yPosA,
        zPos,
    );

    const pointB = new Vector3(
        xPos,
        yPosB,
        zPos,
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = RIGHT;

    return measurementPoints;
}

function __getCushionWidthPoints({length, height, cushionWidth}, distanceFromPiece) {
    const pointA = new Vector3(
        -(length / 2),
        height / 2 + distanceFromPiece,
        0
    );

    const pointB = new Vector3(
        -(length / 2) + cushionWidth,
        height / 2 + distanceFromPiece,
        0
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = FRONT;

    return measurementPoints;
}

function __getProtrusionPoints({length, width, height, cushionWidth, protrusion}, distanceFromPiece) {
    const pointA = new Vector3(
        -(length / 2) + cushionWidth / 2,
        height / 2 + distanceFromPiece,
        width / 2
    );

    const pointB = new Vector3(
        -(length / 2) + cushionWidth / 2,
        height / 2 + distanceFromPiece,
        width / 2 - protrusion
    );

    const measurementPoints = new MeasurementPoints(pointA, pointB);
    measurementPoints.textSide = FRONT;

    return measurementPoints;
}
