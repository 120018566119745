import React from "react";
import {ArticleItem} from "../ArticleItem";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../../constants/TranslationConstants";
import {TableSectionTitle} from "../../../headers/TableSectionTitle";
import ReactTooltip from "react-tooltip";

const ArticleCategory = props => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE)
    const prefix = 'constants.outdoorArticleCategories.';

    return (
        <React.Fragment key={props.category}>
            <TableSectionTitle
                title={t(prefix + props.category.toUpperCase())}
                subTitle={t(prefix + props.subCategory.toUpperCase())}
            />

            <table className="article__table table--bordered pointer">
                {
                    props.articles.map(article => {
                        return <ArticleItem key={`article${article.id}`} article={article}/>
                    })
                }
            </table>

            <ReactTooltip/>
        </React.Fragment>
    )
}

ArticleCategory.propTypes = {
    category: PropTypes.string,
    subCategory: PropTypes.string,
    articles: PropTypes.array,
}

export default ArticleCategory
