class ClientType {
    constructor(id, name, discount, description) {
        this.id = id;
        this.name = name;
        this.discount = discount;
        this.description = description;
    }
}

export default ClientType;
