import ImageCreator from "../../../classes/services/ImageCreator";
import {addAlertMessage, setWindowIsLoading} from "../../../actions/GeneralActions";
import {OFFER, ORDER} from "../../../constants/PdfTypes";
import fileDownload from "js-file-download";
import {DANGER} from "../../../constants/Variants";
import {store} from "../../../store";
import {PdfService} from "../../../classes/services/PdfService";
import {Order} from "../../../classes/models/Order";

export function exportPdf(printable, failedMessage) {
    const pdfService = new PdfService();
    const {priceType} = store.getState().generalReducer;

    let imageCreator = new ImageCreator(printable?.configurations);
    const printableType = printable instanceof Order ? ORDER : OFFER;

    dispatch(setWindowIsLoading(true));

    imageCreator.getImages(false)
        .then(images => {
            return pdfService.getPdf(printable.id, printableType, images, priceType);
        })
        .then(response => {
            if (response.success) {
                const fileName = `${printable.name} - ${printable.timestamp.date}.pdf`;

                fileDownload(response.data, fileName);
            } else {
                throw Error(response.message);
            }
        })
        .catch(error => {
            dispatch(addAlertMessage(DANGER, failedMessage));
            dispatch(setWindowIsLoading(false));

            throw error
        })
        .finally(() => dispatch(setWindowIsLoading(false)));
}

export function exportSummary(order, failedMessage) {
    const pdfService = new PdfService();
    const {priceType} = store.getState().generalReducer;

    dispatch(setWindowIsLoading(true));

    pdfService.getSummary(order.id, priceType)
        .then(response => {
            if (response.success) {
                const fileName = `${order.name} - ${order.timestamp.date}.pdf`;

                fileDownload(response.data, fileName);
            } else {
                throw Error(response.message);
            }
        })
        .catch(error => {
            dispatch(addAlertMessage(DANGER, failedMessage));
            dispatch(setWindowIsLoading(false));

            throw error
        })
        .finally(() => dispatch(setWindowIsLoading(false)));

}

const dispatch = (action) => {
    store.dispatch(action);
}
