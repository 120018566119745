import React from 'react';
import PropTypes from 'prop-types';
import {ConfigurationList} from "../../lists/ConfigurationList";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {Title} from "../../headers/Title";
import ArticleList from "../../lists/ArticleList";
import {AppContent} from "../../layout/AppContent";
import {Order} from "../../../classes/models/Order";
import {OrderDetailAppHeader} from "./OrderDetailAppHeader";

export const OrderDetail = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = "pages.orders.";

    return (
        <AppContent>
            <OrderDetailAppHeader
                order={props.order}
                onEditClick={() => props.onEditOrderClick()}
                onDeliveryDateUpdateClick={() => props.onDeliveryDateUpdateClick()}
            />

            {
                !!(props.order && props.order.configurations.length) &&
                <>
                    <div className="m-l-4">
                        <Title>{t(prefix + 'configurationsListTitle')}</Title>
                    </div>

                    <ConfigurationList
                        configurations={props.order.configurations}
                    />
                </>
            }

            {
                !!(props.order && props.order.outdoorConfigurations.length) &&
                    <>
                        <div className="m-l-4">
                            <Title>{t(prefix + 'outdoorConfigurationsListTitle')}</Title>
                        </div>
                        <ArticleList
                            outdoorConfigurations={props.order.outdoorConfigurations}
                            isInOrder
                        />
                    </>
            }
        </AppContent>
    );
};

OrderDetail.propTypes = {
    order: PropTypes.instanceOf(Order),
    onEditOrderClick: PropTypes.func.isRequired,
    onDeliveryDateUpdateClick: PropTypes.func.isRequired,
};
