import * as React from 'react';
import PropTypes from 'prop-types';
import {Title} from "../../headers/Title";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../constants/TranslationConstants";
import {PAYMENT_METHODS} from "../../../constants/PaymentMethods";
import {RadioButton} from "../../forms/inputs/RadioButton";

export const PaymentMethodPicker = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.offers.';
    const paymentMethodPrefix = 'constants.paymentMethods.';

    return (
        <>
            <Title dataCy={props.dataCy}>{t(prefix + 'paymentMethodPickerTitle')}</Title>

            <div className="m-l-2">
                {
                    PAYMENT_METHODS.map(method => {
                        return <RadioButton
                            dataCy={`paymentMethodPicker-${method.toLowerCase()}`}
                            key={`paymentMethods${method}`}
                            name="paymentMethodRadioButton"
                            content={t(paymentMethodPrefix + method)}
                            checked={props.method === method}
                            marginBottom={1}
                            onChange={() => props.onChange(method)}
                        />
                    })
                }
            </div>
        </>
    );
};

PaymentMethodPicker.propTypes = {
    method: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
}
