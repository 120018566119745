import * as React from 'react';
import PropTypes from 'prop-types';
import {Modal} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../constants/TranslationConstants";
import {User} from "../../classes/models/User";
import {ValidatedInput} from "../forms/inputs/ValidatedInput";
import {EMAIL} from "../../constants/RegEx";
import {useEffect, useState} from "react";
import {CheckboxWithIcon} from "../forms/inputs/CheckboxWithIcon";
import {SectionTitle} from "../headers/SectionTitle";
import {addAlertMessage, setWindowIsLoading} from "../../actions/GeneralActions";
import {DANGER, SUCCESS} from "../../constants/Variants";
import {UserService} from "../../classes/services/UserService";
import {useDispatch} from "react-redux";
import {Message} from "../messages/Message";
import {cloneDeep} from 'lodash';
import {ModalHolder} from "./ModalHolder";

export const UserModal = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'modals.userModal.';

    const {register, errors, handleSubmit, reset} = useForm();

    const [isBanned, setIsBanned] = useState(false);
    const [message, setMessage] = useState('');

    const dispatch = useDispatch();

    const userService = new UserService();

    useEffect(() => {
        if (props.isActive) {
            setIsBanned(props.user.isBanned);
        } else {
            setMessage('');
            reset();
        }
    }, [props.isActive]);

    const handleErrors = (response) => {
        if (response.errors?.email) {
            setMessage(t(prefix + 'emailAlreadyTaken'));
        } else {
            throw Error(response.message);
        }
    }

    const createUser = (user) => {
        clearMessage();
        dispatch(setWindowIsLoading(true));

        userService.create(props.companyId, props.companyBranchId, user)
            .then(response => {
                if (response.success) {
                    props.onClose(response.data);

                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'createUserSuccess')));
                } else {
                    handleErrors(response);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'createUserFailed')));
                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const updateUser = (user) => {
        clearMessage();
        dispatch(setWindowIsLoading(true));

        userService.update(props.companyId, user)
            .then(response => {
                if (response.success) {
                    props.onClose(user);

                    dispatch(addAlertMessage(SUCCESS, t(prefix + 'updateUserSuccess')));
                } else {
                    handleErrors(response);
                }
            })
            .catch(error => {
                dispatch(addAlertMessage(DANGER, t(prefix + 'updateUserFailed')));
                throw error;
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const onClose = (fields) => {
        if (fields) {
            fields.isBanned = isBanned;
            let tempUser = cloneDeep(props.user);

            if (props.user.id) {
                updateUser(Object.assign(tempUser, fields));
            } else {
                createUser(Object.assign(tempUser, fields));
            }
        } else {
            props.onClose();
        }
    }

    const clearMessage = () => {
        if (message !== '') {
            setMessage('');
        }
    }

    return (
        <ModalHolder isActive={props.isActive}>
            <Modal show={props.isActive} onHide={() => onClose(false)} animation={true} className="modal--prompt">
                <Modal.Header>
                    <Modal.Title>{props.user?.id > 0 ? t(prefix + 'editHeader') : t(prefix + 'addHeader')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        props.user ?
                            <div>
                                {
                                    message !== '' ?
                                        <div className="m-b-2">
                                            <Message content={message} variant={DANGER}/>
                                        </div>
                                        : null
                                }
                                <form id="userModalForm"
                                      onSubmit={handleSubmit(onClose)}>

                                    <ValidatedInput
                                        register={register}
                                        error={errors.email}
                                        name="email"
                                        label={t(prefix + 'emailLabel')}
                                        value={props.user.email}
                                        required={true}
                                        minLength={1}
                                        maxLength={50}
                                        pattern={EMAIL}
                                    />

                                    <div className="row">
                                        <div className="col-6">
                                            <ValidatedInput
                                                register={register}
                                                error={errors.firstName}
                                                name="firstName"
                                                label={t(prefix + 'firstNameLabel')}
                                                value={props.user.firstName}
                                                required={true}
                                                minLength={1}
                                                maxLength={30}
                                            />
                                        </div>
                                        <div className="col-6">
                                            <ValidatedInput
                                                register={register}
                                                error={errors.lastName}
                                                name="lastName"
                                                label={t(prefix + 'lastNameLabel')}
                                                value={props.user.lastName}
                                                required={true}
                                                minLength={1}
                                                maxLength={30}
                                            />
                                        </div>
                                    </div>

                                    <ValidatedInput
                                        register={register}
                                        error={errors.phoneNumber}
                                        name="phoneNumber"
                                        label={t(prefix + 'phoneNumberLabel')}
                                        value={props.user.phoneNumber}
                                        maxLength={15}
                                    />

                                    <SectionTitle content={t(prefix + 'isBannedTitle')}/>
                                    <CheckboxWithIcon
                                        content={t(prefix + 'isBannedCheckbox')}
                                        checked={isBanned}
                                        smallText={t(prefix + 'isBannedSmallText')}
                                        onChange={() => setIsBanned(!isBanned)}
                                    />
                                </form>
                            </div>
                            : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="button button--primary" type="submit" form="userModalForm">
                        {props.user?.id ? t(prefix + 'editButton') : t(prefix + 'addButton')}
                    </button>
                    <button className="button button--outline" type="button" onClick={() => onClose(false)}>
                        {t(prefix + 'cancelButton')}
                    </button>
                </Modal.Footer>
            </Modal>
        </ModalHolder>
    );
};

UserModal.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User),
    companyId: PropTypes.number,
    companyBranchId: PropTypes.number,
}
