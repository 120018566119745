import * as ProfileOperationTypes from "../../../constants/ProfileOperationTypes";
import {TYPE_1, TYPE_3, TYPE_4, TYPE_5} from "../../../constants/ProfileOperationTypes";
import {getSidesForProfilesByPieceType} from "../OperationHelper";
import {PROFILE} from "../../../constants/OperationTypes";

export function getAvailableProfileTypes(piece) {
    if (!piece) return [];

    let availableTypes = [];
    const profiles = getProfilesSortedByType(piece);

    // const isType1Available = profiles.type1.length < 1;
    // const isType3Available = profiles.type3.length < 2 ||
    return availableTypes;
}

export function getAvailableSidesForProfiles(piece, pieceType, availableSides) {
    let sides = getSidesForProfilesByPieceType(pieceType);

    return availableSides.filter(side => sides.includes(side));
}

export function getSidesWithAConfiguredProfile(piece, surface) {
    return piece
        .getOperationsByType(PROFILE)
        .filter(operation =>
            operation.data === surface
        )
        .map(operation => operation.side);
}

export const translateProfileTypeToName = profileType => {
    let name;

    switch (profileType) {
        case ProfileOperationTypes.TYPE_1:
            name = ProfileOperationTypes.ROUNDED;
            break;
        case ProfileOperationTypes.TYPE_3:
            name = ProfileOperationTypes.HALF_ROUNDED;
            break;
        case ProfileOperationTypes.TYPE_4:
        case ProfileOperationTypes.TYPE_5:
            name = ProfileOperationTypes.SLOPING;
            break;
    }

    return name;
};

export const translateProfileTypesToDescriptiveNames = (profileTypes) => {
    return profileTypes.map(profileType => translateProfileTypeToName(profileType));
};

export function determineProfileType(height, width) {
    if (height <= 2 && width <= 2) {
        return ProfileOperationTypes.TYPE_4;
    } else {
        return ProfileOperationTypes.TYPE_5;
    }
}

function getProfilesSortedByType(piece) {
    const profiles = piece.getOperationsByType(PROFILE);

    const type1Profiles = [];
    const type3Profiles = [];
    const type4Profiles = [];
    const type5Profiles = [];

    profiles.forEach(profile => {
        if (profile.additionalDimension.type === TYPE_1) type1Profiles.push(profile);
        if (profile.additionalDimension.type === TYPE_3) type3Profiles.push(profile);
        if (profile.additionalDimension.type === TYPE_4) type4Profiles.push(profile);
        if (profile.additionalDimension.type === TYPE_5) type5Profiles.push(profile);
    });

    return {
        type1: type1Profiles,
        type3: type3Profiles,
        type4: type4Profiles,
        type5: type5Profiles,
    }
}
