import React, {useEffect, useState} from 'react';
import {DANGER, DATE_PICKER} from "../../../../constants/Variants";
import {DELIVERIES, DELIVERIES_URL_NAME} from "../../../../constants/AdminPages";
import {AppHeader} from "../../../headers/AppHeader";
import {useTranslation} from "react-i18next";
import {TRANSLATION_NAMESPACE} from "../../../../constants/TranslationConstants";
import {DeliveryTypeService} from "../../../../classes/services/DeliveryTypeService";
import {useDispatch, useSelector} from "react-redux";
import {addAlertMessage, setWindowIsLoading} from "../../../../actions/GeneralActions";
import {captureMessage} from "@sentry/react";
import {LoadingMessage} from "../../../messages/LoadingMessage";
import {useHistory, useParams} from "react-router-dom";
import {DeliveryDetail} from "./DeliveryDetail";
import {DeliveryDateModal} from "../../../modals/DeliveryDateModal";
import {DeliveryDate} from "../../../../classes/models/DeliveryDate";
import {setCurrentCompanyBranch} from "../../../../actions/CompanyActions";
import {sortObjectsAlphabeticallyByDescription} from "../../../../classes/helpers/SortingHelper";
import {OTHER, TYPES_FOR_COLLECT, TYPES_FOR_DELIVERY} from "../../../../constants/DeliveryTypes";

export const Deliveries = (props) => {
    const {t} = useTranslation(TRANSLATION_NAMESPACE);
    const prefix = 'pages.admin.deliveries.';
    const constantsPrefix = 'constants.adminPages.';
    const deliveryTypePrefix = 'constants.deliveryTypes.';

    const [deliveryTypes, setDeliveryTypes] = useState([]);
    const [currentDeliveryType, setCurrentDeliveryType] = useState();
    const [currentDeliveryDate, setCurrentDeliveryDate] = useState();
    const [deliveryDateModalIsActive, setDeliveryDateModalIsActive] = useState(false);

    const {windowIsLoading} = useSelector(state => state.generalReducer);
    const {currentCompanyBranch} = useSelector(state => state.companyReducer);
    const {id: deliveryTypeId, page: pageName} = useParams();
    const history = useHistory();

    const dispatch = useDispatch();

    const deliveryTypeService = new DeliveryTypeService();

    useEffect(() => {
        getDeliveryTypes();
    }, []);

    useEffect(() => {
        setCurrentDeliveryType(deliveryTypes.find(d => d.id === +deliveryTypeId));
    }, [deliveryTypeId]);

    useEffect(() => {
        setDeliveryTypes(deliveryTypes.sort(sortObjectsAlphabeticallyByDescription));
    }, [deliveryTypes]);

    const updateDeliveryDatesInRedux = (updatedDeliveryTypes) => {
        const updatedDeliveryType = updatedDeliveryTypes.find(deliveryType => deliveryType.id === currentCompanyBranch.deliveryType.id);

        if (updatedDeliveryType) {
            currentCompanyBranch.deliveryType = updatedDeliveryType;
            dispatch(setCurrentCompanyBranch(currentCompanyBranch));
        }
    }

    const getDeliveryTypes = () => {
        dispatch(setWindowIsLoading(true));

        deliveryTypeService.getAll(true)
            .then(types => {
                types = types.filter(type => type.name !== OTHER);
                setDeliveryTypes(types);

                if (deliveryTypeId) {
                    const activeType = types.find(t => t.id === +deliveryTypeId);
                    if (activeType) setCurrentDeliveryType(activeType);
                    else updateDeliveryTypeIdInUrl();
                }
            })
            .catch(error => {
                captureMessage(error.message);

                dispatch(addAlertMessage(DANGER, t(prefix + 'fetchingDeliveryTypesFailed')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const addDeliveryDate = (date) => {
        dispatch(setWindowIsLoading(true));

        deliveryTypeService.addDate(currentDeliveryType.id, date)
            .then(newDeliveryDate => {
                const updatedDeliveryTypes = deliveryTypes.map(type => {
                    if (type.id === currentDeliveryType.id) {
                        type.dates = type.dates.concat([newDeliveryDate]);
                    }

                    return type;
                });

                setDeliveryTypes(updatedDeliveryTypes)
                updateDeliveryDatesInRedux(updatedDeliveryTypes);
            })
            .catch(error => {
                captureMessage(error.message);

                dispatch(addAlertMessage(DANGER, t(prefix + 'creatingDeliveryDateFailedMessage')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const updateDeliveryDate = (date, status) => {
        dispatch(setWindowIsLoading(true));

        deliveryTypeService.updateDate(currentDeliveryType.id, currentDeliveryDate.id, date, status)
            .then(updatedDeliveryDate => {
                const updatedDeliveryTypes = deliveryTypes.map(type => {
                    type.dates = type.dates.map(deliveryDate => {
                        if (deliveryDate.id === currentDeliveryDate.id) {
                            deliveryDate.date = updatedDeliveryDate.date;
                            deliveryDate.status = updatedDeliveryDate.status;
                            deliveryDate.updatedAt = updatedDeliveryDate.updatedAt;
                        }

                        return deliveryDate;
                    });

                    return type;
                });

                setDeliveryTypes(updatedDeliveryTypes);
                updateDeliveryDatesInRedux(updatedDeliveryTypes);
            })
            .catch(error => {
                captureMessage(error.message);

                dispatch(addAlertMessage(DANGER, t(prefix + 'updateDeliveryDateFailedMessage')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const deleteDeliveryDate = (dateId) => {
        dispatch(setWindowIsLoading(true));

        deliveryTypeService.deleteDate(currentDeliveryType.id, dateId)
            .then(success => {
                if (success) {
                    setDeliveryTypes(deliveryTypes.map(type => {
                        const indexOfDeletedDate = type.dates.findIndex(d => d.id === dateId);

                        if (indexOfDeletedDate > -1) type.dates.splice(indexOfDeletedDate, 1);

                        return type;
                    }))
                } else {
                    dispatch(addAlertMessage(DANGER, t(prefix + 'updateDeliveryDateFailedMessage')));
                }
            })
            .catch(error => {
                captureMessage(error.message);

                dispatch(addAlertMessage(DANGER, t(prefix + 'updateDeliveryDateFailedMessage')));
            })
            .finally(() => dispatch(setWindowIsLoading(false)));
    }

    const onDeleteClick = (id) => {
        deleteDeliveryDate(id);

        setCurrentDeliveryDate(null);
        setDeliveryDateModalIsActive(false);
    }

    const updateDeliveryTypeIdInUrl = (id) => {
        if (pageName !== DELIVERIES_URL_NAME) return;

        if (id) history.push(`/admin/${DELIVERIES_URL_NAME}/${id}`);
        else history.push(`/admin/${DELIVERIES_URL_NAME}`);
    }

    const openDeliveryDateModal = (deliveryDateId) => {
        const deliveryDate = deliveryDateId ?
            currentDeliveryType.dates.find(d => d.id === deliveryDateId) :
            new DeliveryDate();

        setCurrentDeliveryDate(deliveryDate);
        setDeliveryDateModalIsActive(true);
    }

    const closeDeliveryDateModal = (submitted, newDate, status) => {
        if (submitted && newDate) {
            if (currentDeliveryDate.id) {
                updateDeliveryDate(newDate, status);
            } else {
                addDeliveryDate(newDate);
            }
        }

        setCurrentDeliveryDate(null);
        setDeliveryDateModalIsActive(false);
    }

    const getTitle = () => {
        let title = t(constantsPrefix + DELIVERIES);

        if (currentDeliveryType) {
            title = `${title} - ${currentDeliveryType.getDescription()}`;
        }

        return title;
    }

    const getDeliveryDateModalVariant = () => {
        if (!currentDeliveryType) return;

        if (TYPES_FOR_DELIVERY.includes(currentDeliveryType.name)) {
            return DATE_PICKER.DATE;
        }

        if (TYPES_FOR_COLLECT.includes(currentDeliveryType.name)) {
            return DATE_PICKER.DATETIME;
        }

        return null;
    }

    return (
        <>
            {windowIsLoading ? <LoadingMessage/> : null}

            <DeliveryDateModal
                isActive={deliveryDateModalIsActive}
                onClose={(submitted, newDate, status) => closeDeliveryDateModal(submitted, newDate, status)}
                deliveryDate={currentDeliveryDate}
                variant={getDeliveryDateModalVariant()}
            />

            <AppHeader
                title={getTitle()}
                onBackClick={() => updateDeliveryTypeIdInUrl()}
            >
                {
                    currentDeliveryType && currentDeliveryType.dates.length && <button
                        className="button button--icon-before button--primary"
                        type="button"
                        onClick={() => openDeliveryDateModal()}
                    >
                        <i className="material-icons">add_circle</i>
                        {t(prefix + 'addDeliveryDate')}
                    </button>
                }
            </AppHeader>

            {
                currentDeliveryType ?
                    <DeliveryDetail
                        deliveryType={currentDeliveryType}
                        onDateClick={(id) => openDeliveryDateModal(id)}
                        onDeleteClick={(id) => onDeleteClick(id)}
                    />
                    :
                    <div className="list">
                        {
                            deliveryTypes.map(type => {
                                return <div
                                    key={type.id}
                                    className="pointer list__item" onClick={() => updateDeliveryTypeIdInUrl(type.id)}
                                >
                                    <div className="list__item__content">
                                        {t(deliveryTypePrefix + type.name)}
                                    </div>
                                </div>
                            })
                        }
                    </div>
            }
        </>
    );
};
